import Helpers from "../../../common/utils/helpers";
import LocalStorageConstant from "../../constants/localstroage.constant";

const getLocalRefreshToken = () => {
    return localStorage.getItem(LocalStorageConstant.refreshToken);
};

const getLocalAccessToken = () => {
    return localStorage.getItem(LocalStorageConstant.accessToken);
};

const addOrUpdateLocalAccessToken = (token: string) => {
    localStorage.setItem(LocalStorageConstant.accessToken, token);
};

const addOrUpdateLocalRefreshToken = (token: string) => {
    localStorage.setItem(LocalStorageConstant.refreshToken, token);
};

const getUser = () => {
    let user: string | null = localStorage.getItem(LocalStorageConstant.user);
    return user ? JSON.parse(user) : null;
};

const setUser = (user: any) => {
    Helpers.ConsoleLog(JSON.stringify(user));
    localStorage.setItem(LocalStorageConstant.user, JSON.stringify(user) ?? '');
};

const removeUser = () => {
    localStorage.removeItem(LocalStorageConstant.user);
};

const getUserTimeZone = () => {
    let user: any = localStorage.getItem(LocalStorageConstant.user);
    user = user ? JSON.parse(user) : null;
    let timezone = "America/Guatemala"
    if (user?.userSettings.length > 0 || user == null) {
        user.userSettings.forEach((val: any, key: any) => {
            if (val.key == "TimeZone") {

                timezone = val.value;
            }
        })
    }
    return timezone;
}

const TokenService = {
    getLocalRefreshToken,
    getLocalAccessToken,
    addOrUpdateLocalAccessToken,
    addOrUpdateLocalRefreshToken,
    getUser,
    setUser,
    removeUser,
    getUserTimeZone
};

export default TokenService;