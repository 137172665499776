
const CommonApiConstant: any = {
    getToken: "/api/token",
    getAllCustomerList: "/api/customer/getallcustomers",
    getUser: "/api/auth/getuser",
    dowloadFiles: "/api/assets/DownloadBlobByID",
    getPermissionsOfCurrentUser: "api/auth/getUserPermissions",
    GetCustomersByProductType: "api/ESP/GetCustomersByProductType"
};

export default CommonApiConstant;
