import ApiConstant from "../../constants/api.constants";
import Helpers from "../../utils/helpers";
import api from "../api";

const getCustomerList = async () => {
    return await api
        .get(ApiConstant.GetCustomersByProductType+"?ProductType=Soofie")
        .then(function (response: any) {
            return response.data;
        })
        .catch(function (error) {
            Helpers.ConsoleLogError(error);
        });
};

const CustomerService = {
    getCustomerList
};

export default CustomerService;