import { useTranslation } from "react-i18next";
import React, { useEffect } from "react";
import { useState } from "react";
import DataTable, { TableStyles } from 'react-data-table-component';
import SelectDaysFilter from "./aitrendcharts/select-days-filter";
import Helpers from "../../../common/utils/helpers";
import moment from "moment";
import { Button } from "primereact/button";
import { DataTable as PrimeDatatable, DataTableFilterMeta } from 'primereact/datatable';
import { Column, ColumnFilterElementTemplateOptions } from 'primereact/column';

const PreviousESPCallHistory = (props: any) => {
    const ESPCallHistoryData = props.ESPCallHistoryData;
    const [displayCrossBtn, setdisplayCrossBtn] = useState<any>("none");
    const [showSelectedFilterText, setshowSelectedFilterText] = useState('');
    const [textSearchIcon, setTextSearchIcon] = useState<any>("");

    const [filterText, setFilterText] = useState("");
    const [filteredValues, setFilteredValues] = useState<any>([]);
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);

    const { t } = useTranslation();
    const sortIcon = <i className="pi pi-chevron-down" style={{ fontSize: "15px" }}></i>;
    const [visible, setVisible] = useState(false);
    const [popUpTitle, setPopUpTitle] = useState<string>("");
    const [AllHistoryData, setAllHistoryData] = useState([]);
    const handlePopBtn = () => {
        setPopUpTitle("Edit")
        setVisible(true)
    }
    // gather all history data start


    useEffect(() => {

        filteredItems();

    }, [filterText]);

    useEffect(() => {

        setFilteredValues(props.ESPCallHistoryData);

        if (ESPCallHistoryData.length > 0) {
            let temp_array: any = [];

            let hours = 0;
            var d = new Date();
            if (props?.chosenDay == "A") {
                hours = 6;
            } else if (props?.chosenDay == "M") {
                hours = 12;

            } else if (props?.chosenDay == "P") {
                hours = 24;

            } else if (props?.chosenDay == "R") {
                hours = 168;

            }
            d.setHours(d.getHours() - hours);
            ESPCallHistoryData?.map((val: any) => {
                let check_temp_length = val?.histories?.length;
                if (check_temp_length > 0) {
                    let row_data = {
                        comments: val?.histories[check_temp_length - 1]?.comments,
                        verifiedOn: val?.histories[check_temp_length - 1]?.verifiedOn,
                        reason: val?.histories[check_temp_length - 1]?.reason,
                        anomaly: val?.anomaly,
                        endDate: val?.endDate,
                        startDate: val?.startDate,
                        severity: val?.severity,
                    }
                    temp_array.push(row_data);
                } else {
                    let row_data = {
                        comments: "",
                        verifiedOn: null,
                        reason: "",
                        anomaly: val?.anomaly,
                        endDate: val?.endDate,
                        startDate: val?.startDate,
                        severity: val?.severity,
                    }
                    temp_array.push(row_data);
                }

            })

            setAllHistoryData(temp_array);
        }
        if (props?.chosenDay == 'A') {
            setshowSelectedFilterText(`Last 6 hours data`);
        } else if (props?.chosenDay == 'M') {
            setshowSelectedFilterText(`Last 12 hours data`);
        } else if (props?.chosenDay == 'P') {
            setshowSelectedFilterText(`Last 24 hours data`);
        } else if (props?.chosenDay == 'R') {
            setshowSelectedFilterText(`Last 7 days data`);
        } else if (props?.chosenDayDateRange != "") {
            setshowSelectedFilterText(`Data from ${Helpers.convertFullDateToReadableFormatForFilterRange(props?.chosenDayDateRange[0])} to ${Helpers.convertFullDateToReadableFormatForFilterRange(props?.chosenDayDateRange[1])}`);
        }
    }, [props]);
    // gather all history data end
    const columns: any = [
        {
            name: t('AnamolyDetectionAnalyze.PredictionStartTime'),
            selector: 'startDate',
            cell: (row: any) => Helpers.convertFullDateToReadableFormat(row.startDate),
            sortable: true,
            style: {
                fontSize: "9px",
                fontWeight: 500
            }
        },
        {
            name: t('AnamolyDetectionAnalyze.PredictionEndTime'),
            selector: 'endDate',
            cell: (row: any) => Helpers.convertFullDateToReadableFormat(row.endDate),
            sortable: true,
            style: {
                fontSize: "9px",
                fontWeight: 500
            }
        },
        // {
        //     name: "",
        //     omit: true,
        //     selector: (row: any) => row.endDate,
        //     sortable: true,
        //     style: {
        //         fontSize: "9px",
        //         fontWeight: 500
        //     }
        // },
        {
            name: t('AnamolyDetectionAnalyze.Duration'),
            cell: (row: any) => {
                let __duration = moment.duration(moment(moment(row?.endDate).format()).diff(moment(row?.startDate).format()));
                let __hours = __duration.asHours();
                return __hours;
            },
            selector: (row: any) => {
                let __duration = moment.duration(moment(moment(row?.endDate).format()).diff(moment(row?.startDate).format()));
                let __hours = __duration.asHours();
                return __hours;
            },
            sortable: true,
            style: {
                fontSize: "9px",
                fontWeight: 500
            }
        },
        {
            name: "Severity prediction",
            selector: "severity",
            cell: (row: any) => {
                if (row?.anomaly == 0) {
                    return <><span className="badge security_lvl_bge_lvl_normal" style={{ fontSize: "11px" }}><span className="badge rounded p-1 security_lvl_bge_lvl_sub_normal"> </span>  {t("Normal")}</span></>
                }else if(row?.anomaly == 1){
                    return <><span className="badge security_lvl_bge_lvl3" style={{ fontSize: "11px" }}><span className="badge rounded bg-danger p-1 security_lvl_bge_lvl3_sub"> </span>Anomaly</span></>

                }
            },
            sortable: true,
            style: {
                fontSize: "9px",
                fontWeight: 500
            }
        },
        {
            name: 'Verified severity1',
            selector: (row: any) => {

                let check_temp_length = row?.histories?.length;

                if (check_temp_length > 0) {
                    let severity = row?.histories[check_temp_length - 1]?.severity;
                    if (severity == 0 || severity == 'normal' || severity == 'NORMAL') {
                        return <><span className="badge security_lvl_bge_lvl_normal"><span className="badge rounded p-1 security_lvl_bge_lvl_sub_normal"> </span>  {t("Normal")}</span></>
                    } else if (severity == -1 || severity == "shutdown" || severity == "SHUTDOWN") {
                        return <><span className="badge security_lvl_bge_lvl_nil" style={{ fontSize: "9px" }}><span className="badge rounded p-1 security_lvl_bge_lvl_sub_nil"> </span>  {t("ShutDown")}</span></>
                    } else if (severity == "1" || row.anomaly == "1") {
                        return <><span className="badge security_lvl_bge_lvl3" style={{ fontSize: "9px" }}><span className="badge rounded bg-danger p-1 security_lvl_bge_lvl3_sub"> </span>Anomaly</span></>
                    } else if (row.severity == "2" || row?.severity == "medium") {
                        return <><span className="badge security_lvl_bge_lvl2" style={{ fontSize: "9px" }}><span className="badge rounded p-1 security_lvl_bge_lvl2_sub"> </span>Level 2</span></>
                    } else if (severity == "3" || severity == "high") {
                        return <><span className="badge security_lvl_bge_lvl3" style={{ fontSize: "9px" }}><span className="badge rounded bg-danger p-1 security_lvl_bge_lvl3_sub"> </span> Level 3</span></>
                    } else if (severity == null) {
                        return <><span className="badge security_lvl_bge_lvl_nil" style={{ fontSize: "9px" }}><span className="badge rounded p-1 security_lvl_bge_lvl_sub_nil"> </span> {t('Unknown')}</span></>
                    }




                } else {
                    return <>N/A</>
                }
            },
            sortable: false,
            style: {
                fontSize: "9px",
                fontWeight: 500
            }
        },
        /*{
            name: "AI verified type",
            selector: (row: any) => {

                let check_temp_length = row?.histories?.length;

                if (check_temp_length > 0) {
                    let severity = row?.histories[check_temp_length - 1]?.severity;
                    if (severity == 0 || severity == 'normal' || severity == 'NORMAL') {
                        return <><span className="badge security_lvl_bge_lvl_normal"><span className="badge rounded p-1 security_lvl_bge_lvl_sub_normal"> </span>  {t("Normal")}</span></>
                    } else if (severity == -1 || severity == "shutdown" || severity == "SHUTDOWN") {
                        return <><span className="badge security_lvl_bge_lvl_nil" style={{ fontSize: "9px" }}><span className="badge rounded p-1 security_lvl_bge_lvl_sub_nil"> </span>  {t("ShutDown")}</span></>
                    } else if (severity == "1" || severity == "low") {
                        return <><span className="badge security_lvl_bge_lvl1" style={{ fontSize: "9px" }}><span className="badge rounded p-1 security_lvl_bge_lvl1_sub"> </span> Level 1</span></>
                    } else if (row.severity == "2" || row?.severity == "medium") {
                        return <><span className="badge security_lvl_bge_lvl2" style={{ fontSize: "9px" }}><span className="badge rounded p-1 security_lvl_bge_lvl2_sub"> </span>Level 2</span></>
                    } else if (severity == "3" || severity == "high") {
                        return <><span className="badge security_lvl_bge_lvl3" style={{ fontSize: "9px" }}><span className="badge rounded bg-danger p-1 security_lvl_bge_lvl3_sub"> </span> Level 3</span></>
                    } else if (severity == null) {
                        return <><span className="badge security_lvl_bge_lvl_nil" style={{ fontSize: "9px" }}><span className="badge rounded p-1 security_lvl_bge_lvl_sub_nil"> </span> {t('Unknown')}</span></>
                    }




                } else {
                    return <>N-A</>
                }
            },
            sortable: true,
            style: {
                fontSize: "9px",
                fontWeight: 500
            }
        },*/
        {
            name: 'Type prediction',
            selector: "anomalyType",
            cell: (row: any) => {
                if (row?.anomalyType == "2") {
                    return <span className='gasSlugBadge'>Gas Slug</span>;

                } else if (row?.anomalyType == "1") {
                    return <span className='gasLockBadge'>Gas lock</span>;

                } else if (row?.anomalyType == null || row?.anomalyType == "" || row?.anomalyType == "0") {
                    if (row?.anomaly == 0) {
                        return <>N/A</>
                    } else if (row?.anomaly == -1) {
                        return <>N/A</>
                    } else if (row.severity == "1" || row?.severity == "low") {
                        return <>-</>
                    } else if (row.severity == "2" || row?.severity == "medium") {
                        return <>-</>
                    } else if (row.severity == "3" || row?.severity == "high") {
                        return <>-</>
                    } else if (row.severity == "0") {
                        return <>N/A</>
                    } else if (row.severity == null) {
                        return <>N/A</>
                    }
                } else {
                    return <>{row?.anomalyType}</>
                }

                let check_temp_length = row?.histories?.length;

                if (check_temp_length > 0) {
                    let verifiedOn = row?.histories[check_temp_length - 1]?.verifiedOn;

                    if (verifiedOn != null) {
                        return <><span className="badge p-1 verified" style={{ fontSize: "9px", fontWeight: "bolder" }}><span className="pi pi-check" style={{ marginRight: "2px", fontSize: "9px", fontWeight: "bolder" }}> </span>Verified</span></>
                    } else {
                        return <><span className="badge p-1 needs_verification" style={{ fontSize: "9px" }}>Needs verification</span></>
                    }

                } else {
                    return <><span className="badge p-1 needs_verification" style={{ fontSize: "9px" }}>Needs verification</span></>
                }
            },
            sortable: true,
            style: {
                fontSize: "11px",
                fontWeight: 500
            }
        },
        {
            name: 'Verified type',
            selector: (row: any) => {

                let check_temp_length = row?.histories?.length;

                if (check_temp_length > 0) {
                    let reason = row?.histories[check_temp_length - 1]?.reason;

                    if (reason == "Gasslug") {
                        return "Gas slug";
                    } else if (reason == "Gaslock") {
                        return "Gas lock";
                    }

                } else {
                    return <>N/A</>
                }

            },
            sortable: true,
            style: {
                fontSize: "11px",
                fontWeight: 500
            }
        },
        {
            name: t('Comments'),
            selector: (row: any) => {

                let check_temp_length = row?.histories?.length;

                if (check_temp_length > 0) {
                    let comments = row?.histories[check_temp_length - 1]?.comments;

                    return <><span title={comments}>{comments}</span></>

                } else {
                    return <>N/A</>
                }



            },
            sortable: true,
            style: {
                fontSize: "9px",
                fontWeight: 500
            }
        },

    ];
    const dataTableStyles: TableStyles = {
        table: {
            style: {
                // borderRight: "1px solid #ccc !important",
                // borderLeft: "1px solid #ccc !important",
                borderRadius: '0px',
                display: "none"

            }
        },

        headCells: {
            style: {
                backgroundColor: "#f9fafb",
                borderTop: "1px solid rgba(0,0,0,0.07)",
                borderLeft: "0px solid rgba(0,0,0,0.07)",
                '&:last-of-type': {
                    borderRight: "0px solid rgba(0,0,0,0.07)",
                },
                justifyContent: "space-between",
                fontWeight: "bold",
                paddingRight: "5px",
                paddingBottom: "0px !important",
                // border: "1px solid #ccc !important"

                // width: "104% !important",
                // marginLeft: "-1%  !important"
            }
        },
        cells: {
            style: {
                fontSize: "11px !important",
                borderLeft: "0px solid rgba(0,0,0,0.07)",
                '&:last-of-type': {
                    borderRight: "0px solid rgba(0,0,0,0.07)",
                    //borderBottom: "1px solid rgba(0,0,0,0.07)",
                },
                // border: "1px solid #ccc !important"
            }
        },
        pagination: {
            style: {
                justifyContent: "center",
                width: "90%"
            }
        }
    }


    const filteredItems = () => {
        let filteredAnamolyList: any = (props.ESPCallHistoryData.length > 0) ? props.ESPCallHistoryData : [];
        if (filterText) {
            filteredAnamolyList = filteredAnamolyList.filter((item: {
                startDate: any;
                endDate: any;
                search_anomly:any;
                search_verified_severity:any;
                search_verified:any;
                reason: { toString: () => string; };
                comments: { toString: () => string; };
                search_comments: { toString: () => string; };
                search_verified_type: { toString: () => string; };

            }) => {

                return (Helpers.convertFullDateToReadableFormat(item.startDate).toString().toLowerCase().includes(filterText.toLowerCase())
                    ||
                    Helpers.convertFullDateToReadableFormat(item.endDate).toString().toLowerCase().includes(filterText.toLowerCase())

                    ||
                    item?.reason?.toString()?.toLowerCase().includes(filterText.toLowerCase())
                    ||
                    item?.comments?.toString()?.toLowerCase().includes(filterText.toLowerCase())
                    ||
                    item?.search_anomly?.toString()?.toLowerCase().includes(filterText.toLowerCase())
                    ||
                    item?.search_verified_severity?.toString()?.toLowerCase().includes(filterText.toLowerCase())
                    ||
                    item?.search_verified?.toString()?.toLowerCase().includes(filterText.toLowerCase())
                    ||
                    item?.search_comments?.toString()?.toLowerCase().includes(filterText.toLowerCase())
                    ||
                    item?.search_verified_type?.toString()?.toLowerCase().includes(filterText.toLowerCase())
                )

            });
        }
        setFilteredValues(filteredAnamolyList);
    }
    const handleTextSearchEnter = (e: any, type: any) => {

        if (type == "search") {
            if (e.target.value.length == 0) {
                setdisplayCrossBtn("none");
                setTextSearchIcon("")
                setFilterText("");
            } else {
                setTextSearchIcon(e);
                setdisplayCrossBtn("block");
            }
        } else {
            if (e.target.value.length == 0) {
                setdisplayCrossBtn("none");
                setTextSearchIcon("")
                setFilterText("");
            }
        }
    }

    const handleTextSearch = (e: any, type: any) => {
        if (type == "search") {
            setTextSearchIcon(e)
        } else {
            setTextSearchIcon("")
            setFilterText("");
            setdisplayCrossBtn("none");
        }
    }



    const subHeaderComponentMemo = React.useMemo(() => {

        return (
            <>
                <div className="col-xl-3 col-md-6 col-sm-6" style={{ position: "absolute", left: "1.2%" }}>
                    <div className="mobile_survey_details" style={{ marginBottom: '5px ' }}>
                        <label className="custom_common_label">{t('Dashboard.Search')} &nbsp;&nbsp;&nbsp; </label>
                    </div>
                    <input type="text" style={{ marginBottom: "25px", marginRight: "5px" }} className="form-control" onChange={(e: any) => {

                        setFilterText(e.target.value)
                        handleTextSearchEnter(e, "search")

                    }} placeholder={`${t('Dashboard.SearchPlaceholder')}`} name="survey_details_search" value={filterText} id="survey_details_search" autoComplete="off" />

                    <span style={{
                        display: displayCrossBtn, position: "absolute",
                        bottom: "24px",
                        right: "0%"
                    }} className="btn" onClick={(e) => handleTextSearch(e, "clear")} title={'Clear search text'}><i className="pi pi-times"></i></span>

                </div>


                <Button severity="secondary" text raised style={{
                    height: "30px",
                    // fontSize: "12px !important",
                    float: "left",
                    backgroundColor: "#25476a",
                    color: "white",
                    borderRadius: "8px",
                    fontWeight: "400",
                }} onClick={(e: any) => handleESPListDownload(e)} > <i className="pi pi-cloud-download" />&nbsp;{'Download table'}</Button>
            </>

        );
    }, [filterText, resetPaginationToggle, filteredValues]);
    const handleESPListDownload = (e: any) => {

        if (filteredValues?.length > 0) {

            filteredValues?.map((val: any, i: any) => {

                if (val.startDate != null) {
                    val.final_startDate = Helpers.convertFullDateToReadableFormat(val.startDate)
                } else {
                    val.final_startDate = ""
                }
                if (val.endDate != null) {
                    val.final_endDate = Helpers.convertFullDateToReadableFormat(val.endDate)
                } else {
                    val.final_endDate = ""
                }

                if (val?.anomaly == 0) {
                    val.final_anomly = "Normal";
                } else if (val?.anomaly == -4 || val?.severity == -1) {
                    val.final_anomly = "ShutDown";

                } else if (val.severity == "1" || val?.severity == "low") {
                    val.final_anomly = "Anomaly";

                } else if (val.severity == "2" || val?.severity == "medium") {
                    val.final_anomly = "Level 2";

                } else if (val.severity == "3" || val?.severity == "high") {
                    val.final_anomly = "Level 3";

                } else if (val.severity == "0") {
                    val.final_anomly = "Normal";

                } else if (val.severity == null || val.severity == -1 || val.severity == -2 || val.severity == -3) {
                    val.final_anomly = "Unknown";

                }

                // check type prediction

                if (val?.anomalyType == "1") {
                    val.final_reason = "Gas slug";

                } else if (val?.anomalyType == "2") {
                    val.final_reason = "Gas lock";


                } else if (val?.anomalyType == null || val?.anomalyType == "" || val?.anomalyType == "0") {
                    val.final_reason = "";

                } else {
                    val.final_reason = val?.anomalyType;
                }

                // verified severity start
                let check_temp_length1 = val?.histories?.length;

                if (check_temp_length1 > 0) {
                    let severity = val?.histories[check_temp_length1 - 1]?.severity;
                    if (severity == 0 || severity == 'normal' || severity == 'NORMAL') {
                        val.final_verified_severity = "Normal";
                    } else if (severity == -1 || severity == "shutdown" || severity == "SHUTDOWN") {
                        val.final_verified_severity = "Shutdown";
                    } else if (severity == "1" || severity == "low") {
                        val.final_verified_severity = "Anomaly";
                    } else if (val.severity == "2" || val?.severity == "medium") {
                        val.final_verified_severity = "Level 2";
                    } else if (severity == "3" || severity == "high") {
                        val.final_verified_severity = "Level 3";
                    } else if (severity == null) {
                        val.final_verified_severity = "Unknown";
                    }




                } else {
                    return <>N/A</>
                }

                // verified severity end


                // verified predition 
                let check_temp_length = val?.histories?.length;

                if (check_temp_length > 0) {
                    let verifiedOn = val?.histories[check_temp_length - 1]?.verifiedOn;

                    if (verifiedOn != null) {
                        val.final_verified = "Verified";
                        val.final_verified = "Needs verification";
                    } else {
                        val.final_verified = "Needs verification";
                    }

                } else {
                    val.final_verified = "Needs verification";
                }




                // check comment
                let check_temp_length_comments = val?.histories?.length;

                if (check_temp_length_comments > 0) {
                    let comments = val?.histories[check_temp_length - 1]?.comments;

                    val.final_comments = comments;

                } else {
                    val.final_comments = "";

                }

                // verified type
                let check_temp_length2 = val?.histories?.length;

                if (check_temp_length2 > 0) {
                    let reason = val?.histories[check_temp_length2 - 1]?.reason;

                    if (reason == "Gasslug") {
                        val.final_verified_type = "Gas slug"
                    } else if (reason == "Gaslock") {
                        val.final_verified_type = "Gas lock"
                    }

                } else {
                    return <>N/A</>
                }

            })

            Helpers.exportToCSV(
                filteredValues,
                "Previous_ESP_Call_History",
                'Previous_ESP_Call_History',
                {
                    final_startDate: 'Prediction start time',
                    final_endDate: 'Prediction end time',
                    duration: 'Duration (hrs)',
                    final_anomly: 'Severity Prediction',
                    final_verified_severity: 'Verified severity',
                    final_comments: 'Comments',
                    final_reason: 'Type prediction',
                    final_verified_type: "Verfied type"
                },
                ["final_startDate", "final_endDate", "duration", "final_anomly", "final_verified_severity", "final_reason", "final_verified_type", "final_comments"]);
        }
    }

    return <>

        <div style={{ border: "1px solid #c4c4c4", borderRadius: "10px", marginTop: "20px" }}>
            <div className='row' style={{ marginTop: "12px" }}>
                <div className='col-sm-8 col-md-8 col-lg-8' style={{ marginLeft: "15px" }}>
                    <span style={{ color: "black", fontWeight: 700, fontSize: "19px" }}>{t('AnamolyDetectionAnalyze.PreviousESPCallHistory')}</span>
                    <p style={{ fontSize: "14px" }}>{t('AnamolyDetectionAnalyze.PreviousESPCallHistorySubHeading')}</p>
                </div>
                <div className='col-sm-3 col-md-3 col-lg-3' style={{ marginTop: "7px", marginLeft: "6%" }}>



                    <SelectDaysFilter setChosenDay={props?.setChosenDay} chosenDay={props?.chosenDay} setchosenDayDateRange={props.setchosenDayDateRange} chosenDayDateRange={props.chosenDayDateRange} />
                </div>
            </div>
            <div className="card" style={{ borderTop: "1px solid rgb(196, 196, 196)", borderTopLeftRadius: "0", borderTopRightRadius: "0" }}></div>
            <span style={{
                float: "right",
                marginRight: "1%"
            }}>{showSelectedFilterText}</span>
            <div style={{ marginTop: "2%" }}>
                <DataTable
                    columns={columns}
                    data={filteredValues}
                    pagination
                    // defaultSortFieldId={1}
                    defaultSortAsc={false}
                    subHeader
                    subHeaderComponent={subHeaderComponentMemo}
                    customStyles={dataTableStyles}
                    responsive={true}
                    striped={false}
                    highlightOnHover={false}
                    sortIcon={sortIcon}
                />
            </div>

            {/* prime datatable start */}
            <div className="card" style={{ borderTop: "1px solid rgb(196, 196, 196)", borderTopLeftRadius: "0", borderTopRightRadius: "0" }}>
                <PrimeDatatable sortField="startDate" sortOrder={-1} value={filteredValues} paginator rows={10} rowsPerPageOptions={[10, 20, 30, 40, 50]} currentPageReportTemplate="{first} to {last} of {totalRecords}" paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink">

                    {/* PredictionStartTime */}
                    <Column sortable showFilterMatchModes={false} field="startDate" body={(body_val) => {
                        return <>{Helpers.convertFullDateToReadableFormat(body_val.startDate)}</>
                    }} header={`${t('AnamolyDetectionAnalyze.PredictionStartTime')}`}></Column>

                    {/* PredictionEndTime */}
                    <Column sortable field="endDate" body={(body_val) => {
                        return <>{Helpers.convertFullDateToReadableFormat(body_val.endDate)}</>
                    }} header={`${t('AnamolyDetectionAnalyze.PredictionEndTime')}`}></Column>



                    {/* Duration */}

                    <Column sortable field="duration" body={(body_val) => {
                        let __duration = moment.duration(moment(moment(body_val?.endDate).format()).diff(moment(body_val?.startDate).format()));
                        let __hours = __duration.asHours();
                        return Number(__hours).toFixed(2) + " hrs";
                    }} header={t('AnamolyDetectionAnalyze.Duration')}></Column>

                    {/* Severity prediction */}

                    <Column sortable field="severity" body={(body_val) => {
                        if (body_val?.anomaly == 0) {
                            return <><span className="badge security_lvl_bge_lvl_normal" style={{ fontSize: "11px" }}><span className="badge rounded p-1 security_lvl_bge_lvl_sub_normal"> </span>  {t("Normal")}</span></>
                        } else if (body_val?.anomaly == -4) {
                            return <><span className="badge security_lvl_bge_lvl_nil" style={{ fontSize: "11px" }}><span className="badge rounded p-1 security_lvl_bge_lvl_sub_nil"> </span>  {t("ShutDown")}</span></>
                        } else if (body_val.severity == "1" || body_val?.anomaly == "1") {
                            return <><span className="badge security_lvl_bge_lvl3" style={{ fontSize: "11px" }}><span style={{marginRight:"2px"}} className="badge rounded bg-danger p-1 security_lvl_bge_lvl3_sub"> </span>Anomaly</span></>
                        } else if (body_val.severity == "2" || body_val?.severity == "medium") {
                            return <><span className="badge security_lvl_bge_lvl2" style={{ fontSize: "11px" }}><span className="badge rounded p-1 security_lvl_bge_lvl2_sub" style={{ marginRight: "3px" }}> </span>Level 2</span></>
                        } else if (body_val.severity == "3" || body_val?.severity == "high") {
                            return <><span className="badge security_lvl_bge_lvl3" style={{ fontSize: "11px" }}><span className="badge rounded bg-danger p-1 security_lvl_bge_lvl3_sub"> </span> Level 3</span></>
                        } else if (body_val.severity == "0") {
                            return <><span className="badge security_lvl_bge_lvl_normal" style={{ fontSize: "11px" }}><span className="badge rounded p-1 security_lvl_bge_lvl_sub_normal"> </span> {t('Normal')}</span></>
                        } else if (body_val.severity == null || body_val.severity == "-1" || body_val.severity == "-2" || body_val.severity == "-3") {
                            return <><span className="badge security_lvl_bge_lvl_nil" style={{ fontSize: "11px" }}><span className="badge rounded p-1 security_lvl_bge_lvl_sub_nil"> </span> {t('Unknown')}</span></>
                        } else if (body_val.severity == "-1") {
                            return <><span className="badge security_lvl_bge_lvl_nil" style={{ fontSize: "11px" }}><span className="badge rounded p-1 security_lvl_bge_lvl_sub_nil"> </span>  {t("ShutDown")}</span></>
                        }
                    }} header={'Severity prediction'}></Column>

                    {/* Verified severity */}
                    <Column sortable field="severity" body={(body_val) => {
                        let check_temp_length = body_val?.histories?.length;

                        if (check_temp_length > 0) {
                            let severity = body_val?.histories[check_temp_length - 1]?.severity;
                            if (severity == 0 || severity == 'normal' || severity == 'NORMAL') {
                                return <><span className="badge security_lvl_bge_lvl_normal"><span className="badge rounded p-1 security_lvl_bge_lvl_sub_normal"> </span>  {t("Normal")}</span></>
                            } else if (severity == "-1" || severity == "-4" || severity == "shutdown" || severity == "SHUTDOWN") {
                                return <><span className="badge security_lvl_bge_lvl_nil" style={{ fontSize: "11px" }}><span className="badge rounded p-1 security_lvl_bge_lvl_sub_nil"> </span>  {t("ShutDown")}</span></>
                            } else if (severity == "1" || severity == "low") {
                                return <><span className="badge security_lvl_bge_lvl3" style={{ fontSize: "11px" }}><span className="badge rounded bg-danger p-1 security_lvl_bge_lvl3_sub"> </span>Anomaly</span></>
                            } else if (body_val.severity == "2" || body_val?.severity == "medium") {
                                return <><span className="badge security_lvl_bge_lvl2" style={{ fontSize: "11px" }}><span className="badge rounded p-1 security_lvl_bge_lvl2_sub"> </span>Level 2</span></>
                            } else if (severity == "3" || severity == "high") {
                                return <><span className="badge security_lvl_bge_lvl3" style={{ fontSize: "11px" }}><span className="badge rounded bg-danger p-1 security_lvl_bge_lvl3_sub"> </span> Level 3</span></>
                            } else if (severity == null || severity == "-1" || severity == "-2" || severity == "-3") {
                                return <><span className="badge security_lvl_bge_lvl_nil" style={{ fontSize: "9px" }}><span className="badge rounded p-1 security_lvl_bge_lvl_sub_nil"> </span> {t('Unknown')}</span></>
                            }




                        } else {
                            return <>N/A</>
                        }

                    }} header={"Verified severity"}></Column>

                    {/*Type prediction */}
                    <Column sortable field="anomalyType" body={(body_val) => {
                        if (body_val?.anomalyType == "2") {
                            return <span className='gasSlugBadge' style={{ float: "none", fontSize: "11px" }}>Gas Slug</span>;

                        } else if (body_val?.anomalyType == "1") {
                            return <span className='gasLockBadge' style={{ float: "none", fontSize: "11px" }}>Gas lock</span>;

                        } else if (body_val?.anomalyType == "-1") {
                            return <>N/A</>
                        } else if (body_val?.anomalyType == null || body_val?.anomalyType == "" || body_val?.anomalyType == "0") {
                            if (body_val?.anomaly == 0) {
                                return <>N/A</>
                            } else if (body_val?.anomaly == -1) {
                                return <>N/A</>
                            } else if (body_val.severity == "1" || body_val?.severity == "low") {
                                return <>-</>
                            } else if (body_val.severity == "2" || body_val?.severity == "medium") {
                                return <>-</>
                            } else if (body_val.severity == "3" || body_val?.severity == "high") {
                                return <>-</>
                            } else if (body_val.severity == "0") {
                                return <>N/A</>
                            } else if (body_val.severity == null) {
                                return <>N/A</>
                            }
                        } else {
                            return <>{body_val?.anomalyType}</>
                        }

                        let check_temp_length = body_val?.histories?.length;

                        if (check_temp_length > 0) {
                            let verifiedOn = body_val?.histories[check_temp_length - 1]?.verifiedOn;

                            if (verifiedOn != null) {
                                return <><span className="badge p-1 verified" style={{ fontSize: "9px", fontWeight: "bolder" }}><span className="pi pi-check" style={{ marginRight: "2px", fontSize: "9px", fontWeight: "bolder" }}> </span>Verified</span></>
                            } else {
                                return <><span className="badge p-1 needs_verification" style={{ fontSize: "9px" }}>Needs verification</span></>
                            }

                        } else {
                            return <><span className="badge p-1 needs_verification" style={{ fontSize: "9px" }}>Needs verification</span></>
                        }

                    }} header={'Type prediction'} style={{ textAlign: "center" }}></Column>

                    {/*Verified type */}
                    <Column sortable field="duration" body={(body_val) => {
                        let check_temp_length = body_val?.histories?.length;

                        if (check_temp_length > 0) {
                            let reason = body_val?.histories[check_temp_length - 1]?.reason;

                            if (reason == "Gasslug") {
                                return "Gas slug";
                            } else if (reason == "Gaslock") {
                                return "Gas lock";
                            } else {
                                return reason;
                            }

                        } else {
                            return <>N/A</>
                        }
                    }} header={"Verified type"}></Column>



                    {/* Actions */}
                    <Column sortable field="" body={(body_val) => {
                        let check_temp_length = body_val?.histories?.length;

                        if (check_temp_length > 0) {
                            let comments = body_val?.histories[check_temp_length - 1]?.comments;

                            return <><span title={comments}>{comments}</span></>

                        } else {
                            return <>N/A</>
                        }
                    }} header={t('Comments')} style={{ borderTopRightRadius: "8px" }}></Column>



                </PrimeDatatable>
            </div>
            {/* prime datatable end */}

        </div>
    </>;
}

export default PreviousESPCallHistory;