// React
import { useEffect, useRef, useState } from 'react'

// i18n
import { useTranslation } from "react-i18next";

// Prime React
import { OverlayPanel } from "primereact/overlaypanel";
import { Button } from 'primereact/button';
import { Checkbox } from "primereact/checkbox";
import { Calendar } from 'primereact/calendar';

const SelectDaysFilter = (props: any) => {
  const { t } = useTranslation();
  const selectDaysDpDown = useRef<any>(null);
  const [selectedDays, setSelectedDays] = useState<any>("");
  const selectDaysDpDownDateRange = useRef<any>(null);
  const [date2, setDate2] = useState<Date | Date[] | undefined>(props.chosenDayDateRange);

  const selectDaysList = [
    { name: 'Last 6 hours', key: 'A' },
    { name: 'Last 12 hours', key: 'M' },
    { name: 'Last 24 hours', key: 'P' },
    { name: 'Last 7 days', key: 'R' }
  ];
  useEffect(() => {
    setDate2(props.chosenDayDateRange);
    if (props.chosenDayDateRange == "") {
      if (props?.chosenDay == "" || props?.chosenDay == " ") {
        setSelectedDays('P')
        props?.setChosenDay('P');
      }
    }


  }, []);


  const onSelectDaysChange = (e: any) => {
    if (e?.value?.key) {
      setSelectedDays(e?.value?.key)
      props?.setChosenDay(e?.value?.key);
    }
  };
  return (
    <>
      <Button severity="secondary" text raised style={{ height: "30px", fontSize: "12px", float: "right",fontWeight:"500" }} onClick={(e) => selectDaysDpDown.current.toggle(e)}>
        <i className='pi pi-calendar-plus' />&nbsp;{t('AnamolyDetectionAnalyze.SelectDays')}
      </Button>
      <OverlayPanel ref={selectDaysDpDown}>
        {selectDaysList.map((msp) => {
          return (
            <div key={msp.key} className="flex align-items-center mt-2">
              <Checkbox inputId={msp.key} name="msp" value={msp} onChange={(e: any) => {
                onSelectDaysChange(e);
                selectDaysDpDown.current.toggle(e);
              }} checked={props?.chosenDay == msp.key} onClick={(e) => { alert() }} />
              <label htmlFor={msp.key} className="ml-2">
                {msp.name}
              </label>
            </div>
          );
        })}
        <hr style={{ border: "1px solid" }} />
        <div className="flex align-items-center mt-2">
          <i className='pi pi-plus'></i>
          <label htmlFor={"Custom-date-range"} className="ml-2" onClick={(e) => { selectDaysDpDownDateRange.current.toggle(e) }} style={{ cursor: "pointer" }}>
            {t('AnamolyDetectionAnalyze.CustomDateRange')}
          </label>
        </div>
      </OverlayPanel>
      <OverlayPanel ref={selectDaysDpDownDateRange}>
        <Calendar inline id="range" value={date2} onChange={(e: any) => {
          setDate2(e.value);
          if (e.value[1] != null) {
            props.setchosenDayDateRange(e.value);

            selectDaysDpDownDateRange.current.toggle(e);
          }

        }} selectionMode="range" readOnlyInput />
      </OverlayPanel>
    </>
  )
}

export default SelectDaysFilter