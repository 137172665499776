import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface Todo {
    message: string;
}

const EspAnomalyDetectionStore = createSlice({
    name: "EspAnomalyDetectionStore",
    initialState: {
        dashboardSurvey: "",
        OperatorStoreData : [],
        AnamolyDetectionStoreData:[]
    },
    reducers: {
        addDashboardStoreData: (state, action: any) => {
            state.dashboardSurvey = action.payload;
            return state;
        },
        addOperatorStoreData: (state, action: any) => {
            state.OperatorStoreData = action.payload;
            return state;
        },
        addAnamolyDetectionStoreData: (state, action: any) => {
            state.AnamolyDetectionStoreData = action.payload;
            return state;
        }
    }
});
export const { addDashboardStoreData,addOperatorStoreData,addAnamolyDetectionStoreData } = EspAnomalyDetectionStore.actions;

export default EspAnomalyDetectionStore;