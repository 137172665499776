import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import VisualisationsGraphs from "./modules/visualisations-graphs";

const ESPModelMetrics  = () =>{
    const { t } = useTranslation();
    const navigate = useNavigate();
    return <>
    <section className="content content_background_color">
      <div className="content__header content__boxed overlapping">
        <div className="content__wrap pt-3 pb-4">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><a><i className="pli-home"></i></a></li>
              <li className="breadcrumb-item" aria-current="page"><a style={{ fontWeight: "500" }}>{t('BreadcrumAdvancedAnalytics')}</a></li>
              <li className="breadcrumb-item" aria-current="page" style={{ cursor: "pointer" }} onClick={() => {
                navigate('/dashboard')
              }}><a style={{ fontWeight: "500" }}>ESP model</a></li>
              <li className="breadcrumb-item" aria-current="page"><a style={{
                backgroundColor: "white",
                padding: "3px",
                color: "#25476a",
                borderRadius: "6px",
                paddingRight: "8px",
                paddingLeft: "8px",
                fontWeight: "500"
              }}>ESP model metrics</a></li>
            </ol>
          </nav>
          <p className="lead text-overflow">
            <p className="lead text-overflow" style={{ color: 'white', fontSize: "25px" }}>
              ChampionX ESP model analytics
            </p>
          </p>
          <div className="page-heading">
            <div className="row bg-primary" id="divCustFilter">
              <div className="col-lg-3 col-md-4 col-sm-12 col-xs-12">
                <div className="row">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="content__boxed">
        <div className="content__wrap">
          <div className="card" style={{ padding: "18px" }}>
            <VisualisationsGraphs />
            
          </div>
        </div>
      </div>
    </section>
    </>
}

export default ESPModelMetrics;