import { useEffect, useRef, useState } from "react";
import { Button } from "primereact/button";
import { OverlayPanel } from "primereact/overlaypanel";
import { useTranslation } from "react-i18next";
import DraggableList from "react-draggable-list";
const SelectNormalFilterRawTrendHistory = (props: any) => {
  const { t } = useTranslation();

  const filterDpDown = useRef<any>(null);

  const [eyeStateCurrentAnamoly, seteyeStateCurrentAnamoly] =
    useState("pi-eye");
  const [eyeStatePastAnamoly, seteyeStatePastAnamoly] = useState("pi-eye");
  const [eyeStateFrequncyMotor, seteyeStateFrequncyMotor] = useState("pi-eye");
  const [eyeStateTempMotor, seteyeStateTempMotor] = useState("pi-eye");
  const [eyePressIntake, seteyePressIntake] = useState("pi-eye");
  const [tempIntake, settempIntake] = useState("pi-eye");
  const [currMotor, settempcurrMotor] = useState("pi-eye");
  const [currPressure, setcurrPressure] = useState("pi-eye");
  const [TubingPressure, setTubingPressure] = useState("pi-eye");

  useEffect(() => {
    if (props.viewCurrentAnamolyState) {
      seteyeStateCurrentAnamoly("pi-eye");
    } else {
      seteyeStateCurrentAnamoly("pi-eye-slash");
    }

    if (props.viewPastAnamolyState) {
      seteyeStatePastAnamoly("pi-eye");
    } else {
      seteyeStatePastAnamoly("pi-eye-slash");
    }
  }, [props]);
  const raw_list = [
    { id: 1, name: "ch4" },
    { id: 2, name: "total_emission" },
    { id: 3, name: "pressure" },
    { id: 4, name: "temp" },
    { id: 5, name: "wind_speed" },
    { id: 6, name: "direction" },
  ];
  const [list, setList] = useState(raw_list);
  const containerRef = useRef();
  const _onListChange = (newList: any) => {
    setList(newList);
    props.setSequence(newList);
  };
  const Item = ({ item, itemSelected, dragHandleProps }: any) => {
    const { onMouseDown, onTouchStart } = dragHandleProps;

    return (
      <>
        {item.name == "ch4" && (
          <div
            className="flex align-items-center mt-2"
            onClick={(e) => {
              if (eyeStateFrequncyMotor == "pi-eye") {
                seteyeStateFrequncyMotor("pi-eye-slash");
                props.setcheckFilterCH4(false);
              } else {
                seteyeStateFrequncyMotor("pi-eye");
                props.setcheckFilterCH4(true);
              }
            }}
            style={{ cursor: "pointer" }}
          >
            <i
              className="pi pi-ellipsis-v"
              style={{ marginRight: "-10px" }}
            ></i>
            <i
              className="pi pi-ellipsis-v"
              onTouchStart={(e: any) => {
                e.preventDefault();
                // e.target.style.backgroundColor = "blue";
                // document.body.style.overflow = "hidden";
                onTouchStart(e);
              }}
              onMouseDown={(e) => {
                // document.body.style.overflow = "hidden";
                onMouseDown(e);
              }}
              onTouchEnd={(e: any) => {
                // e.target.style.backgroundColor = "black";
                // document.body.style.overflow = "visible";
              }}
              onMouseUp={() => {
                // document.body.style.overflow = "visible";
              }}
            ></i>
            <span className="p-2">{"CH4"}</span>
            <label htmlFor={"rsp"} className="ml-2 float_eyes_toRight_raw">
              <i className={`pi ${eyeStateFrequncyMotor}`}></i>
            </label>
          </div>
        )}

        {item.name == "total_emission" && (
          <div
            className="flex align-items-center mt-2"
            onClick={(e) => {
              if (eyeStateTempMotor == "pi-eye") {
                seteyeStateTempMotor("pi-eye-slash");
                props.setcheckFilterTotalEmission(false);
              } else {
                seteyeStateTempMotor("pi-eye");
                props.setcheckFilterTotalEmission(true);
              }
            }}
            style={{ cursor: "pointer" }}
          >
            <i
              className="pi pi-ellipsis-v"
              style={{ marginRight: "-10px" }}
            ></i>
            <i
              className="pi pi-ellipsis-v"
              onTouchStart={(e: any) => {
                e.preventDefault();
                // e.target.style.backgroundColor = "blue";
                // document.body.style.overflow = "hidden";
                onTouchStart(e);
              }}
              onMouseDown={(e) => {
                // document.body.style.overflow = "hidden";
                onMouseDown(e);
              }}
              onTouchEnd={(e: any) => {
                // e.target.style.backgroundColor = "black";
                // document.body.style.overflow = "visible";
              }}
              onMouseUp={() => {
                // document.body.style.overflow = "visible";
              }}
            ></i>
            <span className="p-2">{"Total emission"}</span>
            <label htmlFor={"rsp"} className="ml-2 float_eyes_toRight_raw">
              <i className={`pi ${eyeStateTempMotor}`}></i>
            </label>
          </div>
        )}

        {item.name == "pressure" && (
          <div
            className="flex align-items-center mt-2"
            onClick={(e) => {
              if (eyePressIntake == "pi-eye") {
                seteyePressIntake("pi-eye-slash");
                props.setcheckFilterPressure(false);
              } else {
                seteyePressIntake("pi-eye");
                props.setcheckFilterPressure(true);
              }
            }}
            style={{ cursor: "pointer" }}
          >
            <i
              className="pi pi-ellipsis-v"
              style={{ marginRight: "-10px" }}
            ></i>
            <i
              className="pi pi-ellipsis-v"
              onTouchStart={(e: any) => {
                e.preventDefault();
                // e.target.style.backgroundColor = "blue";
                // document.body.style.overflow = "hidden";
                onTouchStart(e);
              }}
              onMouseDown={(e) => {
                // document.body.style.overflow = "hidden";
                onMouseDown(e);
              }}
              onTouchEnd={(e: any) => {
                // e.target.style.backgroundColor = "black";
                // document.body.style.overflow = "visible";
              }}
              onMouseUp={() => {
                // document.body.style.overflow = "visible";
              }}
            ></i>
            <span className="p-2">{"Pressure"}</span>
            <label htmlFor={"rsp"} className="ml-2 float_eyes_toRight_raw">
              <i className={`pi ${eyePressIntake}`}></i>
            </label>
          </div>
        )}

        {item.name == "temp" && (
          <div
            className="flex align-items-center mt-2"
            onClick={(e) => {
              if (tempIntake == "pi-eye") {
                settempIntake("pi-eye-slash");
                props.setcheckFilterTemp(false);
              } else {
                settempIntake("pi-eye");
                props.setcheckFilterTemp(true);
              }
            }}
            style={{ cursor: "pointer" }}
          >
            <i
              className="pi pi-ellipsis-v"
              style={{ marginRight: "-10px" }}
            ></i>
            <i
              className="pi pi-ellipsis-v"
              onTouchStart={(e: any) => {
                e.preventDefault();
                // e.target.style.backgroundColor = "blue";
                // document.body.style.overflow = "hidden";
                onTouchStart(e);
              }}
              onMouseDown={(e) => {
                // document.body.style.overflow = "hidden";
                onMouseDown(e);
              }}
              onTouchEnd={(e: any) => {
                // e.target.style.backgroundColor = "black";
                // document.body.style.overflow = "visible";
              }}
              onMouseUp={() => {
                // document.body.style.overflow = "visible";
              }}
            ></i>
            <span className="p-2">{"Temperature"} (&#x2109;)</span>
            <label htmlFor={"rsp"} className="ml-2 float_eyes_toRight_raw">
              <i className={`pi ${tempIntake}`}></i>
            </label>
          </div>
        )}

        {item.name == "wind_speed" && (
          <div
            className="flex align-items-center mt-2"
            onClick={(e) => {
              if (currMotor == "pi-eye") {
                settempcurrMotor("pi-eye-slash");
                props.setcheckFilterWindSpeed(false);
              } else {
                settempcurrMotor("pi-eye");
                props.setcheckFilterWindSpeed(true);
              }
            }}
            style={{ cursor: "pointer" }}
          >
            <i
              className="pi pi-ellipsis-v"
              style={{ marginRight: "-10px" }}
            ></i>
            <i
              className="pi pi-ellipsis-v"
              onTouchStart={(e: any) => {
                e.preventDefault();
                // e.target.style.backgroundColor = "blue";
                // document.body.style.overflow = "hidden";
                onTouchStart(e);
              }}
              onMouseDown={(e) => {
                // document.body.style.overflow = "hidden";
                onMouseDown(e);
              }}
              onTouchEnd={(e: any) => {
                // e.target.style.backgroundColor = "black";
                // document.body.style.overflow = "visible";
              }}
              onMouseUp={() => {
                // document.body.style.overflow = "visible";
              }}
            ></i>
            <span className="p-2">{"Wind speed"}</span>
            <label htmlFor={"rsp"} className="ml-2 float_eyes_toRight_raw">
              <i className={`pi ${currMotor}`}></i>
            </label>
          </div>
        )}
        {item.name == "direction" && (
          <div
            className="flex align-items-center mt-2"
            onClick={(e) => {
              if (currPressure == "pi-eye") {
                setcurrPressure("pi-eye-slash");
                props.setcheckFilterDirection(false);
              } else {
                setcurrPressure("pi-eye");
                props.setcheckFilterDirection(true);
              }
            }}
            style={{ cursor: "pointer" }}
          >
            <i
              className="pi pi-ellipsis-v"
              style={{ marginRight: "-10px" }}
            ></i>
            <i
              className="pi pi-ellipsis-v"
              onTouchStart={(e: any) => {
                e.preventDefault();
                // e.target.style.backgroundColor = "blue";
                // document.body.style.overflow = "hidden";
                onTouchStart(e);
              }}
              onMouseDown={(e) => {
                // document.body.style.overflow = "hidden";
                onMouseDown(e);
              }}
              onTouchEnd={(e: any) => {
                // e.target.style.backgroundColor = "black";
                // document.body.style.overflow = "visible";
              }}
              onMouseUp={() => {
                // document.body.style.overflow = "visible";
              }}
            ></i>
            <span className="p-2">Direction</span>
            <label htmlFor={"rsp"} className="ml-2 float_eyes_toRight_raw">
              <i className={`pi ${currPressure}`}></i>
            </label>
          </div>
        )}
      </>
    );
  };
  return (
    <>
      <Button
        severity="secondary"
        text
        raised
        style={{
          fontWeight: "500",
          height: "30px",
          fontSize: "12px",
          float: "right",
          marginLeft: "12px",
        }}
        onClick={(e) => filterDpDown.current.toggle(e)}
      >
        <i className="pi pi-filter" />
        &nbsp;{t("AnamolyDetectionAnalyze.filters")}
      </Button>
      <OverlayPanel ref={filterDpDown}>
        <div
          className="flex align-items-center mt-2"
          style={{ fontWeight: 500 }}
        >
          {t("AnamolyDetectionAnalyze.ViewOptions")}
        </div>
        <div
          className="flex align-items-center mt-4"
          style={{ cursor: "pointer" }}
          onClick={() => {
            if (eyeStateCurrentAnamoly == "pi-eye") {
              seteyeStateCurrentAnamoly("pi-eye-slash");
              props.setviewCurrentAnamolyState(false);
            } else {
              seteyeStateCurrentAnamoly("pi-eye");
              props.setviewCurrentAnamolyState(true);
            }
          }}
        >
          {t("AnamolyDetectionAnalyze.ViewCurrentAnamoly")}
          <label htmlFor={"rsp"} className="ml-2 float_eyes_toRightviewOP">
            <i className={`pi ${eyeStateCurrentAnamoly}`}></i>
          </label>
        </div>

        <div
          className="flex align-items-center mt-4"
          style={{ cursor: "pointer" }}
          onClick={() => {
            if (eyeStatePastAnamoly == "pi-eye") {
              seteyeStatePastAnamoly("pi-eye-slash");
              props.setviewPastAnamolyState(false);
            } else {
              seteyeStatePastAnamoly("pi-eye");
              props.setviewPastAnamolyState(true);
            }
          }}
        >
          {t("AnamolyDetectionAnalyze.ViewHistoryAnamolies")}
          <label htmlFor={"rsp"} className="ml-2 float_eyes_toRightviewOP">
            <i className={`pi ${eyeStatePastAnamoly}`}></i>
          </label>
        </div>
        <hr style={{ border: "1px solid" }} />
        <div
          className="flex align-items-center mt-2"
          style={{ fontWeight: 500 }}
        >
          {"Trend lines"}
        </div>

        <div
          ref={containerRef as any}
          style={{ touchAction: "pan-y", width: "210px" }}
        >
          <DraggableList
            itemKey="id"
            template={Item as any}
            list={list}
            onMoveEnd={(newList: any) => _onListChange(newList)}
            container={() => containerRef.current}
          />
          {/* {list.map((item) => (
          <Item item={item} />
        ))} */}
        </div>
      </OverlayPanel>
    </>
  );
};

export default SelectNormalFilterRawTrendHistory;
