// amcharts4
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

// React
import { useEffect, useState } from "react";

// Filters
import SelectDaysFilter from "./select-days-filter";
import SelectNormalFilterRawTrendHistory from "./filters/select-normal-filter-raw-trend-history";

// i18n
import { t } from "i18next";

// Helpers
import Helpers from "../../../../common/utils/helpers";
import { any } from "@amcharts/amcharts4/.internal/core/utils/Array";
// Themes begin
am4core.useTheme(am4themes_animated);
// Themes end

const RawTrendHistory = (props: any) => {
  var showAnamolyLevel = "";
  const ESPCallHistoryData = props.ESPCallHistoryData;
  const ESPAIvsHeathIndexData = props.ESPAIvsHeathIndexData;
  const CurPageRawTrendAnamolyType = props.CurPageRawTrendAnamolyType;
  const [showSelectedFilterText, setshowSelectedFilterText] = useState("");
  const [Sequence, setSequence] = useState([
    { id: 1, name: "ch4" },
    { id: 2, name: "total_emission" },
    { id: 3, name: "pressure" },
    { id: 4, name: "temp" },
    { id: 5, name: "wind_speed" },
    { id: 6, name: "direction" },
  ]);

  const selectFilterList = [
    { name: t("AnamolyDetectionAnalyze.ViewCurrentAnamoly"), key: "A" },
    { name: t("AnamolyDetectionAnalyze.ViewHistoryAnamolies"), key: "M" },
    { name: "CH4", key: "P", after: 1 },
    { name: "Total emission", key: "R", after: 1 },
    { name: "Pressure", key: "R", after: 1 },
    { name: "Temprature", key: "R", after: 1 },
    { name: "Wind Speed", key: "R", after: 1 },
    { name: "Direction", key: "R", after: 1 },
  ];

  const [checkFilterCH4, setcheckFilterCH4] = useState(true);
  const [checkFilterTotalEmission, setcheckFilterTotalEmission] =
    useState(true);
  const [checkFilterTemp, setcheckFilterTemp] = useState(true);
  const [checkFilterPressure, setcheckFilterPressure] = useState(true);
  const [checkFilterWindSpeed, setcheckFilterWindSpeed] = useState(true);
  const [checkFilterDirection, setcheckFilterDirection] = useState(true);
  const [checkFilterTubingPress, setcheckFilterTubingPress] = useState(true);

  useEffect(() => {
    let temp_array: any = [];
    if (ESPCallHistoryData.length > 0) {
      ESPCallHistoryData.map((val: any) => {
        temp_array.push(val);
      });
    }
    var chart: any = am4core.create(
      "RawTrendHistorychartdiv",
      am4charts.XYChart
    );

    if (chart.logo) {
      chart.logo.disabled = true;
    }

    let previous_anomaly_array: any = [];
    ESPCallHistoryData.forEach((val1: any) => {
      if (val1.anomaly == 1) {
        previous_anomaly_array.push(val1);
      }
    });

    function DynamicToolTip(
      tooltipAllData: any,
      CurPageRawTrendAnamolyType: any,
      tooltipDate: any,
      CH4: any,
      WindSpeed: any,
      TotalEmission: any,
      Direction: any,
      Temp: any,
      Pressure: any,
      TubingPressure: any,
      AnomalyType: any,
      boldNum: any
    ) {
      let tooltip_previous_AnomalyStartDate = "";
      let tooltip_previous_AnomalyEndDate = "";

        

      previous_anomaly_array.forEach((val: any) => {
        if (
          new Date(Helpers.convertFullDateToReadableFormat(val.startDate)) <
            new Date(
              Helpers.convertFullDateToReadableFormat(tooltipAllData.date)
            ) &&
          new Date(Helpers.convertFullDateToReadableFormat(val.endDate)) >
            new Date(
              Helpers.convertFullDateToReadableFormat(tooltipAllData.date)
            )
        ) {
          tooltip_previous_AnomalyStartDate =
            Helpers.convertFullDateToDateReadableFormatToolTip(val.startDate);
          tooltip_previous_AnomalyEndDate =
            Helpers.convertFullDateToDateReadableFormatToolTip(val.endDate);
        }
      });

      let AnomalyTypeCheck = tooltipAllData.AnomalyType;

      if (tooltipAllData.AnomalyType == "2") {
        AnomalyTypeCheck =
          "<span style='color:green;float:right;display: inline-block;padding: 0.35em 0.65em;font-size: 0.95em;font-weight: 700;line-height: 1;color: #fff;text-align: center;white-space: nowrap;vertical-align: baseline;border-radius: 0.4375rem;border-radius: 6px !important;background-color: #ddeefa !important;color: #077ac9 !important;'>Gas slug</span>";
      } else if (tooltipAllData.AnomalyType == "1") {
        AnomalyTypeCheck =
          "<span style='color:green;float:right;display: inline-block;padding: 0.35em 0.65em;font-size: 0.95em;font-weight: 700;line-height: 1;color: #fff;text-align: center;white-space: nowrap;vertical-align: baseline;border-radius: 0.4375rem;border-radius: 6px !important;background-color: #eaf5f4 !important;color: #84c6bc !important;'>Gas lock</span>";
      } else if (
        tooltipAllData.AnomalyType == null ||
        tooltipAllData.AnomalyType == ""
      ) {
        if (tooltipAllData.Anomaly == "0") {
          AnomalyTypeCheck = "N/A";
        } else if (tooltipAllData.AnomalyLevel == "Shutdown") {
          AnomalyTypeCheck = "N/A";
        } else if (tooltipAllData.AnomalyLevel == "Level 1") {
          AnomalyTypeCheck = "-";
        } else if (tooltipAllData.AnomalyLevel == "Level 2") {
          AnomalyTypeCheck = "-";
        } else if (tooltipAllData.Anomaly == "1") {
          AnomalyTypeCheck = "-";
        } else {
          AnomalyTypeCheck = "N/A";
        }
      }

      let anomaly_level_badge = "";
      let AnomalyDatesTag = "";
      let SequenceTooltip = "";
      Sequence.forEach((val: any) => {
        if (val.name == "ch4") {
          let temp_color_index = 0;
          
          for (let key in tooltipAllData.CH4[0]) {
            if (tooltipAllData.CH4[0].hasOwnProperty(key)) {
              let value = tooltipAllData.CH4[0][key];
              
              SequenceTooltip += `
              
              
                    <tr>
                        <td>
                        <span style='display: inline-block;
                        padding: 0.35em 0.65em;
                        font-size: 0.75em;
                        font-weight: 700;
                        line-height: 1;
                        color: #fff;
                        text-align: center;
                        white-space: nowrap;
                        vertical-align: baseline;
                        border-radius: 0.4375rem;
                        background-color:${color_device[temp_color_index]}'></span>
                        ${boldNum == 1 ? "<b>"+key+"</b>" : key}:</td>
                        <td style="float:right">${Number(value).toFixed(2)}</td>
                    </tr>
                    `;
            }
            temp_color_index++;
            

          }
          
        }

        if (val.name == "total_emission") {
          SequenceTooltip += `
                    <tr>
                <td>${
                  boldNum == 2 ? "<b>Total emission</b>" : "Total emission"
                }:</td>
                <td style="float:right">${TotalEmission}</td>
            </tr>
                    `;
        }
        if (val.name == "pressure") {
          let temp_color_index = 0;
          
          for (let key in tooltipAllData.Pressure[0]) {
            if (tooltipAllData.Pressure[0].hasOwnProperty(key)) {
              let value = tooltipAllData.Pressure[0][key];
              
              SequenceTooltip += `
              
              
                    <tr>
                        <td>
                        <span style='display: inline-block;
                        padding: 0.35em 0.65em;
                        font-size: 0.75em;
                        font-weight: 700;
                        line-height: 1;
                        color: #fff;
                        text-align: center;
                        white-space: nowrap;
                        vertical-align: baseline;
                        border-radius: 0.4375rem;
                        background-color:${color_device[temp_color_index]}'></span>
                        ${boldNum == 3 ? "<b>"+key+"</b>" : key}:</td>
                        <td style="float:right">${Number(value).toFixed(2)}</td>
                    </tr>
                    `;
            }
            temp_color_index++;
            

          }
        }
        if (val.name == "temp") {
          let temp_color_index = 0;
          
          for (let key in tooltipAllData.Temp[0]) {
            if (tooltipAllData.Temp[0].hasOwnProperty(key)) {
              let value = tooltipAllData.Temp[0][key];
              
              SequenceTooltip += `
              
              
                    <tr>
                        <td>
                        <span style='display: inline-block;
                        padding: 0.35em 0.65em;
                        font-size: 0.75em;
                        font-weight: 700;
                        line-height: 1;
                        color: #fff;
                        text-align: center;
                        white-space: nowrap;
                        vertical-align: baseline;
                        border-radius: 0.4375rem;
                        background-color:${color_device[temp_color_index]}'></span>
                        ${boldNum == 4 ? "<b>"+key+"</b>" : key}:</td>
                        <td style="float:right">${Number(value).toFixed(2)}</td>
                    </tr>
                    `;
            }
            temp_color_index++;
            

          }
        }
        if (val.name == "wind_speed") {
          
          
           
              let value = tooltipAllData.WindSpeed[0]['WSP'];
              
              SequenceTooltip += `
              
              
                    <tr>
                        <td>
                        <span style='display: inline-block;
                        padding: 0.35em 0.65em;
                        font-size: 0.75em;
                        font-weight: 700;
                        line-height: 1;
                        color: #fff;
                        text-align: center;
                        white-space: nowrap;
                        vertical-align: baseline;
                        border-radius: 0.4375rem;
                        background-color:#9900cc'></span>
                        ${boldNum == 5 ? "<b>WSP</b>" : "WSP"}:</td>
                        <td style="float:right">${Number(value).toFixed(2)}</td>
                    </tr>
                    `;

                    let value1 = tooltipAllData.WindSpeed[0]['WDR'];
              
              SequenceTooltip += `
              
              
                    <tr>
                        <td>
                        <span style='display: inline-block;
                        padding: 0.35em 0.65em;
                        font-size: 0.75em;
                        font-weight: 700;
                        line-height: 1;
                        color: #fff;
                        text-align: center;
                        white-space: nowrap;
                        vertical-align: baseline;
                        border-radius: 0.4375rem;
                        background-color:#0099cc'></span>
                        ${boldNum == 5 ? "<b>WDR</b>" : "WDR"}:</td>
                        <td style="float:right">${Number(value1).toFixed(2)}</td>
                    </tr>
                    `;
            
            

        }
      });
      if (tooltipAllData.Anomaly == "0") {
        anomaly_level_badge =
          "<span style='color:green;float:right;display: inline-block;padding: 0.35em 0.65em;font-size: 0.95em;font-weight: 700;line-height: 1;color: #fff;text-align: center;white-space: nowrap;vertical-align: baseline;border-radius: 0.4375rem;border-radius: 6px !important;background-color: #ecfdf3 !important;color: #0b643f !important;'>Normal</span>";
      } else if (tooltipAllData.AnomalyLevel == "Shutdown") {
        anomaly_level_badge =
          "<span style='color:green;float:right;display: inline-block;padding: 0.35em 0.65em;font-size: 0.95em;font-weight: 700;line-height: 1;color: #fff;text-align: center;white-space: nowrap;vertical-align: baseline;border-radius: 0.4375rem;border-radius: 6px !important;background-color: #f0f1f2 !important;color: #0000007d !important;'>Shutdown</span>";
      } else if (tooltipAllData.AnomalyLevel == "Level 1") {
        anomaly_level_badge =
          "<span style='color:green;float:right;display: inline-block;padding: 0.35em 0.65em;font-size: 0.95em;font-weight: 700;line-height: 1;color: #fff;text-align: center;white-space: nowrap;vertical-align: baseline;border-radius: 0.4375rem;border-radius: 6px !important;background-color: #fef3f2 !important;color: #992b0abf !important;'>Anomaly</span>";
      } else if (tooltipAllData.AnomalyLevel == "Level 2") {
        anomaly_level_badge =
          "<span style='color:green;float:right;display: inline-block;padding: 0.35em 0.65em;font-size: 0.95em;font-weight: 700;line-height: 1;color: #fff;text-align: center;white-space: nowrap;vertical-align: baseline;border-radius: 0.4375rem;border-radius: 6px !important;background-color: #fff4eb !important;color: #df9612 !important;'>Level 2</span>";
      } else if (tooltipAllData.Anomaly == "1") {
        anomaly_level_badge =
          "<span style='float:right;display: inline-block;padding: 0.35em 0.65em;font-size: 0.95em;font-weight: 700;line-height: 1;color: #fff;text-align: center;white-space: nowrap;vertical-align: baseline;border-radius: 0.4375rem;border-radius: 6px !important;background-color: #fef3f2 !important;color: #992b0abf !important;'>Anomaly</span>";
      } else {
        anomaly_level_badge = "N/A";
      }
      if (tooltipAllData.isCurrentAnomaly) {
        AnomalyDatesTag = `<tr>
                <td style="visibility:hidden">asd </td>
                <td style="float:right;display:none">asd </td>
                
                </tr>
                


                <tr>
                <td>Start date time:</td>
                <td style="float:right">{anomalySDate}</td>
              </tr>
              <tr>
                <td>End date time:</td>
                <td style="float:right">{anomalyEDate}</td>
              </tr>
                <tr>
                
                <tr>
                <td style="visibility:hidden">asd </td>
                <td style="float:right;display:none">asd </td>
                
            </tr>
            `;
      } else if (tooltip_previous_AnomalyStartDate != "") {
        AnomalyDatesTag = `
                
                <tr>
                <td style="visibility:hidden">asd </td>
                <td style="float:right;display:none">asd </td>
                
                </tr><tr>
                <tr>
                <td>Date:</td>
                <td style="float:right"> ${Helpers.convertFullDateToReadableFormatToolTip(
                  tooltipAllData.date
                )}</td>
                </tr>

                <tr>
                <td>Time:</td>
                <td style="float:right"> ${Helpers.convertOnlyTimeToReadableFormat(
                  tooltipAllData.date
                )}</td>
                </tr>
                <td>Start date time:</td>
                <td style="float:right">${tooltip_previous_AnomalyStartDate}</td>
              </tr>
              <tr>
                <td>End date time:</td>
                <td style="float:right">${tooltip_previous_AnomalyEndDate}</td>
              </tr>
                <tr>
                
                <tr>
                <td style="visibility:hidden">asd </td>
                <td style="float:right;display:none">asd </td>
                
            </tr>
            `;
      }

      // <tr>
      //           <td><b>Severity level:</b></td>
      //           <td style="float:right">${anomaly_level_badge}</td>
      //       </tr>
      //       <tr>
      //           <td style="padding-top:5px;padding-bottom:5px"><b>Anomaly type:</b></td>
      //           <td style="float:right;padding-top:5px;padding-bottom:5px">${AnomalyTypeCheck}</td>
      //       </tr>
      return `<table style="color:black;margin-top:10px;margin-bottom:15px;">
            <tbody>

             <tr>
                 <td><b>Severity level:</b></td>
                 <td style="float:right">${anomaly_level_badge}</td>
            </tr>
            
            <tr>
                <td>Date:</td>
                <td style="float:right"> ${Helpers.convertFullDateToReadableFormatToolTip(
                  tooltipAllData.date
                )}</td>
                </tr>

                <tr>
                <td>Time:</td>
                <td style="float:right"> ${Helpers.convertOnlyTimeToReadableFormat(
                  tooltipAllData.date
                )}</td>
                </tr>
            ${AnomalyDatesTag}
            
            ${SequenceTooltip}

    

            
            </tbody>
        </table>`;

      // Dynamic tooltip function end
    }

    let temp_data: any = [];
    let color_device:any = ["#00ff00","#ffcc00","#4b0082","#ff6600"]
    let hours = 0;
    var d = new Date();
    if (props?.chosenDay == "A") {
      hours = 6;
    } else if (props?.chosenDay == "M") {
      hours = 12;
    } else if (props?.chosenDay == "P") {
      hours = 24;
    } else if (props?.chosenDay == "R") {
      hours = 168;
    }
    if (props.chosenDayDateRange[1] != null) {
      hours = 0;
    }
    hours = 0;
    d.setHours(d.getHours() - hours);
   
    if (props?.ESPRawTrendData?.length > 0) {
      
      props?.ESPRawTrendData?.map((val: any) => {
        let formattedDateForToolTip =
          Helpers.convertFullDateToReadableFormatToolTip(val.Time);
        let isCurrentAnomaly = false;

        let val_date = new Date(
          Helpers.convertFullDateToReadableFormat(val.Time)
        );
        let sDate = new Date(
          Helpers.convertFullDateToReadableFormat(props.currentAnamolyStartDate)
        );
        let eDate: any = "";
        let DisplayEndDateAnomaly = "";

        if (props.currentAnamolyEndDate == "null") {
          eDate = new Date();
          DisplayEndDateAnomaly = "In progress";
        } else {
          eDate = new Date(
            Helpers.convertFullDateToReadableFormat(props.currentAnamolyEndDate)
          );
          DisplayEndDateAnomaly =
            Helpers.convertFullDateToDateReadableFormatToolTip(
              props.currentAnamolyEndDate
            );
        }
        if (val_date >= sDate && val_date <= eDate) {
          // test start
          if (val?.Anomaly == "0") {
            isCurrentAnomaly = false;
          } else if (val?.Anomaly == "-1") {
            isCurrentAnomaly = false;
          } else if (val.Anomaly == "1") {
            isCurrentAnomaly = true;
          }
        }

        if (val.Anamoly == 1) {
          showAnamolyLevel = "Anomaly";
        } else if (val.Anamoly == 0) {
          showAnamolyLevel = "Normal";
        } else if (val.AnomalyLevel == 1) {
          showAnamolyLevel = "Level 1";
        } else if (val.AnomalyLevel == 2) {
          showAnamolyLevel = "Level 2";
        } else if (val.AnomalyLevel == 3) {
          showAnamolyLevel = "Level 3";
        } else {
          showAnamolyLevel = "";
          // ESPAIvsHeathIndexData.forEach((val1: any) => {
          //     let check_for_anomaly = Helpers.getHoursForRawTrendHistoryAnamoly(val1.time, val.Time);
          //     if (check_for_anomaly) {
          //         if (val1.anomaly == -1) {
          //             showAnamolyLevel = "Shutdown"
          //         } else if (val1.anomaly == 0) {
          //             showAnamolyLevel = "Normal"
          //         } else if (val1.anomalyLevel == 1) {
          //             showAnamolyLevel = "Level 1"
          //         } else if (val1.anomalyLevel == 2) {
          //             showAnamolyLevel = "Level 2"
          //         } else if (val1.anomalyLevel == 3) {
          //             showAnamolyLevel = "Level 3"
          //         }
          //     }
          // })
        }
        if (hours == 0) {
          temp_data.push({
            Anomaly : val.Anomaly,
            date: new Date(val.Time),
            CH4: val.CH4,
            WindSpeed: val.WindSpeed,
            TotalEmission: val.TotalEmission,
            Direction: val.Direction,
            Temp: val.Temp,
            Pressure: val.Pressure,
            TubingPressure: val.TubingPressure,
            AnomalyLevel: showAnamolyLevel,
            tooltipDate: formattedDateForToolTip,
            CurPageRawTrendAnamolyType: CurPageRawTrendAnamolyType,
            isCurrentAnomaly: isCurrentAnomaly,
            anomalySDate:
              Helpers.convertFullDateToDateReadableFormatToolTip(sDate),
            anomalyEDate: DisplayEndDateAnomaly,
            AnomalyType: val.AnomalyType,
            ...val.CH4[0],
            ...val.Pressure[0],
            ...val.Temp[0],
            ...val.WindSpeed[0]
          });
        } else {
          if (new Date(val.Time) > d) {
            temp_data.push({
              Anomaly : val.Anomaly,
              date: new Date(val.Time),
              CH4: val.CH4,
              WindSpeed: val.WindSpeed,
              TotalEmission: val.TotalEmission,
              Direction: val.Direction,
              Temp: val.Temp,
              Pressure: val.Pressure,
              TubingPressure: val.TubingPressure,
              AnomalyLevel: showAnamolyLevel,
              tooltipDate: formattedDateForToolTip,
              CurPageRawTrendAnamolyType: CurPageRawTrendAnamolyType,
              isCurrentAnomaly: isCurrentAnomaly,
              anomalySDate:
                Helpers.convertFullDateToDateReadableFormatToolTip(sDate),
              anomalyEDate: DisplayEndDateAnomaly,
              AnomalyType: val.AnomalyType,
              ...val.CH4[0],
              ...val.Pressure[0],
              ...val.Temp[0],
              ...val.WindSpeed[0]

            });
          }
        }
      });
    }

    // if (props.viewPastAnamolyState === false && props.viewCurrentAnamolyState === false) {
    //     temp_data = []
    // }
    chart.data = temp_data;
    // the following line makes value axes to be arranged vertically.
    chart.leftAxesContainer.layout = "vertical";

    // uncomment this line if you want to change order of axes

    var dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.tooltipDateFormat = "d MMM , hh:mm a";
    dateAxis.dateFormats.setKey("hour", "hh:mm a");
    // dateAxis.events.on("startchanged", dateAxisChanged);
    // dateAxis.events.on("endchanged", dateAxisChanged);

    dateAxis.startLocation = 0.5;
    dateAxis.endLocation = 0.5;
    dateAxis.renderer.grid.template.location = 0;
    dateAxis.renderer.ticks.template.length = 8;
    dateAxis.renderer.ticks.template.strokeOpacity = 0.1;
    dateAxis.renderer.grid.template.strokeWidth = 0;
    dateAxis.renderer.ticks.template.disabled = false;
    dateAxis.renderer.ticks.template.strokeOpacity = 0.2;

    // {
    //     dateAxis.baseInterval = {
    //         "timeUnit": "minute",
    //         "count": 60
    //     };
    // }

    dateAxis.groupCount = 1500;
    dateAxis.keepSelection = true;

    chart.leftAxesContainer.pixelPerfect = true;
    dateAxis.pixelPerfect = true;
    dateAxis.renderer.pixelPerfect = true;
    dateAxis.renderer.gridContainer.layout = "absolute";
    var tooltipHTML = "";
    if (showAnamolyLevel == "") {
      tooltipHTML = `<table style="margin-top:10px;margin-bottom:15px;">
        <tbody>
          

          <tr>
            <td style="padding-top:5px;padding-bottom:5px">Anomaly type:</td>
            <td style="float:right;padding-top:5px;padding-bottom:5px">{CurPageRawTrendAnamolyType}</td>
          </tr>

          <tr>
            <td>CH4:</td>
            <td style="float:right">{CH4} Hz</td>
          </tr>


          <tr>
            <td>Pressure - intake:</td>
            <td style="float:right">{TotalEmission} psi</td>
          </tr>

          <tr>
          <td>Casing - pressure:</td>
          <td style="float:right">{Pressure} psi</td>
        </tr>

        <tr>
          <td>Current - motor:</td>
          <td style="float:right">{Temp} amp</td>
        </tr>



          <tr>
            <td>Tempurature - motor:</td>
            <td style="float:right">{WindSpeed} (&#8457)</td>
          </tr>

          

          <tr>
          <td>Tempurature - intake:</td>
          <td style="float:right">{Direction} (&#8457)</td>
        </tr>

          

        

        
          
        </tbody>
      </table>`;
    } else {
      tooltipHTML = `<table style="color:black;margin-top:10px;margin-bottom:15px;">
        <tbody>

        <tr>
            <td><b>Severity Level:</b></td>
            <td style="float:right">{AnomalyLevel}</td>
          </tr>


          

          <tr>
            <td style="padding-top:5px;padding-bottom:5px">Anomaly type:</td>
            <td style="float:right;padding-top:5px;padding-bottom:5px">{CurPageRawTrendAnamolyType}</td>
          </tr>

          <tr>
            <td>CH4:</td>
            <td style="float:right">{CH4} Hz</td>
          </tr>

          <tr>
            <td>Tempurature - motor:</td>
            <td style="float:right">{WindSpeed} (&#8457)</td>
          </tr>

          <tr>
            <td>Pressure - intake:</td>
            <td style="float:right">{TotalEmission} psi</td>
          </tr>

          <tr>
          <td>Tempurature - intake:</td>
          <td style="float:right">{Direction} (&#8457)</td>
        </tr>

          <tr>
          <td>Current - motor:</td>
          <td style="float:right">{Temp} amp</td>
        </tr>

        <tr>
          <td>Casing - pressure:</td>
          <td style="float:right">{Pressure} psi</td>
        </tr>

        <tr>
          <td>Tubing - pressure:</td>
          <td style="float:right">{TubingPressure} psi</td>
        </tr>
          
        </tbody>
      </table>`;
    }

    Sequence.forEach((Seq_val, Seq_id) => {
      if (Seq_val.name == "ch4") {
        // Chart 1 : frequency
        if (checkFilterCH4 === true) {
          // Chart 2: Tempurature - motor
          var valueAxis2: any = chart.yAxes.push(new am4charts.ValueAxis());
          
          valueAxis2.title.text = "CH4";
          valueAxis2.title.fontSize = "11px";
          valueAxis2.title.marginRight = "30px";
          valueAxis2.title.fontWeight = "700";
          valueAxis2.renderer.inside = false;
          valueAxis2.zIndex = 1;
          valueAxis2.renderer.baseGrid.disabled = true;

          // valueAxis.renderer.line.strokeOpacity = 0.6;
          // height of axis
          valueAxis2.height = am4core.percent(40);
          valueAxis2.renderer.labels.template.verticalCenter = "bottom";
          valueAxis2.renderer.labels.template.padding(8, 8, -4, 2);
          valueAxis2.renderer.fontSize = "0.8em";
          // uncomment these lines to fill plot area of this axis with some color
          valueAxis2.renderer.gridContainer.background.fillOpacity = 0.05;

          // uncomment these lines to fill plot area of this axis with some color
          valueAxis2.renderer.gridContainer.background.fillOpacity = 0.05;
          if (Seq_id != 0) {
            valueAxis2.marginTop = 33;

            var rectangle = chart.plotContainer.createChild(am4core.Rectangle);
            rectangle.fillOpacity = 1;
            rectangle.width = am4core.percent(100);
            rectangle.fill = am4core.color("white");
            rectangle.isMeasured = false;
            rectangle.height = 30;
            rectangle.zIndex = 1000;
            valueAxis2?.events.on("positionchanged", function () {
              rectangle.y = valueAxis2.pixelY - rectangle.pixelHeight - 1;
            });
          }
          let device_color_count_temp = 0;
          for (const key in temp_data[0]?.CH4[0]) {
            var series2 = chart.series.push(new am4charts.LineSeries());
          if (props?.chosenDayDateRange.length > 0) {
            let test: any = new Date(props?.chosenDayDateRange[1]);
            let test1: any = new Date(props?.chosenDayDateRange[0]);
            let check_diff_max_7: any = test - test1;
            if (check_diff_max_7 / (1000 * 60 * 60 * 24) > 7) {
              series2.connect = true;
            }
          } else if (showSelectedFilterText == "Last 7 days data") {
            series2.connect = true;
          }

         
          
          series2.dataFields.dateX = "date";
          series2.dataFields.valueY = key;
          series2.name = key;

          if (temp_data?.length > 60) {
            series2.minBulletDistance = 30;
          } else {
            series2.minBulletDistance = 3;
          }
          series2.strokeWidth = 2;
          series2.yAxis = valueAxis2;
          series2.stroke = am4core.color(color_device[device_color_count_temp]);
          series2.tooltip.getFillFromObject = false;
          series2.tooltip.background.fill = am4core.color("#ffffff");

          series2?.adapter?.add("tooltipHTML", function (text: any, target: any) {
            var data = target.tooltipDataItem.dataContext;

            if (data.isCurrentAnomaly) {
              series2.tooltip.background.stroke = am4core.color("red");
              series2.tooltip.background.strokeWidth = 1;
            } else {
              series2.tooltip.background.stroke = am4core.color("grey");
              series2.tooltip.background.strokeWidth = 1;
            }

            return DynamicToolTip(
              data,
              `{CurPageRawTrendAnamolyType}`,
              `{tooltipDate}`,
              `{CH4}`,
              `{WindSpeed}`,
              `{TotalEmission}`,
              `{Direction}`,
              `{Temp}`,
              `{Pressure}`,
              `{TubingPressure}`,
              `{AnomalyType}`,
              1
            );
          });

          let bullet:any = series2?.bullets?.push(new am4charts.CircleBullet());
          if(bullet !=undefined){
            bullet.circle.strokeWidth = 0;
            bullet.circle.radius = 4;
            bullet.circle.fill = am4core.color(color_device[device_color_count_temp]);
          }
          device_color_count_temp++;

          }
          
          
          
        }
      }

      if (Seq_val.name == "total_emission") {
        if (checkFilterTotalEmission === true) {
          // Chart 2: Tempurature - motor
          var valueAxis2: any = chart.yAxes.push(new am4charts.ValueAxis());

          valueAxis2.title.text = "Total emission";
          valueAxis2.title.fontSize = "11px";
          valueAxis2.title.marginRight = "30px";
          valueAxis2.title.fontWeight = "700";
          valueAxis2.renderer.inside = false;
          valueAxis2.zIndex = 1;
          valueAxis2.renderer.baseGrid.disabled = true;

          // valueAxis.renderer.line.strokeOpacity = 0.6;
          // height of axis
          valueAxis2.height = am4core.percent(40);
          valueAxis2.renderer.labels.template.verticalCenter = "bottom";
          valueAxis2.renderer.labels.template.padding(8, 8, -4, 2);
          valueAxis2.renderer.fontSize = "0.8em";
          // uncomment these lines to fill plot area of this axis with some color
          valueAxis2.renderer.gridContainer.background.fillOpacity = 0.05;

          // uncomment these lines to fill plot area of this axis with some color
          valueAxis2.renderer.gridContainer.background.fillOpacity = 0.05;
          if (Seq_id != 0) {
            valueAxis2.marginTop = 33;

            var rectangle = chart.plotContainer.createChild(am4core.Rectangle);
            rectangle.fillOpacity = 1;
            rectangle.width = am4core.percent(100);
            rectangle.fill = am4core.color("white");
            rectangle.isMeasured = false;
            rectangle.height = 30;
            rectangle.zIndex = 1000;
            valueAxis2?.events.on("positionchanged", function () {
              rectangle.y = valueAxis2.pixelY - rectangle.pixelHeight - 1;
            });
          }

          var series2 = chart.series.push(new am4charts.LineSeries());
          if (props?.chosenDayDateRange.length > 0) {
            let test: any = new Date(props?.chosenDayDateRange[1]);
            let test1: any = new Date(props?.chosenDayDateRange[0]);
            let check_diff_max_7: any = test - test1;
            if (check_diff_max_7 / (1000 * 60 * 60 * 24) > 7) {
              series2.connect = true;
            }
          } else if (showSelectedFilterText == "Last 7 days data") {
            series2.connect = true;
          }
          series2.dataFields.dateX = "date";
          series2.dataFields.valueY = "TotalEmission";
          series2.name = "Total emission";


          // if (temp_data?.length > 60) {
          //   series2.minBulletDistance = 30;
          // } else {
          //   series2.minBulletDistance = 3;
          // }
          series2.strokeWidth = 2;
          series2.yAxis = valueAxis2;
          series2.stroke = am4core.color("#f9190c");
          series2.tooltip.getFillFromObject = false;
          series2.tooltip.background.fill = am4core.color("#ffffff");
          series2.adapter.add("tooltipHTML", function (text: any, target: any) {
            var data = target.tooltipDataItem.dataContext;
            if (data.isCurrentAnomaly) {
              series2.tooltip.background.stroke = am4core.color("red");
              series2.tooltip.background.strokeWidth = 1;
            } else {
              series2.tooltip.background.stroke = am4core.color("grey");
              series2.tooltip.background.strokeWidth = 1;
            }

            return DynamicToolTip(
              data,
              `{CurPageRawTrendAnamolyType}`,
              `{tooltipDate}`,
              `{CH4}`,
              `{WindSpeed}`,
              `{TotalEmission}`,
              `{Direction}`,
              `{Temp}`,
              `{Pressure}`,
              `{TubingPressure}`,
              `{AnomalyType}`,
              2
            );
          });

          let bullet2 = series2.bullets.push(new am4charts.CircleBullet());
          bullet2.circle.strokeWidth = 0;
          bullet2.circle.radius = 4;
          bullet2.circle.fill = am4core.color("#f9190c");
        }
      }
      if (Seq_val.name == "pressure") {
        if (checkFilterPressure === true) {
          // Chart 3: Pressure - intake
          var valueAxis3: any = chart.yAxes.push(new am4charts.ValueAxis());

          valueAxis3.title.text = "Pressure (psi)";
          valueAxis3.title.fontSize = "11px";
          valueAxis3.title.marginRight = "30px";
          valueAxis3.title.fontWeight = "700";

          // height of axis
          valueAxis3.height = am4core.percent(40);
          valueAxis3.zIndex = 3;
          // this makes gap between panels
          valueAxis3.marginTop = 33;
          // valueAxis3.renderer.line.strokeOpacity = 0.6;
          valueAxis3.renderer.baseGrid.disabled = true;
          valueAxis3.renderer.inside = false;
          valueAxis3.renderer.labels.template.verticalCenter = "bottom";
          valueAxis3.renderer.labels.template.padding(8, 8, -4, 2);
          valueAxis3.renderer.fontSize = "0.8em";

          // uncomment these lines to fill plot area of this axis with some color
          valueAxis3.renderer.gridContainer.background.fillOpacity = 0.05;

          var rectangle3 = chart.plotContainer.createChild(am4core.Rectangle);
          rectangle3.fillOpacity = 1;
          rectangle3.width = am4core.percent(100);
          rectangle3.fill = am4core.color("white");
          rectangle3.isMeasured = false;
          rectangle3.height = 30;
          rectangle3.zIndex = 1000;
          valueAxis3?.events.on("positionchanged", function () {
            rectangle3.y = valueAxis3.pixelY - rectangle3.pixelHeight - 1;
          });
          let device_color_count_temp = 0;
          for (const key in temp_data[0]?.Pressure[0]) {
            var series3 = chart.series.push(new am4charts.LineSeries());
            if (props?.chosenDayDateRange.length > 0) {
              let test: any = new Date(props?.chosenDayDateRange[1]);
              let test1: any = new Date(props?.chosenDayDateRange[0]);
              let check_diff_max_7: any = test - test1;
              if (check_diff_max_7 / (1000 * 60 * 60 * 24) > 7) {
                series3.connect = true;
              }
            } else if (showSelectedFilterText == "Last 7 days data") {
              series3.connect = true;
            }
          
            series3.dataFields.dateX = "date";
            series3.dataFields.valueY = key;
            series3.name = key;
            series3.minBulletDistance = 30;
            series3.strokeWidth = 2;
            series3.yAxis = valueAxis3;
            series3.stroke = am4core.color(color_device[device_color_count_temp]);
            series3.tooltip.getFillFromObject = false;
            series3.tooltip.background.fill = am4core.color("#ffffff");
            // series3.tooltipHTML = tooltipHTML;
            series3.adapter.add("tooltipHTML", function (text: any, target: any) {
              var data = target.tooltipDataItem.dataContext;
              if (data.isCurrentAnomaly) {
                series3.tooltip.background.stroke = am4core.color("red");
                series3.tooltip.background.strokeWidth = 1;
              } else {
                series3.tooltip.background.stroke = am4core.color("grey");
                series3.tooltip.background.strokeWidth = 1;
              }
  
              return DynamicToolTip(
                data,
                `{CurPageRawTrendAnamolyType}`,
                `{tooltipDate}`,
                `{CH4}`,
                `{WindSpeed}`,
                `{TotalEmission}`,
                `{Direction}`,
                `{Temp}`,
                `{Pressure}`,
                `{TubingPressure}`,
                `{AnomalyType}`,
                3
              );
            });
            let bullet3 = series3.bullets.push(new am4charts.CircleBullet());
            if(bullet3 != undefined){
              bullet3.circle.strokeWidth = 0;
            bullet3.circle.radius = 4;
            bullet3.circle.fill = am4core.color(color_device[device_color_count_temp]);
            device_color_count_temp++
            }
            
        }
          
        }
      }
      if (Seq_val.name == "temp") {
        if (checkFilterTemp === true) {
          // Chart 4: Tempurature - intake
          var valueAxis4: any = chart.yAxes.push(new am4charts.ValueAxis());

          valueAxis4.title.text = "Temperature (\u00B0F)";
          valueAxis4.title.fontSize = "11px";
          valueAxis4.title.marginRight = "30px";
          valueAxis4.title.fontWeight = "700";

          // height of axis
          valueAxis4.height = am4core.percent(40);
          valueAxis4.zIndex = 3;
          // this makes gap between panels
          valueAxis4.marginTop = 33;
          // valueAxis4.renderer.line.strokeOpacity = 0.6;
          valueAxis4.renderer.baseGrid.disabled = true;
          valueAxis4.renderer.inside = false;
          valueAxis4.renderer.labels.template.verticalCenter = "bottom";
          valueAxis4.renderer.labels.template.padding(8, 8, -4, 2);
          valueAxis4.renderer.fontSize = "0.8em";

          // uncomment these lines to fill plot area of this axis with some color
          valueAxis4.renderer.gridContainer.background.fillOpacity = 0.05;
          var rectangle4 = chart.plotContainer.createChild(am4core.Rectangle);
          rectangle4.fillOpacity = 1;
          rectangle4.width = am4core.percent(100);
          rectangle4.fill = am4core.color("white");
          rectangle4.isMeasured = false;
          rectangle4.height = 30;
          rectangle4.zIndex = 1000;
          valueAxis4?.events.on("positionchanged", function () {
            rectangle4.y = valueAxis4.pixelY - rectangle4.pixelHeight - 1;
          });
          let temp_count = 0;
          for (const key in temp_data[0]?.Temp[0]) {
          var series4 = chart.series.push(new am4charts.LineSeries());
          if (props?.chosenDayDateRange.length > 0) {
            let test: any = new Date(props?.chosenDayDateRange[1]);
            let test1: any = new Date(props?.chosenDayDateRange[0]);
            let check_diff_max_7: any = test - test1;
            if (check_diff_max_7 / (1000 * 60 * 60 * 24) > 7) {
              series4.connect = true;
            }
          } else if (showSelectedFilterText == "Last 7 days data") {
            series4.connect = true;
          }
          series4.dataFields.dateX = "date";
          series4.dataFields.valueY = key;
          series4.name = key;
          series4.minBulletDistance = 30;
          series4.strokeWidth = 2;
          series4.yAxis = valueAxis4;
          series4.stroke = am4core.color(color_device[temp_count]);
          series4.tooltip.getFillFromObject = false;
          series4.tooltip.background.fill = am4core.color("#ffffff");
          series4.adapter.add("tooltipHTML", function (text: any, target: any) {
            var data = target.tooltipDataItem.dataContext;
            if (data.isCurrentAnomaly) {
              series4.tooltip.background.stroke = am4core.color("red");
              series4.tooltip.background.strokeWidth = 1;
            } else {
              series4.tooltip.background.stroke = am4core.color("grey");
              series4.tooltip.background.strokeWidth = 1;
            }

            return DynamicToolTip(
              data,
              `{CurPageRawTrendAnamolyType}`,
              `{tooltipDate}`,
              `{CH4}`,
              `{WindSpeed}`,
              `{TotalEmission}`,
              `{Direction}`,
              `{Temp}`,
              `{Pressure}`,
              `{TubingPressure}`,
              `{AnomalyType}`,
              4
            );
          });
          series4.tooltip.pointerOrientation = "horizontal";

          let bullet4 = series4.bullets.push(new am4charts.CircleBullet());
          bullet4.circle.strokeWidth = 0;
          bullet4.circle.radius = 4;
          bullet4.circle.fill = am4core.color(color_device[temp_count]);
          temp_count++

        }
        }
      }
      if (Seq_val.name == "wind_speed") {
        if (checkFilterWindSpeed === true) {
          // Chart 5: Current - motor
          var valueAxis5: any = chart.yAxes.push(new am4charts.ValueAxis());

          valueAxis5.title.text = "Wind speed";
          valueAxis5.title.fontSize = "11px";
          valueAxis5.title.marginRight = "35px";
          valueAxis5.title.fontWeight = "700";

          // height of axis
          valueAxis5.height = am4core.percent(40);
          valueAxis5.zIndex = 3;
          // this makes gap between panels
          valueAxis5.marginTop = 33;
          // valueAxis5.renderer.line.strokeOpacity = 0.6;
          valueAxis5.renderer.baseGrid.disabled = true;
          valueAxis5.renderer.inside = false;
          valueAxis5.renderer.labels.template.verticalCenter = "bottom";
          valueAxis5.renderer.labels.template.padding(8, 8, -4, 2);
          valueAxis5.renderer.fontSize = "0.8em";

          // uncomment these lines to fill plot area of this axis with some color
          valueAxis5.renderer.gridContainer.background.fillOpacity = 0.05;

          var rectangle5 = chart.plotContainer.createChild(am4core.Rectangle);
          rectangle5.fillOpacity = 1;
          rectangle5.width = am4core.percent(100);
          rectangle5.fill = am4core.color("white");
          rectangle5.isMeasured = false;
          rectangle5.height = 30;
          rectangle5.zIndex = 1000;
          valueAxis5?.events.on("positionchanged", function () {
            rectangle5.y = valueAxis5.pixelY - rectangle5.pixelHeight - 1;
          });
          var series5 = chart.series.push(new am4charts.LineSeries());
          if (props?.chosenDayDateRange.length > 0) {
            let test: any = new Date(props?.chosenDayDateRange[1]);
            let test1: any = new Date(props?.chosenDayDateRange[0]);
            let check_diff_max_7: any = test - test1;
            if (check_diff_max_7 / (1000 * 60 * 60 * 24) > 7) {
              series5.connect = true;
            }
          } else if (showSelectedFilterText == "Last 7 days data") {
            series5.connect = true;
          }
          series5.dataFields.dateX = "date";
          series5.dataFields.valueY = "WSP";
          series5.name = "WSP";
          series5.minBulletDistance = 30;
          series5.strokeWidth = 2;
          series5.yAxis = valueAxis5;
          series5.stroke = am4core.color("#9900cc");
          series5.tooltip.getFillFromObject = false;
          series5.tooltip.background.fill = am4core.color("#ffffff");
          // series5.tooltipHTML = tooltipHTML;
          series5.adapter.add("tooltipHTML", function (text: any, target: any) {
            var data = target.tooltipDataItem.dataContext;
            if (data.isCurrentAnomaly) {
              series5.tooltip.background.stroke = am4core.color("red");
              series5.tooltip.background.strokeWidth = 1;
            } else {
              series5.tooltip.background.stroke = am4core.color("grey");
              series5.tooltip.background.strokeWidth = 1;
            }

            return DynamicToolTip(
              data,
              `{CurPageRawTrendAnamolyType}`,
              `{tooltipDate}`,
              `{CH4}`,
              `{WindSpeed}`,
              `{TotalEmission}`,
              `{Direction}`,
              `{Temp}`,
              `{Pressure}`,
              `{TubingPressure}`,
              `{AnomalyType}`,
              5
            );
          });
          var bullet5 = series5.bullets.push(new am4charts.CircleBullet());
          bullet5.circle.strokeWidth = 0;
          bullet5.circle.radius = 4;
          bullet5.circle.fill = am4core.color("#9900cc");

         
        }
      }
      if (Seq_val.name == "direction") {
        if (checkFilterDirection === true) {
          // Chart 6: Casing pressure
          var valueAxis6: any = chart.yAxes.push(new am4charts.ValueAxis());

          valueAxis6.title.text = "Direction";
          valueAxis6.title.fontSize = "11px";
          valueAxis6.title.marginRight = "35px";
          valueAxis6.title.fontWeight = "700";

          // height of axis
          valueAxis6.height = am4core.percent(40);
          valueAxis6.zIndex = 3;
          // this makes gap between panels
          valueAxis6.marginTop = 33;
          // valueAxis6.renderer.line.strokeOpacity = 0.6;
          valueAxis6.renderer.baseGrid.disabled = true;
          valueAxis6.renderer.inside = false;
          valueAxis6.renderer.labels.template.verticalCenter = "bottom";
          valueAxis6.renderer.labels.template.padding(8, 8, -4, 2);
          valueAxis6.renderer.fontSize = "0.8em";

          // uncomment these lines to fill plot area of this axis with some color
          valueAxis6.renderer.gridContainer.background.fillOpacity = 0.05;

          let rectangle6 = chart.plotContainer.createChild(am4core.Rectangle);
          rectangle6.fillOpacity = 1;
          rectangle6.width = am4core.percent(100);
          rectangle6.fill = am4core.color("white");
          rectangle6.isMeasured = false;
          rectangle6.height = 30;
          rectangle6.zIndex = 1000;
          valueAxis6?.events.on("positionchanged", function () {
            rectangle6.y = valueAxis6.pixelY - rectangle6.pixelHeight - 1;
          });
           // copy start
         
           var series5 = chart.series.push(new am4charts.LineSeries());
           if (props?.chosenDayDateRange.length > 0) {
             let test: any = new Date(props?.chosenDayDateRange[1]);
             let test1: any = new Date(props?.chosenDayDateRange[0]);
             let check_diff_max_7: any = test - test1;
             if (check_diff_max_7 / (1000 * 60 * 60 * 24) > 7) {
               series5.connect = true;
             }
           } else if (showSelectedFilterText == "Last 7 days data") {
             series5.connect = true;
           }
           series5.dataFields.dateX = "date";
           series5.dataFields.valueY = "WDR";
           series5.name = "WDR";
           series5.minBulletDistance = 30;
           series5.strokeWidth = 2;
           series5.yAxis = valueAxis6;
           series5.stroke = am4core.color("#0099cc");
           series5.tooltip.getFillFromObject = false;
           series5.tooltip.background.fill = am4core.color("#ffffff");
           // series5.tooltipHTML = tooltipHTML;
           series5.adapter.add("tooltipHTML", function (text: any, target: any) {
             var data = target.tooltipDataItem.dataContext;
             if (data.isCurrentAnomaly) {
               series5.tooltip.background.stroke = am4core.color("red");
               series5.tooltip.background.strokeWidth = 1;
             } else {
               series5.tooltip.background.stroke = am4core.color("grey");
               series5.tooltip.background.strokeWidth = 1;
             }
 
             return DynamicToolTip(
               data,
               `{CurPageRawTrendAnamolyType}`,
               `{tooltipDate}`,
               `{CH4}`,
               `{WindSpeed}`,
               `{TotalEmission}`,
               `{Direction}`,
               `{Temp}`,
               `{Pressure}`,
               `{TubingPressure}`,
               `{AnomalyType}`,
               5
             );
           });
           var bullet5:any = series5.bullets.push(new am4charts.CircleBullet());
           bullet5.circle.strokeWidth = 0;
           bullet5.circle.radius = 4;
           bullet5.circle.fill = am4core.color("#0099cc");
           // copy end
        }
      }
      if (Seq_val.name == "tubing_pressure") {
        if (checkFilterTubingPress === true) {
          // Chart 7: Tubing pressure
          var valueAxis7: any = chart.yAxes.push(new am4charts.ValueAxis());

          valueAxis7.title.text = "Tubing pressure (psi)";
          valueAxis7.title.fontSize = "11px";
          valueAxis7.title.marginRight = "35px";
          valueAxis7.title.fontWeight = "700";

          // height of axis
          valueAxis7.height = am4core.percent(40);
          valueAxis7.zIndex = 3;
          // this makes gap between panels
          valueAxis7.marginTop = 33;
          // valueAxis7.renderer.line.strokeOpacity = 0.6;
          valueAxis7.renderer.baseGrid.disabled = true;
          valueAxis7.renderer.inside = false;
          valueAxis7.renderer.labels.template.verticalCenter = "bottom";
          valueAxis7.renderer.labels.template.padding(8, 8, -4, 2);
          valueAxis7.renderer.fontSize = "0.8em";

          // uncomment these lines to fill plot area of this axis with some color
          valueAxis7.renderer.gridContainer.background.fillOpacity = 0.05;

          let rectangle7 = chart.plotContainer.createChild(am4core.Rectangle);
          rectangle7.fillOpacity = 1;
          rectangle7.width = am4core.percent(100);
          rectangle7.fill = am4core.color("white");
          rectangle7.isMeasured = false;
          rectangle7.height = 30;
          rectangle7.zIndex = 1000;
          valueAxis7?.events.on("positionchanged", function () {
            rectangle7.y = valueAxis7.pixelY - rectangle7.pixelHeight - 1;
          });
          var series7 = chart.series.push(new am4charts.LineSeries());
          if (props?.chosenDayDateRange.length > 0) {
            let test: any = new Date(props?.chosenDayDateRange[1]);
            let test1: any = new Date(props?.chosenDayDateRange[0]);
            let check_diff_max_7: any = test - test1;
            if (check_diff_max_7 / (1000 * 60 * 60 * 24) > 7) {
              series7.connect = true;
            }
          } else if (showSelectedFilterText == "Last 7 days data") {
            series7.connect = true;
          }
          series7.dataFields.dateX = "date";
          series7.dataFields.valueY = "TubingPressure";
          series7.name = "Tubing Pressure";
          series7.minBulletDistance = 30;
          series7.strokeWidth = 2;

          series7.yAxis = valueAxis7;
          series7.stroke = am4core.color("#16d3ea");
          series7.tooltip.getFillFromObject = false;
          series7.tooltip.background.fill = am4core.color("#ffffff");
          series7.adapter.add("tooltipHTML", function (text: any, target: any) {
            var data = target.tooltipDataItem.dataContext;
            if (data.isCurrentAnomaly) {
              series7.tooltip.background.stroke = am4core.color("red");
              series7.tooltip.background.strokeWidth = 1;
            } else {
              series7.tooltip.background.stroke = am4core.color("grey");
              series7.tooltip.background.strokeWidth = 1;
            }

            return DynamicToolTip(
              data,
              `{CurPageRawTrendAnamolyType}`,
              `{tooltipDate}`,
              `{CH4}`,
              `{WindSpeed}`,
              `{TotalEmission}`,
              `{Direction}`,
              `{Temp}`,
              `{Pressure}`,
              `{TubingPressure}`,
              `{AnomalyType}`,
              7
            );
          });
          // series7.tooltipHTML = tooltipHTML;

          let bullet7 = series7.bullets.push(new am4charts.CircleBullet());
          bullet7.circle.strokeWidth = 0;
          bullet7.circle.radius = 4;

          bullet7.circle.fill = am4core.color("#16d3ea");
        }
      }
    });

    chart.cursor = new am4charts.XYCursor();
    chart.cursor.xAxis = dateAxis;

    // start test range
    let array_event: any = [];

    if (props.viewPastAnamolyState != false) {
      temp_array.map((val: any, i: any) => {
        if (val?.anomaly == "0") {
        } else if (val?.anomaly == "-1") {
        } else if (val.anomaly == "1") {
          array_event[i] = dateAxis.axisRanges.create();
          array_event[i].date = new Date(
            Helpers.convertFullDateToReadableFormat(temp_array[i]?.startDate)
          );
          array_event[i].endDate = new Date(
            Helpers.convertFullDateToReadableFormat(temp_array[i]?.endDate)
          );
          // if(temp_array.length == i+1){
          //     array_event[i].endDate = new Date(Helpers.convertFullDateToReadableFormat(temp_array[i]?.endDate));
          // }else{
          //     array_event[i].endDate = new Date(Helpers.convertFullDateToReadableFormat(temp_array[i+1]?.endDate));
          // }

          array_event[i].grid.disabled = true;
          array_event[i].grid.disabled = true;
          array_event[i].axisFill.fillOpacity = 0.1;
        }
      });
    }

    // end test range

    // var event1 = dateAxis.axisRanges.create();
    // event1.date = new Date(Helpers.convertFullDateToReadableFormat(props.currentAnamolyStartDate));

    let sDate = new Date(
      Helpers.convertFullDateToReadableFormat(props.currentAnamolyStartDate)
    );
    let eDate: any = "";

    if (props.currentAnamolyEndDate == "null") {
      // event1.endDate = new Date();
      eDate = new Date();
    } else {
      eDate = new Date(
        Helpers.convertFullDateToReadableFormat(props.currentAnamolyEndDate)
      );

      // event1.endDate = new Date(Helpers.convertFullDateToReadableFormat(props.currentAnamolyEndDate));
    }
    // event1.grid.disabled = true;
    // event1.axisFill.fill = am4core.color("red");
    // event1.axisFill.fillOpacity = 0.1;

    // show red anomly current start
    let test_event: any = [];

    if (props.viewCurrentAnamolyState != false) {
      if (props?.ESPRawTrendData?.length > 0) {
        props?.ESPRawTrendData.forEach((val: any, i: any) => {
          let val_date = new Date(
            Helpers.convertFullDateToReadableFormat(val.Time)
          );
          if (val_date >= sDate && val_date <= eDate) {
            // test start
            
            if (val?.Anomaly == "0") {
            } else if (val?.Anomaly == "-1") {
            } else if (val.Anomaly == "1") {
              test_event[i] = dateAxis.axisRanges.create();
              test_event[i].date = new Date(
                Helpers.convertFullDateToReadableFormat(val.Time)
              );
              test_event[i].endDate = new Date(
                Helpers.convertFullDateToReadableFormat(
                  props.ESPRawTrendData[i + 1]?.Time
                )
              );
              test_event[i].grid.disabled = true;
              test_event[i].axisFill.fill = am4core.color("red");
              test_event[i].axisFill.fillOpacity = 0.1;
              // ESPAIvsHeathIndexData
            }
          }
        });
      }
    }

    // show red anomly current end

    var scrollbarX = new am4charts.XYChartScrollbar();
    scrollbarX.bbox.height = 0;
    scrollbarX.marginBottom = 40;
    scrollbarX.minHeight = 15;
    // chart.scrollbarX = scrollbarX;

    // let rectangle1 = chart.plotContainer.createChild(am4core.Rectangle)
    // rectangle1.fillOpacity = 1;
    // rectangle1.width = am4core.percent(100);
    // rectangle1.fill = am4core.color("white")
    // rectangle1.isMeasured = false;
    // rectangle1.height = 0;
    // rectangle1.zIndex = 1000;

    /* Create a cursor */
    chart.cursor = new am4charts.XYCursor();
    chart.cursor.maxTooltipDistance = -1;

    chart.legend = new am4charts.Legend();
    chart.events.on("ready", function (event: any) {
      if (props.zoomDateRange.length > 0) {
        dateAxis.zoomToDates(
          new Date(
            Helpers.convertFullDateToReadableFormat(props.zoomDateRange[0])
          ),
          new Date(
            Helpers.convertFullDateToReadableFormat(props.zoomDateRange[1])
          )
        );
      }

      dateAxis?.events.on("startendchanged", dateAxisChanged);

      function dateAxisChanged(ev: any) {
        var start = ev.target.minZoomed;
        var end = ev.target.maxZoomed;

        let startTime: any = new Date(
          Helpers.convertFullDateToReadableFormat(start)
        );
        let startTime1: any = new Date(props.zoomDateRange[0]);

        let endTime: any = new Date(
          Helpers.convertFullDateToReadableFormat(end)
        );
        let endTime1: any = new Date(props.zoomDateRange[1]);

        let diff_start = (startTime1 - startTime) / 1000;
        let diff_end = (endTime1 - endTime) / 1000;
        // console.log(diff_start);
        //

        if (
          diff_start < -120 ||
          diff_start > 120 ||
          diff_end < -120 ||
          diff_end > 120 ||
          isNaN(diff_start)
        ) {
          // props.setzoomDateRange([Helpers.convertFullDateToReadableFormat(start), Helpers.convertFullDateToReadableFormat(end)]);
        }
      }

      var color = "red";
      var percent = Math.round(30 * 100) / 100;
      var value = 30;
    });

    if (props?.chosenDay == "A") {
      setshowSelectedFilterText(`Last 6 hours data`);
    } else if (props?.chosenDay == "M") {
      setshowSelectedFilterText(`Last 12 hours data`);
    } else if (props?.chosenDay == "P") {
      setshowSelectedFilterText(`Last 24 hours data`);
    } else if (props?.chosenDay == "R") {
      setshowSelectedFilterText(`Last 7 days data`);
    } else if (props?.chosenDayDateRange != "") {
      setshowSelectedFilterText(
        `Data from ${Helpers.convertFullDateToReadableFormatForFilterRange(
          props?.chosenDayDateRange[0]
        )} to ${Helpers.convertFullDateToReadableFormatForFilterRange(
          props?.chosenDayDateRange[1]
        )}`
      );
    }


    return () => {
      chart.dispose();
    };


  }, [
    props.ESPCallHistoryData,
    // props.zoomDateRange,
    Sequence,
    props.CurPageRawTrendAnamolyType,
    props.chosenDayDateRange,
    props.ESPRawTrendData,
    props.viewCurrentAnamolyState,
    props.viewPastAnamolyState,
    props.currentAnamolyEndDate,
    props.currentAnamolyStartDate,
    checkFilterCH4,
    checkFilterTotalEmission,
    checkFilterPressure,
    checkFilterTemp,
    checkFilterWindSpeed,
    checkFilterDirection,
    checkFilterTubingPress,
  ]);

  return (
    <>
      <div
        style={{
          border: "1px solid #c4c4c4",
          borderRadius: "10px",
          marginTop: "20px",
        }}
      >
        <div className="row" style={{ marginTop: "12px" }}>
          <div
            className="col-sm-8 col-md-8 col-lg-8"
            style={{ marginLeft: "15px" }}
          >
            <span style={{ color: "black", fontWeight: 700, fontSize: "19px" }}>
              {t("AnamolyDetectionAnalyze.RawTrendHistory")}
            </span>
            <p style={{ fontSize: "14px" }}>{"Compare trends over time"}</p>
          </div>
          <div
            className="col-sm-3 col-md-3 col-lg-3"
            style={{ marginTop: "7px", marginLeft: "6%" }}
          >
            <SelectNormalFilterRawTrendHistory
              setSequence={setSequence}
              viewPastAnamolyState={props.viewPastAnamolyState}
              setviewPastAnamolyState={props.setviewPastAnamolyState}
              viewCurrentAnamolyState={props.viewCurrentAnamolyState}
              setviewCurrentAnamolyState={props.setviewCurrentAnamolyState}
              flist={selectFilterList}
              setcheckFilterCH4={setcheckFilterCH4}
              setcheckFilterTotalEmission={setcheckFilterTotalEmission}
              setcheckFilterTemp={setcheckFilterTemp}
              setcheckFilterPressure={setcheckFilterPressure}
              setcheckFilterWindSpeed={setcheckFilterWindSpeed}
              setcheckFilterDirection={setcheckFilterDirection}
              setcheckFilterTubingPress={setcheckFilterTubingPress}
            />
            <SelectDaysFilter
              setChosenDay={props?.setChosenDay}
              chosenDay={props?.chosenDay}
              setchosenDayDateRange={props.setchosenDayDateRange}
              chosenDayDateRange={props.chosenDayDateRange}
            />
          </div>
        </div>
        {/* <div className="card" style={{ border: "0.1em solid  rgb(196, 196, 196)", borderTopLeftRadius: "0", borderTopRightRadius: "0" }}> </div> */}
        <span
          style={{
            float: "right",
            marginRight: "1%",
            marginBottom: "20px",
          }}
        >
          {showSelectedFilterText}
        </span>

        {props?.ESPRawTrendData?.length == undefined ? (
          <div
            style={{
              padding: "24px",
              textAlign: "center",
              marginTop: "40px",
              marginBottom: "40px",
              color: "rgba(0,0,0,0.87)",
            }}
          >
            {t("No Records To Display")}
          </div>
        ) : (
          <>
            <div
              id="RawTrendHistorychartdiv"
              style={{
                borderTop: "1px solid rgb(196, 196, 196)",
                width: "100%",
                height: "1250px",
                marginBottom: "40px",
              }}
            ></div>
          </>
        )}

        <div></div>
      </div>
    </>
  );
};

export default RawTrendHistory;
