// React
import { useState } from "react";

// SCSS
import "./aitrend.scss";

// Components
import AiVsHealthIndex from "./aitrendcharts/ai-vs-health-index";
import RawTrendHistory from "./aitrendcharts/raw-trend-history";
import PreviousESPCallHistory from "./previous-esp-call-history";

// i18n
import { useTranslation } from "react-i18next";

const Aitrend = (props: any) => {
  window.document.title = "Soofie Insights";

  const { t } = useTranslation();

  const [viewCurrentAnamolyState, setviewCurrentAnamolyState] = useState(true);
  const [viewPastAnamolyState, setviewPastAnamolyState] = useState(false);

  return (
    <>
      <AiVsHealthIndex
        StartDateCurrentAnomaly={props.StartDateCurrentAnomaly}
        EndDateCurrentAnomaly={props.EndDateCurrentAnomaly}
        setzoomDateRange={props.setzoomDateRange}
        zoomDateRange={props.zoomDateRange}
        viewPastAnamolyState={viewPastAnamolyState}
        setviewPastAnamolyState={setviewPastAnamolyState}
        viewCurrentAnamolyState={viewCurrentAnamolyState}
        setviewCurrentAnamolyState={setviewCurrentAnamolyState}
        currentAnamolyStartDate={props.currentAnamolyStartDate}
        currentAnamolyEndDate={props.currentAnamolyEndDate}
        chosenDay={props.chosenDay}
        setChosenDay={props?.setChosenDay}
        ESPAIvsHeathIndexData={props.ESPAIvsHeathIndexData}
        ESPCallHistoryData={props.ESPCallHistoryData}
        setchosenDayDateRange={props.setchosenDayDateRange}
        chosenDayDateRange={props.chosenDayDateRange}
      />
      <RawTrendHistory
        CurPageRawTrendAnamolyType={props.CurPageRawTrendAnamolyType}
        ESPAIvsHeathIndexData={props.ESPAIvsHeathIndexData}
        setzoomDateRange={props.setzoomDateRange}
        zoomDateRange={props.zoomDateRange}
        viewPastAnamolyState={viewPastAnamolyState}
        setviewPastAnamolyState={setviewPastAnamolyState}
        viewCurrentAnamolyState={viewCurrentAnamolyState}
        setviewCurrentAnamolyState={setviewCurrentAnamolyState}
        currentAnamolyStartDate={props.currentAnamolyStartDate}
        currentAnamolyEndDate={props.currentAnamolyEndDate}
        chosenDay={props.chosenDay}
        setChosenDay={props?.setChosenDay}
        ESPRawTrendData={props.ESPRawTrendData}
        ESPCallHistoryData={props.ESPCallHistoryData}
        setchosenDayDateRange={props.setchosenDayDateRange}
        chosenDayDateRange={props.chosenDayDateRange}
      />
      <PreviousESPCallHistory
        chosenDay={props.chosenDay}
        setChosenDay={props?.setChosenDay}
        ESPCallHistoryData={props.ESPCallHistoryData}
        setchosenDayDateRange={props.setchosenDayDateRange}
        chosenDayDateRange={props.chosenDayDateRange}
      />
    </>
  );
};

export default Aitrend;
