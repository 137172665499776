// React
import React, { useState, useEffect, useRef } from "react";

// i18n
import { useTranslation } from "react-i18next";
// React icon

import { BsChevronDown } from "react-icons/bs";
// React Datatable
import DataTable, {
  ExpanderComponentProps,
  TableStyles,
} from "react-data-table-component";
import {
  TriStateCheckbox,
  TriStateCheckboxChangeEvent,
} from "primereact/tristatecheckbox";

// components
import FilterComponent from "./anamoly-detection-filter";
import AnamolyVerificationAddEditPopUp from "./anamoly-verification-add-edit-popup";
import LoadingScreen from "../common/loading";
import ExpandedComponent from "./Expandablerow";

// prime react
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";

// styles
import "./style.css";
import "./../../../assets/styles/esp-ai/anamoly-detection.scss";
// services
import CustomerService from "../../../common/services/customer/customer.service";
import AnomalyDetectionService from "../../services/anamolt-detection/anomaly-detection.services";

// redux store
import {
  addAnamolyDetectionStoreData,
  addOperatorStoreData,
} from "../../store/esp-ai/esp-anomaly-detection.store";
import { AppDispatch, AppState } from "../../store/esp-ai/store";
import { useDispatch, useSelector } from "react-redux";

// react router
import { useNavigate } from "react-router-dom";

// helper
import Helpers from "../../../common/utils/helpers";

// moment
import moment from "moment";

import {
  DataTable as PrimeDatatable,
  DataTableFilterMeta,
} from "primereact/datatable";
import { Column, ColumnFilterElementTemplateOptions } from "primereact/column";
import { FilterMatchMode, FilterOperator } from "primereact/api";

const AnamolyDetection = () => {
  window.document.title = "Soofie Insights";
  const [SortField, setSortField] = useState("order");
  const [SortFieldSeq, setSortFieldSeq] = useState<any>(-1);
  const { t } = useTranslation();

  const navigate = useNavigate();

  const toast = useRef<any>(null);

  const dispatch: AppDispatch = useDispatch();
  const OperatorData_Redux: any = useSelector(
    (state: AppState) => state.EspAnomalyDetectionStore.OperatorStoreData
  );
  const AnamolyDetectionData_Redux: any = useSelector(
    (state: AppState) =>
      state.EspAnomalyDetectionStore.AnamolyDetectionStoreData
  );

  const sortIcon = (
    <i className="pi pi-chevron-down" style={{ fontSize: "11px" }}></i>
  );

  const [visible, setVisible] = useState(false);
  const [popUpTitle, setPopUpTitle] = useState<string>("");
  const [showOperatorList, setshowOperatorList] = useState("");
  const [showAnamolyDetectionList, setshowAnamolyDetectionList] = useState("");
  const [filteredValues, setFilteredValues] = useState<any>([]);
  const [filterText, setFilterText] = React.useState("");
  const [operatorFilter, setOperatorFilter] = useState<any>("");
  const [securityFilter, setSecurityFilter] = useState<any>([]);
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);
  const [ValidateEditData, setValidateEditData] = React.useState("");
  const [isLoading, isLoadingUpdate] = useState(true);
  const [customToast, UpdateCustomToast] = useState<any>("");
  const [resetData, setResetData] = useState<any>(0);
  const [textSearchIcon, setTextSearchIcon] = useState<any>("");
  const [chosenDay, setChosenDay] = useState<any>("");
  const [chosenDayDateRange, setchosenDayDateRange] = useState<any>("");
  const [Hours, setHours] = useState<any>("");

  useEffect(() => {
    isLoadingUpdate(true);
    initFilters();
    if (OperatorData_Redux?.length == 0) {
      const fetchMyAPI = async () => {
        let res = await CustomerService.getCustomerList();

        dispatch(addOperatorStoreData(res as any));

        let temp_res = res.filter((val: any) => {
          if (val?.name == "All") {
            return false;
          } else {
            return true;
          }
        });

        setshowOperatorList(temp_res);
      };

      fetchMyAPI();
    } else {
      let temp_res = OperatorData_Redux.filter((val: any) => {
        if (val?.name == "All") {
          return false;
        } else {
          return true;
        }
      });

      setshowOperatorList(temp_res);
    }
    // Start calculating start date and end date days filter

    // '' = 24 hours
    // 'A' = 6 hours
    // 'M' = 12 hours
    // 'P' = 24 hours
    // 'R' = 7 days
    // End calculating start date and end date days filter

    let hours = 0;
    if (chosenDay == "A") {
      hours = 6;
    } else if (chosenDay == "M") {
      hours = 12;
    } else if (chosenDay == "P") {
      hours = 24;
    } else if (chosenDay == "R") {
      hours = 168;
    } else if (chosenDay == "T") {
      hours = 720;
    }

    let anomalyDetectionFilterStartDate: any = new Date();
    let anomalyDetectionFilterEndDate: any = new Date();

    let session = window.localStorage;
    let RangeDate = chosenDayDateRange;
    if (RangeDate[0] != null && RangeDate[1] != null) {
      let startD = new Date(chosenDayDateRange[0]);
      let EndD = new Date(chosenDayDateRange[1]);

      anomalyDetectionFilterStartDate = startD.toISOString();
      anomalyDetectionFilterEndDate = EndD.toISOString();
    } else {
      if (hours == 0) {
        let session_checkvalueISO: any = session.getItem(
          "summary_filter_dates"
        );

        let session_checkvalueISO_array: any =
          session_checkvalueISO?.split(",");
        if (session_checkvalueISO_array == undefined) {
          session_checkvalueISO_array = [0, 0];
        }
        if (
          session_checkvalueISO == "" ||
          session_checkvalueISO_array[0] == session_checkvalueISO_array[1]
        ) {
          anomalyDetectionFilterStartDate.setHours(
            anomalyDetectionFilterStartDate.getHours() - hours
          );
          anomalyDetectionFilterStartDate =
            anomalyDetectionFilterStartDate.toISOString();
          anomalyDetectionFilterEndDate =
            anomalyDetectionFilterEndDate.toISOString();
        } else {
          let startD = new Date(session_checkvalueISO_array[0]);
          let EndD = new Date(session_checkvalueISO_array[1]);

          anomalyDetectionFilterStartDate = startD.toISOString();
          anomalyDetectionFilterEndDate = EndD.toISOString();
        }
      } else {
        anomalyDetectionFilterStartDate.setHours(
          anomalyDetectionFilterStartDate.getHours() - hours
        );
        anomalyDetectionFilterStartDate =
          anomalyDetectionFilterStartDate.toISOString();
        anomalyDetectionFilterEndDate =
          anomalyDetectionFilterEndDate.toISOString();
      }
    }
    let session_checkvalues: any = session.getItem(
      "summary_filter_dates_readable"
    );
    let session_checkvalues_array: any = session_checkvalues?.split(",");
    if (session_checkvalues_array == undefined) {
      session_checkvalues_array = [0, 0];
    }
    if (
      AnamolyDetectionData_Redux?.length == 0 ||
      session_checkvalues_array[0] !=
        Helpers.convertOnlyDateToReadableFormat(
          anomalyDetectionFilterStartDate
        ) ||
      session_checkvalues_array[1] !=
        Helpers.convertOnlyDateToReadableFormat(
          anomalyDetectionFilterEndDate
        ) ||
      Hours != hours
    ) {
      AnomalyDetectionService.AnomalyDetectionGetWellSummaryService(
        anomalyDetectionFilterStartDate,
        anomalyDetectionFilterEndDate
      ).then((res: any) => {
       if(res == "" || res==undefined || res?.length == 0){
        isLoadingUpdate(false);
        return ;
       }
        // if(res == "" || res==undefined){
        //   res = [];
        //   isLoadingUpdate(false);
        // }
        let temp_res = JSON.parse(JSON.stringify(res));
        let res_without_null_values = temp_res?.filter((val: any) => {
          if (val?.firstOccurance == null) {
            return false;
          } else {
            return true;
          }
        });
        let testId = 1;
        res_without_null_values?.map((val: any, id: any) => {
          val.id = testId++;

          if (val?.reason == "") {
            val.reason = null;
          }
          if (val?.anomaly == "NORMAL") {
              val.order = 0;
            } else if(val?.anomaly =="ANOMALY"){
              val.order = 1;
            }else if (val?.anomaly == 2 || val?.anomaly == -1) {
            val.order = -1;
          } else if (
            val?.severity == "1" ||
            val?.severity == "low"
            /*  ||
            val.severity == "2" ||
            val?.severity == "medium" ||
            val?.severity == "3" ||
            val?.severity == "high" */
          ) {
            val.order = 1;
          } else if (val?.severity == null) {
            /* else if (val.severity == "2" || val?.severity == "medium") {
            val.order = 2;
          }  */
            /* else if (val?.severity == "3" || val?.severity == "high") {
            val.order = 3;
          }  */
            val.order = null;
          } else if (val?.severity == "0" || val?.severity == "normal") {
            val.order = 0;
          } else if (
            val?.severity == "-3" ||
            val?.severity == "-2" ||
            val?.severity == "-1"
          ) {
            val.order = -1;
          } else if (val?.severity == "-4") {
            val.order = -1;
          }

          if (val?.action == "NeedsVerification") {
            val.orderAction = val?.action;
          } else if (
            val?.action == "" ||
            val?.action == "Normal" ||
            val?.action == "Anomaly Predicted"
          ) {
            val.orderAction = "";
          }
        });

        session.setItem("summary_filter_dates", [
          anomalyDetectionFilterStartDate,
          anomalyDetectionFilterEndDate,
        ] as any);
        if (chosenDay != "") {
          session.setItem("summary_filter_days", chosenDay);
        }
        session.setItem("summary_filter_dates_readable", [
          Helpers.convertOnlyDateToReadableFormat(
            anomalyDetectionFilterStartDate
          ),
          Helpers.convertOnlyDateToReadableFormat(
            anomalyDetectionFilterEndDate
          ),
        ] as any);
        setHours(hours);
        dispatch(addAnamolyDetectionStoreData(res_without_null_values));
        setshowAnamolyDetectionList(res_without_null_values);
        setFilteredValues(res_without_null_values);
        isLoadingUpdate(false);
      });
    } else {
      setshowAnamolyDetectionList(AnamolyDetectionData_Redux);

      setFilteredValues(AnamolyDetectionData_Redux);

      isLoadingUpdate(false);
    }
  }, [AnamolyDetectionData_Redux, chosenDay, chosenDayDateRange]);

  useEffect(() => {
    if (customToast == "add") {
      toast?.current?.show({
        severity: "success",
        summary: "Success",
        detail: "Added Successfully",
        life: 10000,
      });
      UpdateCustomToast("");
    } else if (customToast == "update") {
      toast?.current?.show({
        severity: "success",
        summary: "Success",
        detail: "Updated Successfully",
        life: 10000,
      });
      UpdateCustomToast("");
    }
  }, [customToast]);

  useEffect(() => {
    if (operatorFilter != "" || securityFilter != "") {
      isLoadingUpdate(true);
      setTimeout(() => {
        isLoadingUpdate(false);
      }, 1000);
    }
    filteredItems();
  }, [
    textSearchIcon,
    operatorFilter,
    securityFilter,
    AnamolyDetectionData_Redux,
  ]);

  const Header = () => {
    return (
      <>
        <div
          style={{
            fontWeight: 700,
            fontSize: "19px",
            color: "black",
            paddingBottom: "20px",
          }}
        >
          {t("AnamolyDetectionAnalyze.AssetSummary")}
        </div>
        {/* <p style={{ fontSize: '13px', fontWeight: 400 }}>{t('Dashboard.WellSummarySubHeading')}</p> */}
        <div style={{ position: "absolute", right: "15px", top: "17px" }}>
          <Button
            className="dashboardDownloadIcon"
            style={{
              marginRight: "5px",
              backgroundColor: "#fff",
              border: "1px solid #ccc",
              fontWeight: "500",
              color: "#607D8B",
              height: "32px",
              fontSize: "12px",
              borderRadius: "7px",
            }}
            onClick={(e) => handleESPListDownload(e)}
          >
            <i
              className="pi pi-cloud-download"
              style={{ marginRight: "4px" }}
            ></i>
            &nbsp;{"Download"}
          </Button>

          <Button
            className="dashboardDownloadIcon"
            style={{
              fontWeight: "500",
              marginRight: "5px",
              backgroundColor: "#25476a",
              color: "#fff",
              height: "32px",
              fontSize: "12px",
              borderRadius: "7px",
            }}
            onClick={(e) => {
              setPopUpTitle("Add");
              setVisible(true);
            }}
          >
            <i
              className="pi pi-plus"
              style={{
                fontSize: "11px",
                marginRight: "4px",
              }}
            ></i>
            &nbsp;{"Create call"}
          </Button>

          {/* <Button label={`Create call`} style={{ height: "32px" }} icon="pi pi-plus" className="btn btn-primary" onClick={() => {
          setPopUpTitle("Add")
          setVisible(true)
        }} /> */}
        </div>
      </>
    );
  };
  const setValidateDataBeforePopup = (row: any) => {
    setValidateEditData(row);
  };
  const Icon = () => {
    return (
      <>
        <div
          style={{
            textAlign: "center",
            width: "30px",
            background: "#ddf2fc",
            margin: "0 auto",
            borderRadius: "25px",
          }}
        >
          <i
            className="pi pi-check-circle"
            style={{ color: "#1e97e9", fontSize: "20px" }}
          ></i>
        </div>
        <div>
          {popUpTitle == "Edit" ? (
            <>
              {t("PopupCall.Anamolyverification")}
              <p style={{ fontSize: "12px", fontWeight: 400 }}>
                {t("PopupCall.FillInfoVerifyAnamolyDetected")}
              </p>
            </>
          ) : (
            <>
              {t("PopupCall.CreateNewAnamolyCall")}
              <p style={{ fontSize: "12px", fontWeight: 400 }}>
                {t("PopupCall.FillInfoAboutAnamoly")}
              </p>
            </>
          )}
        </div>
      </>
    );
  };

  const handlePopBtn = () => {
    setPopUpTitle("Edit");
    setVisible(true);
  };
  const dataTableStyles: TableStyles = {
    table: {
      style: {
        // borderRight: "1px solid #ccc !important",
        // borderLeft: "1px solid #ccc !important",
        borderRadius: "8px",
        border: "1px solid #8b8b8b !important",
        display: "none",
      },
    },

    headCells: {
      style: {
        backgroundColor: "#f9fafb",
        borderTop: "1px solid rgba(0,0,0,0.07)",
        borderLeft: "0px solid rgba(0,0,0,0.07)",
        "&:last-of-type": {
          borderRight: "0px solid rgba(0,0,0,0.07)",
        },
        justifyContent: "space-between",
        fontWeight: "600",
        paddingRight: "5px",
        paddingBottom: "0px !important",
        // border: "1px solid #ccc !important"

        // width: "104% !important",
        // marginLeft: "-1%  !important"
      },
    },
    cells: {
      style: {
        borderLeft: "0px solid rgba(0,0,0,0.07)",
        "&:last-of-type": {
          borderRight: "0px solid rgba(0,0,0,0.07)",
          //borderBottom: "1px solid rgba(0,0,0,0.07)",
        },
        // border: "1px solid #ccc !important"
      },
    },
    pagination: {
      style: {
        justifyContent: "center",
        width: "90%",
      },
    },
  };
  const columns: any = [
    {
      // t('Dashboard.Table.AnamolyDate')
      name: t("Dashboard.Table.AnamolyStartDate"),
      selector: "firstOccurance",
      cell: (row: any) => {
        // oo
        return Helpers.convertFullDateToReadableFormat(row?.firstOccurance);
      },
      sortable: true,
      width: "175px",
    },
    {
      name: "",
      selector: (row: any) => {
        return row?.order;
      },
      sortable: true,
      width: "165px",
      omit: true,
    },
    {
      name: t("Dashboard.Table.AnamolyEndDate"),
      width: "165px",
      selector: (row: any) => {
        if (row?.lastOccurance == null) {
          return "";
        } else {
          return row?.lastOccurance;
        }
      },
      cell: (row: any) => {
        if (row?.lastOccurance != null) {
          return Helpers.convertFullDateToReadableFormat(row?.lastOccurance);
        } else {
          return t("AnamolyDetectionAnalyze.InProgress");
        }
      },
      sortable: true,
    },

    {
      name: t("Dashboard.Table.SecurityLevel"),
      selector: "order",
      cell: (row: any) => {
        if (row?.order == "0") {
          return (
            <>
              <span className="badge security_lvl_bge_lvl_normal">
                <span className="badge rounded p-1 security_lvl_bge_lvl_sub_normal">
                  {" "}
                </span>{" "}
                {t("Normal")}
              </span>
            </>
          );
        } else if (row?.order == -4) {
          return (
            <>
              <span className="badge security_lvl_bge_lvl_normal">
                <span className="badge rounded p-1 security_lvl_bge_lvl_sub_normal">
                  {" "}
                </span>{" "}
                {t("ShutDown")}
              </span>
            </>
          );
        } else if (row?.order == -1) {
          return (
            <>
              <span className="badge security_lvl_bge_lvl_nil">
                <span className="badge rounded p-1 security_lvl_bge_lvl_sub_nil">
                  {" "}
                </span>{" "}
                {t("Unknown")}
              </span>
            </>
          );
        } else if (row?.order == "1") {
          return (
            <>
              <span className="badge security_lvl_bge_lvl1">
                <span className="badge rounded p-1 bg-danger security_lvl_bge_lvl1_sub">
                  {" "}
                </span>{" "}
                {t("Anomaly")}
              </span>
            </>
          );
        } else if (row?.order == null) {
          /* else if (row.order == "2") {
          return (
            <>
              <span className="badge security_lvl_bge_lvl2">
                <span className="badge rounded p-1 security_lvl_bge_lvl2_sub">
                  {" "}
                </span>{" "}
                {t("Level")} 2
              </span>
            </>
          );
        } 
        else if (row?.order == "3") {
          return (
            <>
              <span className="badge security_lvl_bge_lvl3">
                <span className="badge rounded bg-danger p-1 security_lvl_bge_lvl3_sub">
                  {" "}
                </span>{" "}
                {t("Level")} 3
              </span>
            </>
          );
        }  */
          return (
            <>
              <span className="badge security_lvl_bge_lvl_nil">
                <span className="badge rounded p-1 security_lvl_bge_lvl_sub_nil">
                  {" "}
                </span>{" "}
                {t("Unknown")}
              </span>
            </>
          );
        }
      },
      sortable: true,
      width: "130px",
    },
    /*  {
      name: t("AnomalyType"),
      selector: (row: any) => {
        if (row?.anomalyType == "1") {
          return 1;
        } else if (row?.anomalyType == "2") {
          return 2;
        } else {
          return 0;
        }
      },
      cell: (row: any) => {
        if (row?.anomalyType == "2") {
          return <span className="gasSlugBadge">Gas Slug</span>;
        } else if (row?.anomalyType == "1") {
          return <span className="gasLockBadge">Gas lock</span>;
        } else if (
          row?.anomalyType == null ||
          row?.anomalyType == "" ||
          row?.anomalyType == "0"
        ) {
          if (row?.order == "0") {
            return <>N/A</>;
          } else if (row?.order == -1) {
            return <>N/A</>;
          } else if (row?.order == "1") {
            return <>-</>;
          } else if (row.order == "2") {
            return <>-</>;
          } else if (row?.order == "3") {
            return <>-</>;
          } else if (row?.order == null) {
            return <>N/A</>;
          }
        } else {
          return <>{row?.anomalyType}</>;
        }
      },
      sortable: true,
      width: "125px",
    }, */
    {
      name: t("Dashboard.Table.Operator"),
      cell: (row: any) => row?.customerName,
      selector: (row: any) => row?.customerName,
      sortable: true,
      width: "170px",
    },
    {
      name: t("AssetName"),
      selector: "assetName",
      cell: (row: any) => {
        return (
          <>
            <span title={row?.assetName}>{row?.assetName}</span>
          </>
        );
      },
      sortable: true,
    },
    {
      name: t("Dashboard.Table.VerificationStatus"),
      selector: (row: any) => {
        if (row?.action == "NeedsVerification") {
          return row?.action;
        } else if (
          row?.action == "" ||
          row?.action == "Normal" ||
          row?.action == "Anomaly Predicted"
        ) {
          return "";
        }
      },

      cell: (row: any) => {
        if (row?.action == "NeedsVerification") {
          return (
            <>
              <span className="badge p-1 needs_verification">
                {t("PopupCall.NeedVerification")}
              </span>
            </>
          );
        } else if (
          row?.action == "" ||
          row?.action == "Normal" ||
          row?.action == "Anomaly Predicted"
        ) {
          return (
            <>
              <span className="badge p-1 verified">
                <i
                  className="pi pi-check"
                  style={{ fontSize: "10px", fontWeight: "bolder" }}
                ></i>{" "}
                {t("Verified")}
              </span>
            </>
          );
        }
      },
      sortable: true,
      // width: "250px",
    },

    {
      name: "",
      // width: "200px",

      cell: (row: any) => (
        <>
          <div className="" style={{ marginLeft: "auto" }}>
            <Button
              className="AnalyzeBtnDashboard"
              label={`${t("Dashboard.Table.Analyze")}`}
              onClick={() => {
                navigate(
                  `/analyze-details/${row?.assetId}/${
                    row?.customerID
                  }/${encodeURIComponent(
                    row?.firstOccurance
                  )}/${encodeURIComponent(row?.lastOccurance)}/${
                    row?.customerName
                  }/${encodeURI(row?.assetName)}`
                );
              }}
              severity="secondary"
              outlined
            />
            &nbsp;&nbsp;
            <Button
              className="ValidateBtnDashboard"
              label={`${t("Dashboard.Table.Validate")}`}
              severity="secondary"
              style={{ color: "#105787", background: "#ddf2fc" }}
              onClick={(e) => {
                setValidateDataBeforePopup(row);
                handlePopBtn();
              }}
            />
            {/* {row?.lastOccurance == null && <Button disabled className='ValidateBtnDashboard' label={`${t('Dashboard.Table.Validate')}`} severity="secondary" style={{ color: "#105787", background: '#ddf2fc' }} />} */}
          </div>
        </>
      ),
    },
  ];

  const handleESPListDownload = (e: any) => {
    if (filteredValues?.length > 0) {
      // let testfilteredValues:any = filteredValues;
      var testfilteredValues: any = JSON.parse(JSON.stringify(filteredValues));
      testfilteredValues?.map((val: any, i: any) => {
        if (val?.firstOccurance != null) {
          val.finalfirstOccurance = moment(val?.firstOccurance)
            .local()
            .format("DD-MM-YYYY hh:mm A");
        } else {
          val.finalfirstOccurance = "";
        }
        if (val?.lastOccurance != null) {
          val.finallastOccurance = moment(val?.lastOccurance)
            .local()
            .format("DD-MM-YYYY hh:mm A");
        } else {
          val.finallastOccurance = t("AnamolyDetectionAnalyze.InProgress");
        }
        /*   if (val?.anomalyType == "1") {
          val.finalAnomalyType = "Gas slug";
        } else if (val?.anomalyType == "2") {
          val.finalAnomalyType = "Gas lock";
        } else if (
          val?.anomalyType == "Other" ||
          val?.anomalyType == "Others"
        ) {
          val.finalAnomalyType = "Other";
        } else {
          val.finalAnomalyType = "N/A";
        } */
        // check severity start
        if (val?.anomaly == "NORMAL") {
          val.finalSeverity = "Normal";
        } else if (val?.severity == -4 || val?.anomaly == -4) {
          val.finalSeverity = "ShutDown";
        } else if (val?.severity == "1" || val?.severity == "low" || val?.anomaly == "ANOMALY") {
          val.finalSeverity = "Anomaly";
        } else if (
          /*  else if (val.severity == "2" || val?.severity == "medium") {
          val.finalSeverity = "Level 2";
        } else if (val?.severity == "3" || val?.severity == "high") {
          val.finalSeverity = "Level 3";
        } */
          val?.severity == null ||
          val?.severity == -1 ||
          val?.severity == -2 ||
          val?.severity == -3
        ) {
          val.finalSeverity = "Unknown";
        } else if (val?.severity == "0" || val?.severity == "normal") {
          val.finalSeverity = "Normal";
        }
        // check severity end

        // check verification
        if (val?.action == "NeedsVerification") {
          val.finalVerification = "Not verified";
        } else if (
          val?.action == "Closed as Anomaly" ||
          val?.action == "Closed as Normal" ||
          val?.action == "Normal" ||
          val?.action == "Anomaly Predicted" ||
          val?.action == "Verified"
        ) {
          val.finalVerification = t("Verified");
        }
      });
      testfilteredValues.sort((a: any, b: any) => {
        return b.order - a.order;
      });
      Helpers.exportToCSV(
        testfilteredValues,
        "ESP_Anamoly_Detection",
        "ESP_Anamoly_Detection",
        {
          finalfirstOccurance: t("Dashboard.Table.AnamolyStartDate"),
          finallastOccurance: "Anamoly end date",
          finalSeverity: t("Dashboard.Table.SecurityLevel"),
          /* finalAnomalyType: t("Dashboard.Table.SecurityLevel"), */
          customerName: t("Dashboard.Table.Operator"),
          assetName: t("AssetName"),
          finalVerification: t("Dashboard.Table.VerificationStatus"),
        },
        [
          "finalfirstOccurance",
          "finallastOccurance",
          "finalSeverity",
          /* "finalAnomalyType", */
          "customerName",
          "assetName",
          "finalVerification",
        ]
      );
    }
  };

  const filteredItems = () => {
    let filteredAnamolyList: any =
      AnamolyDetectionData_Redux?.length > 0 ? AnamolyDetectionData_Redux : [];

    if (operatorFilter && operatorFilter.length > 0) {
      //
      filteredAnamolyList = filteredAnamolyList?.filter((opr: any) => {
        return (
          operatorFilter.find((statusItem: any) => {
            return (
              opr?.customerName?.toLowerCase() ==
              statusItem?.name?.toLowerCase()
            );
          }) != undefined
        );
      });
      //
    }
    //Filter section for security level
    if (securityFilter != "All" && securityFilter.length > 0) {
      filteredAnamolyList = filteredAnamolyList?.filter((opr: any) => {
        if (opr?.severity != null) {
          if (securityFilter.includes(opr?.severity)) {
            return securityFilter;
          }
        }
      });
    }

    if (filterText) {
      filteredAnamolyList = filteredAnamolyList.filter(
        (item: {
          firstOccurance: any;
          lastOccurance: { toString: () => string };
          severity: { toString: () => string };
          customerName: { toString: () => string };
          assetName: { toString: () => string };
        }) => {
          return item.assetName
            .toString()
            .toLowerCase()
            .includes(filterText.toLowerCase());
        }
      );

      //   return (Helpers.convertFullDateToReadableFormat(item.firstOccurance).toString().toLowerCase().includes(filterText.toLowerCase()) ||
      //     Helpers.convertFullDateToReadableFormat(item.lastOccurance).toString().toLowerCase().includes(filterText.toLowerCase()) ||
      //     item.assetName.toString().toLowerCase().includes(filterText.toLowerCase()) ||
      //     item.severity == filterText ||
      //     item?.customerName?.toString()?.toLowerCase().includes(filterText.toLowerCase()))
      // });
    }
    setFilteredValues(filteredAnamolyList);
  };

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };
    return (
      <FilterComponent
        onFilter={(e: any) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
        setPopUpTitle={setPopUpTitle}
        setVisible={setVisible}
        setOperatorFilter={setOperatorFilter}
        setSecurityFilter={setSecurityFilter}
        operators={showOperatorList}
        setTextSearchIcon={setTextSearchIcon}
        setFilterText={setFilterText}
        setChosenDay={setChosenDay}
        chosenDay={chosenDay}
        setchosenDayDateRange={setchosenDayDateRange}
        chosenDayDateRange={chosenDayDateRange}
        operatorFilter={operatorFilter}
      />
    );
  }, [
    filterText,
    resetPaginationToggle,
    operatorFilter,
    securityFilter,
    showOperatorList,
    textSearchIcon,
    chosenDay,
    chosenDayDateRange,
    SortField,
    SortFieldSeq,
  ]);

  function testBody(options: any) {
    return (
      <>
        <div className="flex align-items-center gap-2">
          <select
            onChange={(e) => {
              setSortField("firstOccurance");
              if (e.target.value == "oldToNew") {
                setSortFieldSeq(1);
              } else {
                setSortFieldSeq(-1);
              }
            }}
          >
            <option value="oldToNew">Old to new</option>
            <option value="newToOld">New to old</option>
          </select>
          {/* onChange={(e: TriStateCheckboxChangeEvent) =>{
            console.log(e.value);
          }
            
            // options.filterCallback(e.value)
          } */}
        </div>
      </>
    );
  }
  const [filters, setFilters] = useState<any>(null);
  const [globalFilterValue, setGlobalFilterValue] = useState<string>("");
  const initFilters = () => {
    setFilters({
      firstOccurance: {},
    });
    setGlobalFilterValue("");
  };

  return (
    <>
      <Toast ref={toast} />
      <LoadingScreen isLoading={isLoading} text="Loading..." />
      <section className="content content_background_color">
        <div className="content__header content__boxed overlapping">
          <div className="content__wrap pt-3 pb-4">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a>
                    <i className="pli-home"></i>
                  </a>
                </li>
                <li className="breadcrumb-item">
                  <a
                    style={{
                      fontWeight: "500",
                    }}
                  >
                    {t("BreadcrumAdvancedAnalytics")}
                  </a>
                </li>
                <li className="breadcrumb-item" aria-current="page">
                  <a
                    style={{
                      backgroundColor: "white",
                      padding: "3px",
                      color: "#25476a",
                      borderRadius: "6px",
                      paddingRight: "8px",
                      paddingLeft: "8px",
                      fontWeight: "500",
                    }}
                  >
                    Soofie anomaly detection
                  </a>
                </li>
              </ol>
            </nav>
            <p
              className="lead text-overflow"
              style={{ color: "white", fontSize: "25px" }}
            >
              {t("Dashboard.ESPAnamolyDetection")}
            </p>
            <div className="page-heading">
              <div className="row bg-primary" id="divCustFilter">
                <div className="col-lg-3 col-md-4 col-sm-12 col-xs-12">
                  <div className="row"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="content__boxed">
          <div className="content__wrap">
            <div className="card" style={{ padding: "18px" }}>
              <Header />
              <div>
                <div className="row" style={{ marginTop: "12px" }}>
                  <DataTable
                    className="dashboardTableBorder"
                    columns={columns}
                    data={filteredValues}
                    pagination
                    defaultSortFieldId={2}
                    defaultSortAsc={false}
                    subHeader
                    subHeaderComponent={subHeaderComponentMemo}
                    customStyles={dataTableStyles}
                    responsive={true}
                    striped={false}
                    highlightOnHover={false}
                    sortIcon={sortIcon}
                    // expandableRows
                    // expandableRowsComponent={ExpandedComponent}
                    // expandableRowsHideExpander={false}
                    // expandOnRowClicked={true}
                  />
                </div>

                {/* prime datatable start */}
                <div
                  className="card"
                  style={{ border: "1px solid rgb(196, 196, 196)" }}
                >
                  <PrimeDatatable
                    sortField={SortField}
                    sortOrder={SortFieldSeq}
                    value={filteredValues}
                    paginator
                    currentPageReportTemplate="{first} to {last} of {totalRecords}"
                    paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                    rows={10}
                    rowsPerPageOptions={[10, 20, 30, 40, 50]}
                  >
                    {/* Anomaly Start date */}
                    <Column
                      filterElement={testBody}
                      style={{ borderTopLeftRadius: "8px" }}
                      sortable
                      showFilterMatchModes={false}
                      field="firstOccurance"
                      body={(body_val) => {
                        return (
                          <>
                            {Helpers.convertFullDateToReadableFormat(
                              body_val?.firstOccurance
                            )}
                          </>
                        );
                      }}
                      header={`Start time`}
                    ></Column>

                    {/* Anomaly End date */}
                    <Column
                      sortable
                      field="lastOccurance"
                      body={(body_val) => {
                        if (body_val?.lastOccurance != null) {
                          return Helpers.convertFullDateToReadableFormat(
                            body_val?.lastOccurance
                          );
                        } else {
                          return t("AnamolyDetectionAnalyze.InProgress");
                        }
                      }}
                      header={`End time`}
                    ></Column>

                    {/* Hidden Order */}
                    <Column
                      hidden
                      sortable
                      field="order"
                      body={(body_val) => {
                        return <>{body_val?.order}</>;
                      }}
                      header={""}
                    ></Column>

                    {/* Severity Level */}

                    <Column
                      sortable
                      field="order"
                      body={(body_val) => {
                        if (body_val?.order == "0") {
                          return (
                            <>
                              <span className="badge security_lvl_bge_lvl_normal">
                                <span className="badge rounded p-1 security_lvl_bge_lvl_sub_normal">
                                  {" "}
                                </span>{" "}
                                {t("Normal")}
                              </span>
                            </>
                          );
                        } else if (body_val?.order == -4) {
                          return (
                            <>
                              <span className="badge security_lvl_bge_lvl_normal">
                                <span className="badge rounded p-1 security_lvl_bge_lvl_sub_normal">
                                  {" "}
                                </span>{" "}
                                {t("ShutDown")}
                              </span>
                            </>
                          );
                        } else if (body_val?.order == "1") {
                          return (
                            <>
                              <span className="badge security_lvl_bge_lvl1">
                                <span className="badge rounded p-1 bg-danger security_lvl_bge_lvl1_sub">
                                  {" "}
                                </span>{" "}
                                {t("Anomaly")}
                              </span>
                            </>
                          );
                        } else if (
                          /* else if (body_val.order == "2") {
                          return (
                            <>
                              <span className="badge security_lvl_bge_lvl2">
                                <span className="badge rounded p-1 security_lvl_bge_lvl2_sub">
                                  {" "}
                                </span>{" "}
                                {t("Level")} 2
                              </span>
                            </>
                          );
                        } else if (body_val?.order == "3") {
                          return (
                            <>
                              <span className="badge security_lvl_bge_lvl3">
                                <span className="badge rounded bg-danger p-1 security_lvl_bge_lvl3_sub">
                                  {" "}
                                </span>{" "}
                                {t("Level")} 3
                              </span>
                            </>
                          );
                        }  */
                          body_val?.order == null ||
                          body_val?.order == -1
                        ) {
                          return (
                            <>
                              <span className="badge security_lvl_bge_lvl_nil">
                                <span className="badge rounded p-1 security_lvl_bge_lvl_sub_nil">
                                  {" "}
                                </span>{" "}
                                {t("Unknown")}
                              </span>
                            </>
                          );
                        }
                      }}
                      header={"Severity"}
                    ></Column>

                    {/* Anomaly type */}

                    {/* <Column
                      sortable
                      field="anomalyType"
                      body={(body_val) => {
                        if (body_val?.anomalyType == "2") {
                          return (
                            <span
                              className="gasSlugBadge"
                              style={{ float: "none" }}
                            >
                              Gas Slug
                            </span>
                          );
                        } else if (body_val?.anomalyType == "1") {
                          return (
                            <span
                              className="gasLockBadge"
                              style={{ float: "none" }}
                            >
                              Gas lock
                            </span>
                          );
                        } else if (body_val?.anomalyType == -1) {
                          return <>N/A</>;
                        } else if (
                          body_val?.anomalyType == null ||
                          body_val?.anomalyType == "" ||
                          body_val?.anomalyType == "0"
                        ) {
                          if (body_val?.order == "0") {
                            return <>N/A</>;
                          } else if (body_val?.order == -1) {
                            return <>N/A</>;
                          } else if (body_val?.order == "1") {
                            return <>-</>;
                          } else if (body_val.order == "2") {
                            return <>-</>;
                          } else if (body_val?.order == "3") {
                            return <>-</>;
                          } else if (body_val?.order == null) {
                            return <>N/A</>;
                          }
                        } else {
                          return <>{body_val?.anomalyType}</>;
                        }
                      }}
                      header={"Type"}
                    ></Column> */}

                    {/* operator */}
                    <Column
                      sortable
                      field="customerName"
                      body={(body_val) => {
                        return <>{body_val?.customerName}</>;
                      }}
                      header={t("Dashboard.Table.Operator")}
                    ></Column>

                    {/*Asset Name */}
                    <Column
                      sortable
                      field="assetName"
                      body={(body_val) => {
                        return <>{body_val?.assetName}</>;
                      }}
                      header={t("AssetName")}
                    ></Column>

                    {/*Verification status */}
                    <Column
                      sortable
                      field="action"
                      body={(body_val) => {
                        if (body_val?.action == "NeedsVerification") {
                          return (
                            <>
                              <span
                                className="badge p-1 needs_verification"
                                style={{
                                  padding: "5px !important",
                                  paddingLeft: "8px !important",
                                  paddingRight: "8px !important",
                                }}
                              >
                                <i
                                  className="pi pi-times"
                                  style={{
                                    fontSize: "8px",
                                    fontWeight: "700",
                                    marginRight: "3px",
                                  }}
                                ></i>
                                {"Not verified"}
                              </span>
                            </>
                          );
                        } else if (
                          body_val?.action == "Closed as Anomaly" ||
                          body_val?.action == "Closed as Normal" ||
                          body_val?.action == "Normal" ||
                          body_val?.action == "Anomaly Predicted" ||
                          body_val?.action == "Verified"
                        ) {
                          return (
                            <>
                              <span className="badge p-1 verified">
                                <i
                                  className="pi pi-check"
                                  style={{
                                    fontSize: "10px",
                                    fontWeight: "bolder",
                                  }}
                                ></i>{" "}
                                {t("Verified")}
                              </span>
                            </>
                          );
                        } else {
                          return <>N/A</>;
                        }
                      }}
                      header={"Verification"}
                    ></Column>

                    {/* Actions */}
                    <Column
                      field=""
                      body={(body_val) => {
                        return (
                          <>
                            <div className="" style={{ float: "right" }}>
                              <Button
                                className="AnalyzeBtnDashboard"
                                label={`${t("Dashboard.Table.Analyze")}`}
                                onClick={() => {
                                  navigate(
                                    `/analyze-details/${body_val?.assetId}/${
                                      body_val?.customerID
                                    }/${encodeURIComponent(
                                      body_val?.firstOccurance
                                    )}/${encodeURIComponent(
                                      body_val?.lastOccurance
                                    )}/${body_val?.customerName}/${encodeURI(
                                      body_val?.assetName
                                    )}`
                                  );
                                }}
                                severity="secondary"
                                outlined
                              />
                              &nbsp;&nbsp;
                              <Button
                                className="ValidateBtnDashboard"
                                label={`${t("Dashboard.Table.Validate")}`}
                                severity="secondary"
                                style={{
                                  color: "#105787",
                                  background: "#ddf2fc",
                                }}
                                onClick={(e) => {
                                  setValidateDataBeforePopup(body_val);
                                  handlePopBtn();
                                }}
                              />
                              {/* {body_val?.lastOccurance == null && <Button disabled className='ValidateBtnDashboard' label={`${t('Dashboard.Table.Validate')}`} severity="secondary" style={{ color: "#105787", background: '#ddf2fc' }} />} */}
                            </div>
                          </>
                        );
                      }}
                      header={""}
                      style={{ borderTopRightRadius: "8px", width: "20%" }}
                    ></Column>
                  </PrimeDatatable>
                </div>
                {/* prime datatable end */}
                <div></div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Dialog
        header={<Icon />}
        visible={visible}
        style={{ width: "50vw" }}
        onHide={() => setVisible(false)}
      >
        <AnamolyVerificationAddEditPopUp
          data={ValidateEditData}
          title={popUpTitle}
          setVisible={setVisible}
          operatorList={showOperatorList}
          UpdateCustomToast={UpdateCustomToast}
          setResetData={setResetData}
        />
      </Dialog>
    </>
  );
};

export default AnamolyDetection;
