import { Button } from "primereact/button";
import { useTranslation } from "react-i18next";
import ModelMetricsStyles from '../esp-model-metrics-style.module.scss';


// {
//     "title": "Model",
//     "link": "/esp-modal-metrics",
//     "isexternal": false
//   }

const Header = () => {
    const { t } = useTranslation();
    return <>
        {/* Header start */}
        <div style={{ fontWeight: 700, fontSize: '20px', color: "black" }}> Overview</div>
        <p style={{ fontSize: '13px', fontWeight: 400 }}>{"Your current ESP model anomaly events and activity."}</p>
        <div style={{
            position: "absolute",
            right: "1%",

        }}>
            <Button className='DashboardFilterButton' severity="secondary" text style={{ fontWeight: "500", height: "30px", marginRight: "9px", borderRadius: "7px", backgroundColor: "white", color: "#607D8B", border: "1px solid #ccc" }}>
                <i className='pi pi-calendar-plus' />&nbsp;{t('AnamolyDetectionAnalyze.SelectDays')}
            </Button>

            <Button className='DashboardFilterButton' severity="secondary" text style={{ fontWeight: "500", height: "30px", marginRight: "9px", borderRadius: "7px", backgroundColor: "white", color: "#607D8B", border: "1px solid #ccc" }}>
                <i className='pi pi-cloud-download' />&nbsp;{'Export'}
            </Button>




        </div>

        {/* Header end*/}
    </>
}
const VisualisationsGraphs = () => {
    return <>
        <Header />
        <div className="row">
            <div className="col-sm-3 col-md-3 col-lg-3">
                <div className={ModelMetricsStyles.model_metrics_analytics_card}>
                    <div className={ModelMetricsStyles.model_metrics_analytics_card_upper_txt}>
                        Level 1 anomalies
                        <span style={{ float: "right" }}>
                            <i className="pi pi-ellipsis-v" style={{
                                fontSize: "1em",
                                color: "#938e8e"
                            }}></i>
                        </span>
                    </div>
                    <div className="row">
                        <div className="col-sm-6 col-md-6 col-lg-6" style={{ border: "1px solid red", paddingLeft: "22px" }}>
                            <div className={ModelMetricsStyles.model_metrics_analytics_card_num_right}>
                                200
                            </div>
                            <div className="">
                                12% vs last month
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-6 col-lg-6" style={{ border: "1px solid red" }}>
                            asdasdjasldjsalkd
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-sm-3 col-md-3 col-lg-3">
                <div className={ModelMetricsStyles.model_metrics_analytics_card}>
                    <div className={ModelMetricsStyles.model_metrics_analytics_card_upper_txt}>
                        Level 2 anomalies
                        <span style={{ float: "right" }}>
                            <i className="pi pi-ellipsis-v" style={{
                                fontSize: "1em",
                                color: "#938e8e"
                            }}></i>
                        </span>
                    </div>

                </div>
            </div>
            <div className="col-sm-3 col-md-3 col-lg-3">
                <div className={ModelMetricsStyles.model_metrics_analytics_card}>
                    <div className={ModelMetricsStyles.model_metrics_analytics_card_upper_txt}>

                        Level 3 anomalies
                        <span style={{ float: "right" }}>
                            <i className="pi pi-ellipsis-v" style={{
                                fontSize: "1em",
                                color: "#938e8e"
                            }}></i>
                        </span>
                    </div>

                </div>
            </div>
            <div className="col-sm-3 col-md-3 col-lg-3">
                <div className={ModelMetricsStyles.model_metrics_analytics_card}>
                    <div className={ModelMetricsStyles.model_metrics_analytics_card_upper_txt}>
                        Shutdowns
                        <span style={{ float: "right" }}>
                            <i className="pi pi-ellipsis-v" style={{
                                fontSize: "1em",
                                color: "#938e8e"
                            }}></i>
                        </span>
                    </div>

                </div>
            </div>

        </div>

        <div className="row">
            <div className="col-sm-4 col-md-4 col-lg-4">
                <div className={ModelMetricsStyles.model_metrics_analytics_big_card}>
                   
                </div>
            </div>
            <div className="col-sm-8 col-md-8 col-lg-8">
                <div className={ModelMetricsStyles.model_metrics_analytics_big_card}>
                    

                </div>
            </div>
           
            

        </div>

        
    </>
}


export default VisualisationsGraphs;