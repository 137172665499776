import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import Layout from './common/components/layout/layout';
import { PermissionsProvider } from './common/providers/PermissionsProvider';
import { LayoutProvider } from './common/components/layout/context/layout-context';

import './assets/styles/primereact/navy.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';


import AnamolyDetection from './esp-ai/components/Dashboard/anamoly-detection';
import Analyze from './esp-ai/components/Dashboard/analyze';
import ESPModelMetrics from './esp-ai/components/esp-model-metrics/esp-model-metrics';

function App() {


  return (
    <>
      <BrowserRouter>
        <PermissionsProvider>
          <LayoutProvider>
            <Layout>
              <Routes>
                <Route path="/dashboard" element={<AnamolyDetection />} />
                <Route path="/" element={<Navigate to="/dashboard" replace />} />
                <Route path="/analyze-details/:assetID/:customerID/:startDate/:endDate/:custName/:assetNAME" element={<Analyze />} />
                <Route path="/esp-modal-metrics" element={<ESPModelMetrics />} />
              </Routes>
            </Layout>
          </LayoutProvider>
        </PermissionsProvider>
      </BrowserRouter>
    </>
  )
}

export default App;
