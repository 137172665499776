import Helpers from "../../../common/utils/helpers";

import ApiConstant from "../../constants/api.constants";
import api from "../api";
import GetESPSummaryJson from "../sampledata/getESPSummary.json";
import GetAICallVHealthIndexJson1 from "../sampledata/GetAICallVHealthIndex.json";
import GetRawFeaturesJson from "../sampledata/GetRawFeatures.json";
import { ListTemplate } from "@amcharts/amcharts4/core";

// GetEspPastVerifications
const AnomalyDetectionGetWellSummaryService = async (
  startDate: any,
  endDate: any
) => {

   // Get summary data start

   let post_body = {};
   if (startDate == endDate) {
     post_body = { CustomerIds: [] };
   } else {
     post_body = {
       CustomerIds: [],
       StartTime: `'${startDate}'`,

       EndTime: `'${endDate}'`,
     };
   }
   return await api
     .post(ApiConstant.GetSoofieSummary, post_body)
     .then(function (response: any) {
       return response.data;
     })
     .catch(function (error) {
       Helpers.ConsoleLogError(error);
     });

   // Get summary data end


  // Get customer list  start

  return await api
    .get(ApiConstant.GetCustomersByProductType + "?ProductType=Soofie")
    .then(async function (response: any) {
      // EmissionCustomerC
      let cust_data = response.data.filter((val_cust: any) => {
        if (val_cust.name == 'EmissionCustomerC') {
          return true;
        }
      })
      // 


      // Get summary data start

      let post_body = {};
      if (startDate == endDate) {
        post_body = { CustomerIds: [cust_data[0].customerID] };
      } else {
        post_body = {
          CustomerIds: [cust_data[0].customerID],
          StartTime: `'${startDate}'`,

          EndTime: `'${endDate}'`,
        };
      }
      return await api
        .post(ApiConstant.GetSoofieSummary, post_body)
        .then(function (response: any) {
          return response.data;
        })
        .catch(function (error) {
          Helpers.ConsoleLogError(error);
        });

      // Get summary data end
    })
    .catch(function (error) {
      Helpers.ConsoleLogError(error);
    });

  // Get customer list end

  /* return await new Promise((resolve, reject) => {
    resolve(GetESPSummaryJson);
  }); */
};

/* const TableData = async () => {
  return await new Promise((resolve, reject) => {
    resolve(GetESPSummaryJson);
  });
}; */

const AnomalyDetectionGetWellSubSummaryService = async (
  CustID: any,
  AssetID: any,
  firstOccurance: any,
  lastOccurance: any
) => {
  var isoDateStringLast24HoursStart = "";
  if (firstOccurance == "") {
    firstOccurance = "";
    isoDateStringLast24HoursStart = "";
  } else if (lastOccurance == "subtable") {
    isoDateStringLast24HoursStart = `${firstOccurance}`;
    var minus_one_day = new Date(firstOccurance);
    minus_one_day.setDate(minus_one_day.getDate() - 1);
    var isoDateStringLast24HoursStart = new Date(minus_one_day).toISOString();
    isoDateStringLast24HoursStart = `${isoDateStringLast24HoursStart}`;
  } else if (firstOccurance != null && lastOccurance != null) {
    isoDateStringLast24HoursStart = `${firstOccurance}`;
    firstOccurance = `${lastOccurance}`;
  } else {
    var minus_one_day = new Date(firstOccurance);
    minus_one_day.setDate(minus_one_day.getDate() - 15);
    var isoDateStringLast24HoursStart = new Date(minus_one_day).toISOString();
    firstOccurance = `${firstOccurance}`;
    isoDateStringLast24HoursStart = `${isoDateStringLast24HoursStart}`;
  }

  return await api
    .post(ApiConstant.GetEspPastVerifications, {
      CustomerId: CustID,
      AssetId: [AssetID],
      StartTime: `'${isoDateStringLast24HoursStart}'`,
      EndTime: `'${firstOccurance}'`,
    })
    .then(function (response: any) {
      return response.data;
    })
    .catch(function (error) {
      Helpers.ConsoleLogError(error);
    });
};

//To add anomaly data - Jerin Monish
const AddAnomalyDetectionGetWellSummaryService = async (
  Redux_data: any,
  new_data: any,
  existing_data: any
) => {
  return await api
    .post(ApiConstant.AddUpdateESPVerification, new_data, {
      headers: {
        "content-type": "multipart/form-data",
      },
    })
    .then(function (response: any) {
      let _ReduxData = JSON.parse(JSON.stringify(Redux_data)); //Created A Copy for rewrite object
      _ReduxData.forEach(function (obj: any) {
        if (
          obj.customerID == existing_data?.CustomerId &&
          obj.assetId == existing_data?.wellId
        ) {
          obj.assetName = existing_data?.asset_name;
          obj.assetId = existing_data?.wellId;
          obj.customerName = existing_data?.operator_name;
          obj.customerID = existing_data?.CustomerId;
          obj.severity = existing_data?.correct_level;
          obj.firstOccurance = existing_data.survey_start_date
            ? Helpers.convertDateToUTC(
              existing_data.survey_start_date,
              existing_data.survey_start_time
            )
            : "";
          obj.lastOccurance = existing_data.survey_end_date
            ? Helpers.convertDateToUTC(
              existing_data.survey_end_date,
              existing_data.survey_end_time
            )
            : null;
          obj.reason = existing_data?.reason_anamoly;
          obj.comments = existing_data?.description;
          obj.leak = existing_data?.ai_correct;
        }
      });
      return _ReduxData;
    })
    .catch(function (error) {
      Helpers.ConsoleLogError(error);
    });
};

//To get well data based on customer id - Jerin Monish
const getWellDetailsById = async (id: any) => {
  return await api
    .post(ApiConstant.GetSoofieSummary, {
      CustomerIds: [id],
    })
    .then(function (response: any) {
      return response.data;
    })
    .catch(function (error) {
      Helpers.ConsoleLogError(error);
    });
};

const GetAICallVHealthIndex = async (
  assetID: any,
  CustomerId: any,
  StartTime: any,
  TimeFilter: any
) => {

  // return await new Promise((resolve, reject) => {
  //   resolve(GetAICallVHealthIndexJson);
  // });


  return await api
    .post(ApiConstant.GetAICallVHealthIndex, {
      AssetId: assetID,
      StartTime: `${StartTime}`,
      EndTime: `${TimeFilter}`,
      CustomerId: CustomerId,
      TimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
    })
    .then(function (response: any) {
  //   resolve(GetAICallVHealthIndexJson);
  // return GetAICallVHealthIndexJson1;
      return response.data;
    })
    .catch(function (error) {
      Helpers.ConsoleLogError(error);
    });


};

const GetRawFeatures = async (
  assetID: any,
  CustomerId: any,
  StartTime: any,
  TimeFilter: any
) => {
   return await api
    .post(ApiConstant.GetRawFeatures, {
      AssetId: assetID,
      StartTime: `${StartTime}`,
      EndTime: `${TimeFilter}`,
      CustomerId: CustomerId,
      TimeZone:Intl.DateTimeFormat().resolvedOptions().timeZone
    })
    .then(async function (response: any) {
      // return response.data;

      // Raw Feature Calculations Start
      return await new Promise((resolve, reject) => {
        const chartData: any = [];
        let GetRawFeaturesJson = response.data;
        GetRawFeaturesJson.readingData.entries.forEach((entry: any, index: any) => {
          const time = entry.Time;
    
          // Extract CH4 data
          const ch4: any = {};
          Object.keys(entry)
            .filter(key => key.startsWith("METH-Device"))
            .forEach(key => {
              const modifiedKey = key.replace(/\s+/g, '-'); // Replace spaces with hyphens
              ch4[modifiedKey] = entry[key];
            });
    
          // Extract Wind data
          const windData = GetRawFeaturesJson.windData[0].entries.find((windEntry:any) => windEntry.Time === time);
    
          // Extract Emission data
          const emissionDataEntry = GetRawFeaturesJson.emissionData[0].entries.find((emissionEntry:any) => emissionEntry.Time === time);
          const totalEmission = emissionDataEntry ? emissionDataEntry.Emission : null;
    
    
          // Extract Anomaly data
          const AnomalyDataEntry = GetRawFeaturesJson.anomalyData[0].entries.find((AnomalyEntry:any) => AnomalyEntry.Time === time);
          const Prediction = AnomalyDataEntry ? AnomalyDataEntry.Prediction : null;
    
          // Extract Temp data with device names as keys
          const temp = GetRawFeaturesJson.readingData.columns
            .filter((col:any) => col.startsWith("TEMP-Device"))
            .reduce((tempObj: any, tempDevice: any) => {
              const modifiedTempDevice = tempDevice.replace(/\s+/g, '-'); // Replace spaces with hyphens
              tempObj[modifiedTempDevice] = entry[tempDevice];
              return tempObj;
            }, {});
    
          // Extract Pressure data with device names as keys
          const pressure = GetRawFeaturesJson.readingData.columns
            .filter((col:any) => col.startsWith("PRESS-Device"))
            .reduce((pressureObj: any, pressureDevice: any) => {
              const modifiedPressureDevice = pressureDevice.replace(/\s+/g, '-'); // Replace spaces with hyphens
              pressureObj[modifiedPressureDevice] = entry[pressureDevice];
              return pressureObj;
            }, {});
    
          // Create the chartData entry
          const chartDataEntry = {
            Time: time,
            CH4: [ch4],
            WindSpeed: [windData],
            TotalEmission: totalEmission,
            Temp: [temp],
            Pressure: [pressure],
            Anomaly: Prediction
          };
    
          if (totalEmission == null) {
            let chartDataEntry = {
              Time: time,
              CH4: [ch4],
              WindSpeed: [windData],
              TotalEmission: totalEmission,
              Temp: [temp],
              Pressure: [pressure],
              Anomaly: Prediction
            };
            chartData.push(chartDataEntry);
    
          } else {
            chartData.push(chartDataEntry);
    
          }
        });
    
        const result = { chartData };
        // console.log(JSON.stringify(result, null, 2));
        resolve(result);
    
      });
      // Raw Feature Calculations End
    })
    .catch(function (error) {
      Helpers.ConsoleLogError(error);
    }); 
  
};

const GetEspVerificationlog = async (
  CustomerId: any,
  StartTime: any,
  EndTime: any,
  assetID: any
) => {
  return await api
    .post(ApiConstant.GetEspVerificationlog, {
      AssetType: "Soofie",
      CustomerID: CustomerId,
      AssetId: [assetID],
      DateFilterStartDate: "",
      DateFilterEndDate: "",
      ModelType: "Soofie",
      State: "All,Monitor,close,true,close,false",
    })
    .then(function (response: any) {
      return response.data;
    })
    .catch(function (error) {
      Helpers.ConsoleLogError(error);
    });
};

const DownloadService = async (fileName: any) => {
  return await api
    .get(ApiConstant.DownloadFile + "?fileName=" + fileName, {
      responseType: "blob",
    })
    .then(function (response: any) {
      return response.data;
    })
    .catch(function (error) {
      Helpers.ConsoleLogError(error);
    });
};

const AnomalyDetectionService = {
  AnomalyDetectionGetWellSummaryService,
  AddAnomalyDetectionGetWellSummaryService,
  AnomalyDetectionGetWellSubSummaryService,
  getWellDetailsById,
  GetAICallVHealthIndex,
  GetRawFeatures,
  GetEspVerificationlog,
  DownloadService,
};
export default AnomalyDetectionService;
