// React
import { useEffect, useRef, useState } from 'react';

// prime
import { Button } from 'primereact/button';
import { OverlayPanel } from "primereact/overlaypanel";

// react i18n
import { useTranslation } from "react-i18next";


const SelectNormalFilterAIvsHeath = (props: any) => {
    const { t } = useTranslation();
    const filterDpDown = useRef<any>(null);
    const [eyeStateHeathIndexScore, seteyeStateHeathIndexScore] = useState('pi-eye');
    const [eyeStateAICall, seteyeStateAICall] = useState('pi-eye');
    const [eyeStateCurrentAnamoly, seteyeStateCurrentAnamoly] = useState('pi-eye');
    const [eyeStatePastAnamoly, seteyeStatePastAnamoly] = useState('pi-eye');

    useEffect(() => {
        if (props.viewCurrentAnamolyState) {
            seteyeStateCurrentAnamoly("pi-eye");
        } else {
            seteyeStateCurrentAnamoly("pi-eye-slash");

        }

        if (props.viewPastAnamolyState) {
            seteyeStatePastAnamoly("pi-eye");
        } else {
            seteyeStatePastAnamoly("pi-eye-slash");

        }

    }, [props])
    return (
        <>
            <Button severity="secondary" text raised style={{ fontWeight:"500",height: "30px", fontSize: "12px", float: "right",marginLeft:"12px" }} onClick={(e) => filterDpDown.current.toggle(e)}><i className='pi pi-filter' />&nbsp;{t('AnamolyDetectionAnalyze.filters')}</Button>
            <OverlayPanel ref={filterDpDown}>
                <div className="flex align-items-center mt-2" style={{ fontWeight: 500 }}>
                    {t("AnamolyDetectionAnalyze.ViewOptions")}
                </div>
                <div className="flex align-items-center mt-4" style={{ cursor: "pointer" }} onClick={() => {
                    if (eyeStateCurrentAnamoly == "pi-eye") {
                        seteyeStateCurrentAnamoly('pi-eye-slash');
                        props.setviewCurrentAnamolyState(false);

                    } else {
                        seteyeStateCurrentAnamoly('pi-eye');
                        props.setviewCurrentAnamolyState(true);


                    }
                }}>
                    {t('AnamolyDetectionAnalyze.ViewCurrentAnamoly')}
                    <label htmlFor={"rsp"} className="ml-2 float_eyes_toRightviewOP_AI">
                        <i className={`pi ${eyeStateCurrentAnamoly}`}></i>
                    </label>
                </div>


                <div className="flex align-items-center mt-4" style={{ cursor: "pointer" }} onClick={() => {
                    if (eyeStatePastAnamoly == "pi-eye") {
                        seteyeStatePastAnamoly('pi-eye-slash');
                        props.setviewPastAnamolyState(false);

                    } else {
                        seteyeStatePastAnamoly('pi-eye');
                        props.setviewPastAnamolyState(true);


                    }
                }}>
                    {t('AnamolyDetectionAnalyze.ViewHistoryAnamolies')}
                    <label htmlFor={"rsp"} className="ml-2">
                        <i className={`pi ${eyeStatePastAnamoly}`}></i>
                    </label>
                </div>


                {/* <hr style={{ border: "1px solid" }} />
                <div className="flex align-items-center mt-2" style={{ fontWeight: 500 }}>
                    {t("AnamolyDetectionAnalyze.shown")}
                </div>
                <div className="flex align-items-center mt-2" onClick={(e) => {
                    if (eyeStateHeathIndexScore == "pi-eye") {
                        seteyeStateHeathIndexScore('pi-eye-slash');
                        props.setheathIndexScoreIsSelected(false);
                    } else {
                        seteyeStateHeathIndexScore('pi-eye');
                        props.setheathIndexScoreIsSelected(true);

                    }
                }} style={{ cursor: "pointer" }}>
                    <i className='pi pi-ellipsis-v' style={{ marginRight: "-10px" }}></i><i className='pi pi-ellipsis-v'></i>
                    <span className='p-2'>{t("AnamolyDetectionAnalyze.heathIndexScore")}</span>
                    <label htmlFor={"rsp"} className="ml-2">
                        <i className={`pi ${eyeStateHeathIndexScore}`}></i>
                    </label>
                </div>

                <div className="flex align-items-center mt-2" onClick={(e) => {
                    if (eyeStateAICall == "pi-eye") {
                        seteyeStateAICall('pi-eye-slash');
                        props.setAiCallIsSelected(false);

                    } else {
                        seteyeStateAICall('pi-eye');
                        props.setAiCallIsSelected(true);

                    }
                }} style={{ cursor: "pointer" }}>
                    <i className='pi pi-ellipsis-v' style={{ marginRight: "-10px" }}></i><i className='pi pi-ellipsis-v'></i>
                    <span className='p-2'>{t("AnamolyDetectionAnalyze.AiCall")}</span>
                    <label htmlFor={"rsp"} className="ml-2">
                        <i className={`pi ${eyeStateAICall}`} ></i>
                    </label>
                </div> */}
            </OverlayPanel>
        </>
    )
}

export default SelectNormalFilterAIvsHeath;