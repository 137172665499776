import { createContext, useEffect, useState } from 'react';
import { createSearchParams, useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import UserPermission from '../../models/security/user-permission';
import AuthService from '../../services/autentication/auth.service';
import TokenService from '../../services/autentication/token.service';
import SecurityService from '../../services/security/security.service';

export interface PermissionsContextType {
    canAccessViewSiteUpload?: boolean,
    canAccessEditSiteUpload?: boolean,
    canAccessViewSiteSurvey?: boolean,
    canAccessEditSiteSurvey?: boolean,
    canAccessViewSuveyDetails?: boolean,
    canAccessEditSuveyDetails?: boolean,
    canAccessViewAllSurveyDashboard?: boolean,
};

export const PermissionsContext = createContext<PermissionsContextType>(null!);

export const PermissionsProvider = (props: any) => {

    const [canAccessViewSiteUpload, setCanAccessViewSiteUpload] = useState(false);
    const [canAccessEditSiteUpload, setCanAccessEditSiteUpload] = useState(false);
    const [canAccessViewSiteSurvey, setCanAccessViewSiteSurvey] = useState(false);
    const [canAccessEditSiteSurvey, setCanAccessEditSiteSurvey] = useState(false);
    const [canAccessViewSuveyDetails, setCanAccessViewSuveyDetails] = useState(false);
    const [canAccessEditSuveyDetails, setCanAccessEditSuveyDetails] = useState(false);
    const [canAccessViewAllSurveyDashboard, setCanAccessViewAllSurveyDashboard] = useState(false);


    const location = useLocation();
    const navigate = useNavigate();

    const [searchParams, setSearchParams] = useSearchParams();

    //make acceess token saved in localstroage
    useEffect(() => {
        // remove the at parameter if it is present in the URL. This happens when we are redirected back from an OAuth
        // flow.
        const acceess_token: string | null = new URLSearchParams(location.search).get('at');
        if (acceess_token)
            TokenService.addOrUpdateLocalAccessToken(acceess_token);
        AuthService.refreshTokenFromServer(true);

        if (searchParams.has('at')) {
            const token = searchParams.get('at');
            if (token) {
                searchParams.delete('at');
                const newParams: { [key: string]: string } = {};
                searchParams.forEach((value: string, key: string) => {
                    newParams[key] = value;
                });

                setSearchParams(newParams);
                navigate({
                    search: createSearchParams(newParams).toString(),
                }, { replace: true });
            }
        }

        getPermissionOfCurrentUser();
    }, []);



    useEffect(() => {
        const timerId = setInterval(() => {
            AuthService.refreshTokenFromServer(false);
        }, 1740000) //refreshing token before it expires
        return () => {
            clearInterval(timerId)
        }
    }, [])


    const getPermissionOfCurrentUser = async () => {
        var userPermissions: UserPermission = await SecurityService.getPermissionsOfCurrentUser();
        setCanAccessViewSiteUpload(userPermissions.ViewSiteUpload);
        setCanAccessEditSiteUpload(userPermissions.EditSiteUpload);
        setCanAccessViewSiteSurvey(userPermissions.ViewSiteSurvey);
        setCanAccessEditSiteSurvey(userPermissions.EditSiteSurvey);
        setCanAccessViewSuveyDetails(userPermissions.ViewSuveyDetails);
        setCanAccessEditSuveyDetails(userPermissions.EditSuveyDetails);
        setCanAccessViewAllSurveyDashboard(userPermissions.ViewAllSurveyDashboard);
    }

    return (
        <PermissionsContext.Provider
            value={{
                canAccessViewSiteUpload, canAccessEditSiteUpload, canAccessViewSiteSurvey,
                canAccessEditSiteSurvey, canAccessViewSuveyDetails, canAccessEditSuveyDetails,
                canAccessViewAllSurveyDashboard
            }}
        >
            {props.children}
        </PermissionsContext.Provider>
    );
};
