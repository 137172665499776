import { configureStore, combineReducers } from '@reduxjs/toolkit';
import EspAnomalyDetectionStore from './esp-anomaly-detection.store';


const rootReducer = combineReducers({
  
    EspAnomalyDetectionStore: EspAnomalyDetectionStore.reducer
});


const store = configureStore({
    reducer: rootReducer
});


export type AppDispatch = typeof store.dispatch;
export type AppState = ReturnType<typeof rootReducer>;

export default store;