import CommonConstant from "../constants/common.constant";
import * as XLSX from 'xlsx';
import { utils, writeFileXLSX } from 'xlsx';

const ConsoleLog = (message: any, data?: string) => {
    if (`${process.env.ENVIRONMENT}` == CommonConstant.localEnvironemt) {
        if (data)
            console.log(message, data);
        else
            console.log(message, data);
    }
};

const ConsoleLogError = (message: any, data?: string) => {
    if (`${process.env.ENVIRONMENT}` == CommonConstant.localEnvironemt) {
        if (data)
            console.error(message, data);
        else
            console.error(message, data);
    }
};

const exportToCSV = (jsonData: any, fileName: string, sheetName?: string, headersRow?: any, keysWhichNeedsTobeFilteredFromJSON?: any) => {
    const fileExtension = '.xlsx';
    var jsonObjectForExcelCreation = jsonData;
    var excelOptions = {};
    var skipHeaderRow = false;

    if (headersRow) {
        skipHeaderRow = true;
    }

    if (keysWhichNeedsTobeFilteredFromJSON?.length > 0) {
        jsonObjectForExcelCreation = jsonObjectForExcelCreation.map((obj: any) => Object.fromEntries(Object.entries(obj).filter((r: any) => keysWhichNeedsTobeFilteredFromJSON?.indexOf(r[0]) > -1)));
        excelOptions = { header: keysWhichNeedsTobeFilteredFromJSON, skipHeader: skipHeaderRow }
    }

    if (headersRow) {
        jsonObjectForExcelCreation.unshift(headersRow);
    }

    var ws: XLSX.WorkSheet = utils.json_to_sheet(jsonObjectForExcelCreation, excelOptions);

    var wb: XLSX.WorkBook = utils.book_new();
    utils.book_append_sheet(wb, ws, sheetName ?? 'Data');

    writeFileXLSX(wb, fileName + "-" + new Date().toISOString() + fileExtension);

}

const convertFullDateToReadableFormat = (fullDateTime: any) => {
    let date = new Date(fullDateTime);
    const month = date.toLocaleString('default', { month: 'short' });
    const day = date.toLocaleDateString("default", { // you can use undefined as first argument
        day: "2-digit",
    });
    const year = date.toLocaleDateString("default", { // you can use undefined as first argument
        year: "numeric",
    });

    let hrs_mins = date.toLocaleString('default', { hour: 'numeric', minute: 'numeric', hour12: true })
    let return_format = month + " " + day + ", " + year + " " + hrs_mins;

    return return_format;
}

const convertFullDateToReadableFormatToolTip = (fullDateTime: any) => {
    let date = new Date(fullDateTime);
    const month = date.toLocaleString('default', { month: 'short' });
    const day = date.toLocaleDateString("default", { // you can use undefined as first argument
        day: "2-digit",
    });
    const year = date.toLocaleDateString("default", { // you can use undefined as first argument
        year: "numeric",
    });

    let hrs_mins = date.toLocaleString('default', { hour: 'numeric', minute: 'numeric', hour12: false })
    let return_format = month + " " + day + ", " + year;

    return return_format;
}

const convertFullDateToDateReadableFormatToolTip = (fullDateTime: any) => {
    let date = new Date(fullDateTime);
    const month = date.toLocaleString('default', { month: 'short' });
    const day = date.toLocaleDateString("default", { // you can use undefined as first argument
        day: "2-digit",
    });
    const year = date.toLocaleDateString("default", { // you can use undefined as first argument
        year: "numeric",
    });
    let hrs_mins = date.toLocaleString('default', { hour: 'numeric', minute: '2-digit', hour12: true })

    let return_format = month + " " + day + ", " + year+ " "+hrs_mins;

    return return_format;
}


const convertFullDateToReadableFormatForFilterRange = (fullDateTime: any) => {
    let date = new Date(fullDateTime);
    const month = date.toLocaleString('default', { month: 'short' });
    const day = date.toLocaleDateString("default", { // you can use undefined as first argument
        day: "2-digit",
    });
    const year = date.toLocaleDateString("default", { // you can use undefined as first argument
        year: "numeric",
    });

    let hrs_mins = date.toLocaleString('default', { hour: 'numeric', minute: 'numeric', hour12: false })
    let return_format = month + " " + day + ", " + year;

    return return_format;
}


const convertOnlyDateToReadableFormat = (fullDateTime: any) => {
    let date = new Date(fullDateTime);
    const month = date.toLocaleString('default', { month: '2-digit' });
    const day = date.toLocaleDateString("default", { // you can use undefined as first argument
        day: "2-digit",
    });
    const year = date.toLocaleDateString("default", { // you can use undefined as first argument
        year: "numeric",
    });


    let return_format = month + "/" + day + "/" + year;

    return return_format;
}

const convertOnlyTimeToReadableFormat = (fullDateTime: any) => {
    let date = new Date(fullDateTime);
    let isoDate = new Date(date).toISOString();
    // let test = new Date(isoDate);
    // test.getUTCHours();

    let hrs_mins = date.toLocaleString('default', { hour: "numeric", minute: '2-digit', hour12: true })

    return hrs_mins;
}


const convertDateToUTC = (getDateFormat: any, getTimeFormat: any) => {

    let date = new Date(getDateFormat);
    let date1 = new Date(getTimeFormat);
    const month = date.toLocaleString('default', { month: 'short' });
    const day = date.toLocaleDateString("default", { // you can use undefined as first argument
        day: "2-digit",
    });
    const year = date.toLocaleDateString("default", { // you can use undefined as first argument
        year: "numeric",
    });

    let hrs_mins = date1.toLocaleString('default', { hour: 'numeric', minute: 'numeric', hour12: true })

    let returned_date = new Date(month + " " + day + "," + year + " " + hrs_mins);
    return returned_date.toISOString();

}


const getHoursForRawTrendHistoryAnamoly = (ai_date: any, raw_date: any) => {
    let new_ai_date = new Date(ai_date);
    let new_raw_date = new Date(raw_date);

    // get AI Date
    let month1 = new_ai_date.toLocaleString('default', { month: 'short' });
    let day1 = new_ai_date.toLocaleDateString("default", { // you can use undefined as first argument
        day: "2-digit",
    });
    let year1 = new_ai_date.toLocaleDateString("default", { // you can use undefined as first argument
        year: "numeric",
    });
    let hrs1 = new_ai_date.toLocaleString('default', { hour: 'numeric' });


    // get RAW Date
    let month2 = new_raw_date.toLocaleString('default', { month: 'short' });
    let day2 = new_raw_date.toLocaleDateString("default", { // you can use undefined as first argument
        day: "2-digit",
    });
    let year2 = new_raw_date.toLocaleDateString("default", { // you can use undefined as first argument
        year: "numeric",
    });
    let hrs2 = new_raw_date.toLocaleString('default', { hour: 'numeric' });
    if ((month1 == month2) && (day1 == day2) && (year1 == year2) && (hrs1 == hrs2)) {
        return true;
    } else {
        return false
    }


}

const removeAlphabetsAlone = (givenDate: any) => {
    if (givenDate) {
        var res = givenDate.replace(/[a-zA-Z]/g, ' ')
        return res;
    }
}
const convertOnlyTimeToReadableFormatToolTIp = (fullDateTime: any) => {
    let date = new Date(fullDateTime);
    let isoDate = new Date(date).toISOString();
    // let test = new Date(isoDate);
    // test.getUTCHours();

    let hrs_mins = date.toLocaleString('default', { hour: 'numeric', minute: '2-digit', hour12: true })

    return hrs_mins;
}
const Helpers = {
    ConsoleLog,
    ConsoleLogError,
    exportToCSV,
    convertFullDateToReadableFormat,
    convertDateToUTC,
    convertOnlyDateToReadableFormat,
    removeAlphabetsAlone,
    convertOnlyTimeToReadableFormat,
    convertFullDateToReadableFormatToolTip,
    convertFullDateToReadableFormatForFilterRange,
    getHoursForRawTrendHistoryAnamoly,
    convertFullDateToDateReadableFormatToolTip,
    convertOnlyTimeToReadableFormatToolTIp
};

export default Helpers;