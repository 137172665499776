// style
import "../../../assets/styles/esp-ai/styles.scss";

// loading images
import loadingMid from "../../../assets/img/esp-ai/loader-mid.png";
import loadingInner from "../../../assets/img/esp-ai/loader-inner.png";

// i18n
import { useTranslation } from "react-i18next";


const Loading = (loadingCondtion: any) => {
    const { t } = useTranslation()
    let LoadingText = loadingCondtion.text;
    if (LoadingText == "") {
        LoadingText = t("LoadingMessage");
    }
    LoadingText = "";
    if (loadingCondtion.isLoading) {
        document.body.style.overflow = "hidden";
        document.body.style.height = "100%";
        return <>
            <div className="div-loading" >
                <div className="div-loading-inner-div">
                    <div className="div-loading-mid" style={{ background: `url(${loadingMid})` }}></div>
                    <div className="div-loading-inner" style={{ background: `url(${loadingInner})` }}></div>
                </div>
            </div>
        </>
    } else {

        document.body.style.overflow = "scroll";
        document.body.style.height = "0%";
        return <></>
    }


}


export default Loading;