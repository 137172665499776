import ApiConstant from "../../constants/api.constants";
import Token from "../../models/authorization/token";
import Helpers from "../../utils/helpers";
import api from "../api";
import TokenService from "./token.service";

const logout = () => {
  TokenService.removeUser();
};

const refreshTokenFromServer = async (isFirstTimeCalling: boolean) => {

  let token = isFirstTimeCalling ? TokenService.getLocalAccessToken() : TokenService.getLocalRefreshToken();
  let grantType = isFirstTimeCalling ? 'reactapp' : 'refresh_token';
  await api
    .post(ApiConstant.getToken,
      {
        grant_type: grantType,
        refresh_token: token
      },
      {
        headers: {
          'content-type': 'application/x-www-form-urlencoded',
        },
      }
    )
    .then(function (response: any) {
      if(response){
        let data: Token = response.data;

        TokenService.addOrUpdateLocalRefreshToken(data?.refresh_token);
        TokenService.addOrUpdateLocalAccessToken(data?.access_token);
      }
    })
    .catch(function (error) {
      Helpers.ConsoleLog(error,"error")
      TokenService.addOrUpdateLocalAccessToken('');
      TokenService.addOrUpdateLocalRefreshToken('');
    });

};

const AuthService = {
  logout,
  refreshTokenFromServer
};

export default AuthService;