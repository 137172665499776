
export const AppFooter = () => {
    const environment = process.env.REACT_APP_ENVIRONMENT;
    let d_year: any = new Date();
    d_year = d_year.getFullYear();
    return (
        <footer className="border-top mt-auto footer_background_color" id="footer">
            <div className="hide-fixed">
                <div className="footer_text">
                    <span data-i18n="Common.Poweredby">Powered by</span> ENTERPRISE<span className="testOrDev"> {(`${environment}` == 'local' ? ' - TEST' : '')}</span> © {d_year}
                </div>
            </div>
        </footer>
    );
};
export default AppFooter;
