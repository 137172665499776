import ApiConstant from "../../constants/api.constants";
import Helpers from "../../utils/helpers";
import api from "../api";
import TokenService from "../autentication/token.service";

const getPermissionsOfCurrentUser = async () => {

    let user: any = TokenService.getUser();
    if (user != null) {

        if (user.accessLevelNew == "Global Admin") {
            return {
                "ViewSiteUpload": true,
                "EditSiteUpload": true,
                "ViewSiteSurvey": true,
                "EditSiteSurvey": true,
                "ViewSuveyDetails": true,
                "EditSuveyDetails": true,
                "ViewAllSurveyDashboard": true
            };
        } else if (user.accessLevelNew == "Administrator") {
            return {
                "ViewSiteUpload": true,
                "EditSiteUpload": false,
                "ViewSiteSurvey": true,
                "EditSiteSurvey": false,
                "ViewSuveyDetails": true,
                "EditSuveyDetails": false,
                "ViewAllSurveyDashboard": true
            };
        } else if (user.accessLevelNew == "Corporate") {
            return {
                "ViewSiteUpload": true,
                "EditSiteUpload": false,
                "ViewSiteSurvey": true,
                "EditSiteSurvey": false,
                "ViewSuveyDetails": true,
                "EditSuveyDetails": false,
                "ViewAllSurveyDashboard": true
            };
        } else if (user.accessLevelNew == "Analyst") {
            return {
                "ViewSiteUpload": true,
                "EditSiteUpload": false,
                "ViewSiteSurvey": true,
                "EditSiteSurvey": false,
                "ViewSuveyDetails": true,
                "EditSuveyDetails": false,
                "ViewAllSurveyDashboard": true
            };
        }else {
            return {
                "ViewSiteUpload": true,
                "EditSiteUpload": false,
                "ViewSiteSurvey": true,
                "EditSiteSurvey": false,
                "ViewSuveyDetails": true,
                "EditSuveyDetails": false,
                "ViewAllSurveyDashboard": true,
            };
        }

    } else {
        return {
            "ViewSiteUpload": true,
            "EditSiteUpload": false,
            "ViewSiteSurvey": true,
            "EditSiteSurvey": false,
            "ViewSuveyDetails": true,
            "EditSuveyDetails": false,
            "ViewAllSurveyDashboard": true,
        };
    }


    // return await api
    //     .get(ApiConstant.getPermissionsOfCurrentUser)
    //     .then(function (response: any) {
    //         return response.data;
    //     })
    //     .catch(function (error) {
    //         Helpers.ConsoleLogError(error);
    //     });
};

const SecurityService = {
    getPermissionsOfCurrentUser
};

export default SecurityService;