import { createContext, Dispatch, SetStateAction, useState } from 'react';

export interface LayoutContextType {
    layoutConfig: LayoutConfig,
    setLayoutConfig: Dispatch<SetStateAction<LayoutConfig>>,
    layoutState: LayoutState,
    setLayoutState: Dispatch<SetStateAction<LayoutState>>,
    onMenuToggle: any,
    showProfileSidebar: any
};

export interface LayoutConfig {
    ripple: boolean,
    inputStyle: string,
    menuMode: string,
    colorScheme: string,
    theme: string,
    scale: number
}

export interface LayoutState {
    staticMenuDesktopInactive: false,
    isMenuExpanded: boolean,
    profileSidebarVisible: false,
    configSidebarVisible: false,
    staticMenuMobileActive: false,
    menuHoverActive: false
}


export const LayoutContext = createContext<LayoutContextType>(null!);

export const LayoutProvider = (props: any) => {
    const [layoutConfig, setLayoutConfig] = useState<LayoutConfig>({
        ripple: false,
        inputStyle: 'outlined',
        menuMode: 'static',
        colorScheme: 'light',
        theme: 'lara-light-indigo',
        scale: 14
    });

    const [layoutState, setLayoutState] = useState<LayoutState>({
        staticMenuDesktopInactive: false,
        isMenuExpanded: true,
        profileSidebarVisible: false,
        configSidebarVisible: false,
        staticMenuMobileActive: false,
        menuHoverActive: false
    });

    const onMenuToggle = () => {
        setLayoutState((prevLayoutState: any) => ({ ...prevLayoutState, isMenuExpanded: !prevLayoutState.isMenuExpanded }));

        if (isDesktop()) {
            setLayoutState((prevLayoutState: any) => ({ ...prevLayoutState, staticMenuDesktopInactive: !prevLayoutState.staticMenuDesktopInactive }));
        } else {
            setLayoutState((prevLayoutState: any) => ({ ...prevLayoutState, staticMenuMobileActive: !prevLayoutState.staticMenuMobileActive }));
        }
    };

    const showProfileSidebar = () => {
        setLayoutState((prevLayoutState: any) => ({ ...prevLayoutState, profileSidebarVisible: !prevLayoutState.profileSidebarVisible }));
    };

    const isDesktop = () => {
        return window.innerWidth > 991;
    };

    const value: LayoutContextType = {
        layoutConfig,
        setLayoutConfig,
        layoutState,
        setLayoutState,
        onMenuToggle,
        showProfileSidebar
    };

    return <LayoutContext.Provider value={value}>{props.children}</LayoutContext.Provider>;
};
