// style
import "../../../assets/styles/esp-ai/styles.scss";


// i18n
import { useTranslation } from "react-i18next";
import { OverlayPanel } from "primereact/overlaypanel";
import { useRef } from "react";


const OverpanelHistoryTabComments = (props:any) => {
  const filterDpDown = useRef<any>(null);
  const filterDpDown1 = useRef<any>(null);

    let comments = props.data;
    if(comments == null){
        comments = "N/A";
    }
    let userName = props.username;
    let verifiedOn = props.verifiedOn;
    return <>
        <OverlayPanel ref={filterDpDown}>
            <div>
                <b>Comment</b> : {comments}
            </div>
        </OverlayPanel>
        <OverlayPanel ref={filterDpDown1}>
              <div>
              <b>Verified by </b>: {userName} <br/>
              <b> Analyst verified on </b>: {verifiedOn}
              </div>
        </OverlayPanel>


        <i className="pi pi-info-circle p-text-secondary p-overlay-badge"
              onMouseOver={(e) => {
                filterDpDown.current.show(e)
              }}
              onMouseLeave={(e) => {
                filterDpDown.current.hide(e)
              }}
              style={{ fontSize: '20px', cursor: 'pointer' }}
            >
            </i>

            <i className=" pi pi-user"
              onMouseOver={(e) => {
                filterDpDown1.current.show(e)
              }}

              onMouseLeave={(e) => {
                filterDpDown1.current.hide(e)
              }}
            
              style={{ fontSize: '20px', cursor: 'pointer', marginLeft: "10px" }}
            >
            </i>


    </>


}


export default OverpanelHistoryTabComments;