import { useContext } from 'react';

import ChampionXLogo from "../../../assets/img/championx-logo.png"
import leftHeaderLogo from "../../../assets/img/banner-dark.png"
import { LayoutContext } from './context/layout-context';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';

const AppTopbar = () => {
    const { onMenuToggle } = useContext(LayoutContext);
    const environment = process.env.REACT_APP_ENVIRONMENT;
    const mainSiteUrl = process.env.REACT_APP_MVC_URL;

    const confirmLogout = () => {
        confirmDialog({
            message: 'Are you sure you want to logout from the portal ?',
            header: 'Confirm Logout',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                localStorage.clear();
                if (mainSiteUrl)
                  window.location.assign(mainSiteUrl)
            }
        });
    }
    

    return (
        <header className="header">
            <div className="header__inner">
                <div className={'d-none d-sm-block header__brand ' + (`${environment}` == 'local' ? '' : 'prod')}  >
                    <div className="brand-wrap-header">
                        <a href="https://testenterprise.apergy.com/" className="brand-img stretched-link">
                            <img src={leftHeaderLogo} alt="Nifty Logo" className="left_header_logo" />
                        </a>
                        <div className="brand-title">ENTERPRISE  {(`${environment}` == 'local' ? ' - TEST' : '')}</div>
                    </div>
                </div>
                <div className="header__content">
                    <div className="header__content-start">
                        <button type="button" className="nav-toggler header__btn btn btn-icon btn-sm" onClick={onMenuToggle}>
                            <i className="psi-list-view fa-lg"></i>
                        </button>
                    </div>
                    <div className="top-middle-header-logo">
                        <img src={ChampionXLogo} className="logo"></img>
                    </div>
                    <div className="header__content-end">
                        <button className="header__btn btn btn-icon btn-sm" type="button" aria-label="Logout button"  onClick={() =>{
                            
                            confirmDialog({
                                message: 'Are you sure you want to logout from the portal ?',
                                header: 'Confirm logout',
                                icon: 'pi pi-exclamation-triangle',
                                accept: () => {
                                    localStorage.clear();
                                    if (mainSiteUrl)
                                      window.location.assign(mainSiteUrl)
                                }
                            });
                        }}>
                            <i className="pi pi-sign-out" ></i>
                        </button>
                        <ConfirmDialog /> 
                    </div>
                </div>
            </div>
        </header>

    );
};

export default AppTopbar;


