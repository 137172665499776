import ApiConstant from "../../constants/api.constants";
import Helpers from "../../utils/helpers";
import api from "../api";
import TokenService from "../autentication/token.service";

const getCurrentUser = () => {
  return api.post(ApiConstant.getUser).then((response: any) => {
    Helpers.ConsoleLog(response);
    TokenService.setUser(response.data);
    return response;
  });;
};

const setCurrentUserPermissions = () => {
  let user_details = TokenService.getUser();
  
  if (user_details?.type == 'GlobalAdmin') {
    return [process.env.REACT_APP_GLOBAL_ADMIN_READ, process.env.REACT_APP_GLOBAL_ADMIN_ADD, process.env.REACT_APP_GLOBAL_ADMIN_UPDATE, process.env.REACT_APP_GLOBAL_ADMIN_DELETE];

  } else if (user_details?.type == 'CustomerAdmin') {
    return [process.env.REACT_APP_CUSTOMER_ADMIN_READ, process.env.REACT_APP_CUSTOMER_ADMIN_ADD, process.env.REACT_APP_CUSTOMER_ADMIN_UPDATE, process.env.REACT_APP_CUSTOMER_ADMIN_DELETE];
  }else{
    return [1,1,1,1]
  }
}

const UserService = {
  getCurrentUser,
  setCurrentUserPermissions
};

export default UserService;