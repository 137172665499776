import { createContext, Dispatch, SetStateAction, useState } from 'react';

export interface MenuContextType {
    activeMenu: string,
    setActiveMenu: Dispatch<SetStateAction<string>>
}

export const MenuContext = createContext<MenuContextType>(null!);

export const MenuProvider = (props: any) => {
    const [activeMenu, setActiveMenu] = useState<string>('');

    const value: MenuContextType = {
        activeMenu,
        setActiveMenu
    };

    return <MenuContext.Provider value={value}>{props.children}</MenuContext.Provider>;
};
