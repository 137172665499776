import { Helmet } from "react-helmet";
import { classNames } from 'primereact/utils';
import { useContext, useEffect, useState } from 'react';
import AppFooter from './app-footer';
import AppSidebar from './app-sidebar';
import AppTopbar from './app-topbar';
import { LayoutContext, LayoutContextType } from "./context/layout-context";
import { useIdleTimer } from 'react-idle-timer';

const Layout = (props: any) => {
    const { layoutState } = useContext<LayoutContextType>(LayoutContext);
    const [menuClass, setMenuClass] = useState<string | undefined>('root  hd--expanded mn--max');

    const idleTimeInMinutes: number = parseInt(process.env.REACT_APP_IDLE_TIME_IN_MINUTES ?? '10');
    const mainSiteUrl = process.env.REACT_APP_MVC_URL;


    useEffect(() => {
        setMenuClass(classNames('root hd--expanded', {
            'mn--max': layoutState.isMenuExpanded,
            'mn--min': !layoutState.isMenuExpanded,
        }));
        var container: any = document.getElementById('popupmenu');
        if(layoutState.isMenuExpanded){
            container.classList.add("show")
        }else{
            container.classList.remove("show")
        }
        // menu_div_root
        var container: any = document.getElementById('menu_div_root');
      
    }, [layoutState.isMenuExpanded]);

    

    const onIdle = () => {
        localStorage.clear();
        if (mainSiteUrl)
            window.location.assign(mainSiteUrl)
    }

    const { getRemainingTime } = useIdleTimer({
        onIdle,
        timeout: 1000 * 60 * idleTimeInMinutes,
        throttle: 500
    })

    return (
        <>
            <Helmet>
                <title>EnterPrise | App</title>
                <meta charSet="UTF-8" />
                <meta name="description" content="The System to manage the enteprise application" />
                <meta name="robots" content="index, follow" />
                <meta name="viewport" content="initial-scale=1, width=device-width" />
                <meta property="og:type" content="website"></meta>
                <meta property="og:title" content="The Enterprise application"></meta>
                <meta property="og:url" content="https://testenterprise.apergy.com/"></meta>
                <meta property="og:description" content="The system to manage the enterprise application" />
                <meta property="og:image" content=""></meta>
                <meta property="og:ttl" content="604800"></meta>
                <link rel="icon" href={`/favicon.ico`} type="image/x-icon"></link>
            </Helmet>
            <div id="menu_div_root" className={menuClass}>
                <section id="content" className="content">
                    {props.children}
                    <AppFooter />
                </section>
                <AppTopbar />
                <AppSidebar />
                <div className="scroll-container">
                    <a href="#enterprise" className="scroll-page rounded-circle ratio ratio-1x1"></a>
                </div>
            </div>
        </>
    );
};

export default Layout;
