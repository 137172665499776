
const CommonApiConstant: any = {
    getToken: "/api/token",
    getAllCustomerList: "/api/customer/getallcustomers",
    getUser: "/api/auth/getuser",
    dowloadFiles: "/api/assets/DownloadBlobByID",
    getPermissionsOfCurrentUser: "api/auth/getUserPermissions",
    GetSoofieSummary: "api/Soofie/GetSoofieSummary",
    GetEspPastVerifications: "api/Soofie/GetSoofiePastVerifications",
    GetAICallVHealthIndex:"api/Soofie/GetAICallVHealthIndex",
    GetRawFeatures:"api/Soofie/GetRawFeatures",
    AddUpdateESPVerification: "api/Soofie/SoofieVerification",
    GetEspVerificationlog: "api/Soofie/GetSoofieVerificationlog",
    DownloadFile: "api/ESP/DownloadFile",
    GetCustomersByProductType: "api/Soofie/GetCustomersByProductType",
};

export default CommonApiConstant;
