// React
import { useEffect, useState } from "react";

// i18n
import { useTranslation } from "react-i18next";

// amcharts
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

// filters
import SelectDaysFilter from "./select-days-filter";
import SelectNormalFilterAIvsHeath from "./filters/select-normal-filter-ai-vs-health";

// helper
import Helpers from "../../../../common/utils/helpers";

// images
import HealthIndexLegend from "../../../../assets/img/esp-ai/chartsLegendsImgs/healthIndex.jpg";
import NormalLegend from "../../../../assets/img/esp-ai/chartsLegendsImgs/normal.jpg";
import ShutdownLegend from "../../../../assets/img/esp-ai/chartsLegendsImgs/shutdown.jpg";
import Level1Legend from "../../../../assets/img/esp-ai/chartsLegendsImgs/level1.jpg";
import Level2Legend from "../../../../assets/img/esp-ai/chartsLegendsImgs/level2.jpg";
import Level3Legend from "../../../../assets/img/esp-ai/chartsLegendsImgs/level3.jpg";

// Themes begin
am4core.useTheme(am4themes_animated);
// Themes end

const AiVsHealthIndex = (props: any) => {
  const ESPCallHistoryData = props.ESPCallHistoryData;
  
  const { t } = useTranslation();

  const [heathIndexScoreIsSelected, setheathIndexScoreIsSelected] =
    useState(true);
  const [AiCallIsSelected, setAiCallIsSelected] = useState(true);
  const [showSelectedFilterText, setshowSelectedFilterText] = useState<any>("");
  const [ChartDisplay, setChartDisplay] = useState("none");

  const selectFilterList = [
    { name: t("AnamolyDetectionAnalyze.ViewCurrentAnamoly"), key: "A" },
    { name: t("AnamolyDetectionAnalyze.ViewHistoryAnamolies"), key: "M" },
    {
      name: t("AnamolyDetectionAnalyze.heathIndexScore"),
      key: "P",
      after: 1,
      sequence: 1,
    },
    {
      name: t("AnamolyDetectionAnalyze.AiCall"),
      key: "R",
      after: 1,
      sequence: 2,
    },
  ];

  useEffect(() => {
    setChartDisplay("none");
    let temp_array: any = [];
    if (ESPCallHistoryData?.length > 0) {
      ESPCallHistoryData.map((val: any) => {
        temp_array.push(val);
      });
    }
    var chart: any = am4core.create("chartdiv", am4charts.XYChart);
    chart.customLegend = document.getElementById("legend");

    if (chart.logo) {
      chart.logo.disabled = true;
    }

    let hours = 0;

    var d = new Date();
    if (props?.chosenDay == "A") {
      hours = 6;
    } else if (props?.chosenDay == "M") {
      hours = 12;
    } else if (props?.chosenDay == "P") {
      hours = 24;
    } else if (props?.chosenDay == "R") {
      hours = 168;
    }
    if (props.chosenDayDateRange[1] != null) {
      hours = 0;
    }
    d.setHours(d.getHours() - hours);
    let temp_data: any = [];

    // check how many previous anomalys and crreate arrays
    let previous_anomaly_array: any = [];

    ESPCallHistoryData.forEach((val1: any) => {
      if (val1.anomaly == 1) {
        previous_anomaly_array.push(val1);
      }
    });

    // Prepare data for previous ESP call history anomaly calls range.
    props?.ESPAIvsHeathIndexData?.map((val: any, i: any) => {
      let color_anamoly_level = null;
      let anamoly_set_data_point: any = null;
      let anamoly_set_data_point_name: any = null;
      let healthIndex_set_data_point = null;
      let healthIndex_set_data_pointToFixed = null;
      let isCurrentAnomaly = false;

      let val_date = new Date(
        Helpers.convertFullDateToReadableFormat(val.time)
      );
      let sDate = new Date(
        Helpers.convertFullDateToReadableFormat(props.currentAnamolyStartDate)
      );
      let eDate: any = "";
      let DisplayEndDateAnomaly = "";
      if (props.currentAnamolyEndDate == "null") {
        eDate = new Date();
        DisplayEndDateAnomaly = "In progress";
      } else {
        eDate = new Date(
          Helpers.convertFullDateToReadableFormat(props.currentAnamolyEndDate)
        );
        DisplayEndDateAnomaly =
          Helpers.convertFullDateToDateReadableFormatToolTip(
            props.currentAnamolyEndDate
          );
      }

      if (val_date >= sDate && val_date <= eDate) {
        // test start
        if (val?.anomaly == "0") {
          isCurrentAnomaly = false;
        } else if (val?.anomaly == "-1") {
          isCurrentAnomaly = false;
        }
         else if (val.anomaly == "1") {
          isCurrentAnomaly = true;
        } 
      }

      if (val?.anomaly == "0") {
        color_anamoly_level = "green";
        anamoly_set_data_point_name = "Normal";
        anamoly_set_data_point = 0;
        healthIndex_set_data_point = val.healthIndex;
        healthIndex_set_data_pointToFixed = Number(val.healthIndex).toFixed(2);
      } else if (val?.anomaly == "-4") {
        color_anamoly_level = "#828282";
        anamoly_set_data_point = 2;
        val.anomaly = "4";
        anamoly_set_data_point_name = "Shutdown";
      } else if (val.anomaly == "1") {
        /* else if (val.anomalyLevel == "3") {
        color_anamoly_level = "red";
        anamoly_set_data_point = 3;
        anamoly_set_data_point_name = "Level 3";
        healthIndex_set_data_point = val.healthIndex;
        healthIndex_set_data_pointToFixed = Number(val.healthIndex).toFixed(2);
      }  */
        color_anamoly_level = "red";
        anamoly_set_data_point = 1;
        anamoly_set_data_point_name = "Anomaly";
        healthIndex_set_data_point = val.healthIndex;
        healthIndex_set_data_pointToFixed = Number(val.healthIndex).toFixed(2);
      } else if (val.anomaly == "0") {
        /*  else if (val.anomalyLevel == "2") {
        color_anamoly_level = "#ff9200";
        anamoly_set_data_point = 2;
        anamoly_set_data_point_name = "Level 2";
        healthIndex_set_data_point = val.healthIndex;
        healthIndex_set_data_pointToFixed = Number(val.healthIndex).toFixed(2);
      }  */
        color_anamoly_level = "green";
        anamoly_set_data_point = 0;
        anamoly_set_data_point_name = "Normal";
        healthIndex_set_data_point = val.healthIndex;
        healthIndex_set_data_pointToFixed = Number(val.healthIndex).toFixed(2);
      } else {
        healthIndex_set_data_point = null;
        healthIndex_set_data_pointToFixed = null;

        color_anamoly_level = "#FFFFFF00";
      }

      hours = 0;
      
      if (hours == 0) {
        temp_data.push({
          date: new Date(val.time),
          price: healthIndex_set_data_point,
          quantity: anamoly_set_data_point,
          color: color_anamoly_level,
          anamoly_set_data_point_name: anamoly_set_data_point_name,
          toFix: healthIndex_set_data_pointToFixed,
          formatted_date: Helpers.convertFullDateToReadableFormatToolTip(
            val.time
          ),
          isCurrentAnomaly: isCurrentAnomaly,
          anomalySDate:
            Helpers.convertFullDateToDateReadableFormatToolTip(sDate),
          anomalyEDate: DisplayEndDateAnomaly,
          anomalyType: val.anomalyType,
          verification: val.verification,
        });
      } else {
        if (new Date(val.time) > d) {
          temp_data.push({
            date: new Date(val.time),
            price: healthIndex_set_data_point,
            quantity: anamoly_set_data_point,
            color: color_anamoly_level,
            anamoly_set_data_point_name: anamoly_set_data_point_name,
            toFix: healthIndex_set_data_pointToFixed,
            formatted_date: Helpers.convertFullDateToReadableFormatToolTip(
              val.time
            ),
            isCurrentAnomaly: isCurrentAnomaly,
            anomalySDate:
              Helpers.convertFullDateToDateReadableFormatToolTip(sDate),
            anomalyEDate: DisplayEndDateAnomaly,
            anomalyType: val.anomalyType,
            verification: val.verification,
          });
        }
      }
    });

    chart.data = temp_data;
    // the following line makes value axes to be arranged vertically.
    chart.leftAxesContainer.layout = "vertical";
    // uncomment this line if you want to change order of axes

    var dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.tooltipDateFormat = "d MMM , hh:mm a";

    dateAxis.baseInterval = { timeUnit: "hour", count: 1 };

    dateAxis.startLocation = 0.5;
    dateAxis.endLocation = 0.5;
    dateAxis.renderer.grid.template.location = 0;
    dateAxis.renderer.ticks.template.length = 8;
    dateAxis.renderer.ticks.template.strokeOpacity = 0.1;
    dateAxis.renderer.grid.template.strokeWidth = 0;
    dateAxis.renderer.ticks.template.disabled = false;
    dateAxis.renderer.ticks.template.strokeOpacity = 0.2;

    chart.leftAxesContainer.pixelPerfect = true;
    dateAxis.pixelPerfect = true;
    dateAxis.renderer.pixelPerfect = true;
    dateAxis.renderer.gridContainer.layout = "absolute";

    // chart line for health index
    if (heathIndexScoreIsSelected === true) {
      var valueAxis: any = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.title.text = "Health index score";
      valueAxis.title.fontSize = "13px";
      valueAxis.title.marginRight = "50px";
      valueAxis.title.fontWeight = "700";
      // valueAxis.renderer.line.strokeOpacity = 0.8;
      valueAxis.renderer.inside = false;
      valueAxis.zIndex = 1;
      valueAxis.renderer.baseGrid.disabled = true;

      // height of axis
      valueAxis.height = am4core.percent(40);
      valueAxis.renderer.labels.template.verticalCenter = "bottom";
      valueAxis.renderer.labels.template.padding(2, 2, 2, 2);
      valueAxis.renderer.fontSize = "0.8em";
      valueAxis.renderer.gridContainer.background.fillOpacity = 0.05;

      var series: any = chart.series.push(new am4charts.LineSeries());
      series.dataFields.dateX = "date";
      series.dataFields.valueY = "price";
      series.name = "Health index score";
      series.minBulletDistance = 30;
      series.strokeWidth = 2;
      series.stroke = am4core.color("#00394c");
      series.connect = true;
      series.tooltip.getFillFromObject = false;

      series.tooltip.background.pointerLength = 2;
      series.tooltip.background.fill = am4core.color("#ffffff");
      series.tooltip.pointerOrientation = "vertical";

      // Test tooltip start

      series.adapter.add("tooltipHTML", function (text: any, target: any) {
        let tooltip_previous_AnomalyStartDate = "";
        let tooltip_previous_AnomalyEndDate = "";

        previous_anomaly_array.forEach((val: any) => {
          if (
            new Date(Helpers.convertFullDateToReadableFormat(val.startDate)) <=
              new Date(
                Helpers.convertFullDateToReadableFormat(
                  target.tooltipDataItem.dataContext.date
                )
              ) &&
            new Date(Helpers.convertFullDateToReadableFormat(val.endDate)) >=
              new Date(
                Helpers.convertFullDateToReadableFormat(
                  target.tooltipDataItem.dataContext.date
                )
              )
          ) {
            tooltip_previous_AnomalyStartDate =
              Helpers.convertFullDateToDateReadableFormatToolTip(val.startDate);
            tooltip_previous_AnomalyEndDate =
              Helpers.convertFullDateToDateReadableFormatToolTip(val.endDate);
          }
        });
        var data = target.tooltipDataItem.dataContext;
        let anomalyType = data.anomalyType;
        /* if (anomalyType == "2") {
          anomalyType =
            "<span style='color:green;float:right;display: inline-block;padding: 0.35em 0.65em;font-size: 0.95em;font-weight: 700;line-height: 1;color: #fff;text-align: center;white-space: nowrap;vertical-align: baseline;border-radius: 0.4375rem;border-radius: 6px !important;background-color: #ddeefa !important;color: #077ac9 !important;'>Gas Slug</span>";
        } else  */
        if (anomalyType == "1") {
          anomalyType =
            "<span style='color:green;float:right;display: inline-block;padding: 0.35em 0.65em;font-size: 0.95em;font-weight: 700;line-height: 1;color: #fff;text-align: center;white-space: nowrap;vertical-align: baseline;border-radius: 0.4375rem;border-radius: 6px !important;background-color: #eaf5f4 !important;color: #84c6bc !important;'>Leak</span>";
        } else if (anomalyType == null || anomalyType == "") {
          if (data.anamoly_set_data_point_name == "Normal") {
            anomalyType = "N/A";
          } else if (data.anamoly_set_data_point_name == "Shutdown") {
            anomalyType = "N/A";
          } else if (data.anamoly_set_data_point_name == "Anomaly") {
            anomalyType = "-";
          }
          /* else if (data.anamoly_set_data_point_name == "Level 2") {
            anomalyType = "-";
          } else if (data.anamoly_set_data_point_name == "Level 3") {
            anomalyType = "-";
          } */
        }
        let isVerified = data.verification
          ? `<span style='color:green;float:right;display: inline-block;padding: 0.35em 0.65em;font-size: 0.95em;font-weight: 700;line-height: 1;color: #fff;text-align: center;white-space: nowrap;vertical-align: baseline;border-radius: 0.4375rem;border-radius: 6px !important;background-color: #cff0f0 !important;color: #22b9b9 !important;'><i className="pi pi-check" style={{ fontSize: '10px', fontWeight: 'bolder' }}></i> ${t(
              "Verified"
            )}</span>`
          : "<span style='color:green;float:right;display: inline-block;padding: 0.35em 0.65em;font-size: 0.95em;font-weight: 700;line-height: 1;color: #fff;text-align: center;white-space: nowrap;vertical-align: baseline;border-radius: 0.4375rem;border-radius: 6px !important;background-color: #EEDBD8 !important;color: #ec7a38 !important;'>" +
            t("PopupCall.NeedVerification") +
            "</span>";

        // "<span style='color:green;float:right;display: inline-block;padding: 0.35em 0.65em;font-size: 0.95em;font-weight: 700;line-height: 1;color: #fff;text-align: center;white-space: nowrap;vertical-align: baseline;border-radius: 0.4375rem;border-radius: 6px !important;background-color: #ecfdf3 !important;color: #0b643f !important;'>'+t('PopupCall.NeedVerification'+'</span>

        let anomaly_level_badge = "";

        if (data.anamoly_set_data_point_name == "Normal") {
          anomaly_level_badge =
            "<span style='color:green;float:right;display: inline-block;padding: 0.35em 0.65em;font-size: 0.95em;font-weight: 700;line-height: 1;color: #fff;text-align: center;white-space: nowrap;vertical-align: baseline;border-radius: 0.4375rem;border-radius: 6px !important;background-color: #ecfdf3 !important;color: #0b643f !important;'>Normal</span>";
        } else if (data.anamoly_set_data_point_name == "Shutdown") {
          anomaly_level_badge =
            "<span style='color:green;float:right;display: inline-block;padding: 0.35em 0.65em;font-size: 0.95em;font-weight: 700;line-height: 1;color: #fff;text-align: center;white-space: nowrap;vertical-align: baseline;border-radius: 0.4375rem;border-radius: 6px !important;background-color: #f0f1f2 !important;color: #0000007d !important;'>Shutdown</span>";
        } else if (data.anamoly_set_data_point_name == "Anomaly") {
          anomaly_level_badge =
            "<span style='color:green;float:right;display: inline-block;padding: 0.35em 0.65em;font-size: 0.95em;font-weight: 700;line-height: 1;color: #fff;text-align: center;white-space: nowrap;vertical-align: baseline;border-radius: 0.4375rem;border-radius: 6px !important;background-color: #EEDBD8 !important;color: red !important;'>Anomaly</span>";
        }
        /* else if (data.anamoly_set_data_point_name == "Level 2") {
          anomaly_level_badge =
            "<span style='color:green;float:right;display: inline-block;padding: 0.35em 0.65em;font-size: 0.95em;font-weight: 700;line-height: 1;color: #fff;text-align: center;white-space: nowrap;vertical-align: baseline;border-radius: 0.4375rem;border-radius: 6px !important;background-color: #fff4eb !important;color: #df9612 !important;'>Level 2</span>";
        } else if (data.anamoly_set_data_point_name == "Level 3") {
          anomaly_level_badge =
            "<span style='float:right;display: inline-block;padding: 0.35em 0.65em;font-size: 0.95em;font-weight: 700;line-height: 1;color: #fff;text-align: center;white-space: nowrap;vertical-align: baseline;border-radius: 0.4375rem;border-radius: 6px !important;background-color: #fef3f2 !important;color: #992b0abf !important;'>Level 3</span>";
        } */

        if (data.isCurrentAnomaly == true) {
          series.tooltip.background.stroke = am4core.color("red");
          series.tooltip.background.strokeWidth = 1;

          return `<table style="color:black;margin-top:10px;margin-bottom:15px">
        <tbody>
          <tr>
            <td><b>Severity level:</b></td>
            <td style="float:right">${anomaly_level_badge}</td>
          </tr>
          
          <tr>
          <td>Date:</td>
          <td style="float:right">${Helpers.convertFullDateToReadableFormatToolTip(
            target.tooltipDataItem.dataContext.date
          )}</td>
          </tr>

          <tr>
          <td>Time:</td>
          <td style="float:right">${Helpers.convertOnlyTimeToReadableFormat(
            target.tooltipDataItem.dataContext.date
          )}</td>
          </tr>


          <tr>
          <td>Start date time:</td>
          <td style="float:right">{anomalySDate}</td>
        </tr>
        <tr>
          <td>End date time:</td>
          <td style="float:right">{anomalyEDate}</td>
        </tr>
          <tr>
            <td>Health Index Score:</td>
            <td style="float:right">{toFix}</td>
          </tr>
          <tr>
          <td>Verification:</td>
          <td style="float:right">${isVerified}</td>
        </tr>
          <tr>
        </tr>
          
        </tbody>
      </table>`;
        } else if (tooltip_previous_AnomalyStartDate != "") {
          // data.previousAnamoly_startDateTooltip != ""
          // data.previousAnamoly_startDateTooltip == "asd"
          series.tooltip.background.stroke = am4core.color("grey");
          series.tooltip.background.strokeWidth = 1;
          return `<table style="color:black;margin-top:10px;margin-bottom:15px">
        <tbody>
          <tr>
            <td><b>Severity level:</b></td>
            <td style="float:right">${anomaly_level_badge}</td>
          </tr>
          
        <tr>
        <td>Date:</td>
        <td style="float:right">${Helpers.convertFullDateToReadableFormatToolTip(
          target.tooltipDataItem.dataContext.date
        )}</td>
        </tr>

        <tr>
        <td>Time:</td>
        <td style="float:right">${Helpers.convertOnlyTimeToReadableFormat(
          target.tooltipDataItem.dataContext.date
        )}</td>
        </tr>
          <tr>
          <td>Start date time:</td>
          <td style="float:right">${tooltip_previous_AnomalyStartDate}</td>
        </tr>
        <tr>
          <td>End date time:</td>
          <td style="float:right">${tooltip_previous_AnomalyEndDate}</td>
        </tr>
          <tr>
            <td>Health Index Score:</td>
            <td style="float:right">{toFix}</td>
          </tr>
          <tr>
          <td>Verification:</td>
          <td style="float:right">${isVerified}</td>
        </tr>
          <tr>
        </tr>
          
        </tbody>
      </table>`;
        } else {
          let anomaly_level_badge = "";
          series.tooltip.background.stroke = am4core.color("grey");
          series.tooltip.background.strokeWidth = 1;
          if (data.anamoly_set_data_point_name == "Normal") {
            anomaly_level_badge =
              "<span style='color:green;float:right;display: inline-block;padding: 0.35em 0.65em;font-size: 0.95em;font-weight: 700;line-height: 1;color: #fff;text-align: center;white-space: nowrap;vertical-align: baseline;border-radius: 0.4375rem;border-radius: 6px !important;background-color: #ecfdf3 !important;color: #0b643f !important;'>Normal</span>";
          } else if (data.anamoly_set_data_point_name == "Shutdown") {
            anomaly_level_badge =
              "<span style='color:green;float:right;display: inline-block;padding: 0.35em 0.65em;font-size: 0.95em;font-weight: 700;line-height: 1;color: #fff;text-align: center;white-space: nowrap;vertical-align: baseline;border-radius: 0.4375rem;border-radius: 6px !important;background-color: #f0f1f2 !important;color: #0000007d !important;'>Shutdown</span>";
          } else if (data.anamoly_set_data_point_name == "Anomaly") {
            anomaly_level_badge =
              "<span style='color:green;float:right;display: inline-block;padding: 0.35em 0.65em;font-size: 0.95em;font-weight: 700;line-height: 1;color: #fff;text-align: center;white-space: nowrap;vertical-align: baseline;border-radius: 0.4375rem;border-radius: 6px !important;background-color: #EEDBD8 !important;color: red !important;'>Anomaly</span>";
          }
          /* else if (data.anamoly_set_data_point_name == "Level 2") {
            anomaly_level_badge =
              "<span style='color:green;float:right;display: inline-block;padding: 0.35em 0.65em;font-size: 0.95em;font-weight: 700;line-height: 1;color: #fff;text-align: center;white-space: nowrap;vertical-align: baseline;border-radius: 0.4375rem;border-radius: 6px !important;background-color: #fff4eb !important;color: #df9612 !important;'>Level 2</span>";
          } else if (data.anamoly_set_data_point_name == "Level 3") {
            anomaly_level_badge =
              "<span style='float:right;display: inline-block;padding: 0.35em 0.65em;font-size: 0.95em;font-weight: 700;line-height: 1;color: #fff;text-align: center;white-space: nowrap;vertical-align: baseline;border-radius: 0.4375rem;border-radius: 6px !important;background-color: #fef3f2 !important;color: #992b0abf !important;'>Level 3</span>";
          } */
          return `<table style="color:black;margin-top:10px;margin-bottom:15px">
          <tbody>
            <tr>
              <td><b>Severity level:</b></td>
              <td style="float:right">${anomaly_level_badge}</td>
            </tr>
            
          <tr>
          <td>Date:</td>
          <td style="float:right">${Helpers.convertFullDateToReadableFormatToolTip(
            target.tooltipDataItem.dataContext.date
          )}</td>
          </tr>

          <tr>
          <td>Time:</td>
          <td style="float:right">${Helpers.convertOnlyTimeToReadableFormat(
            target.tooltipDataItem.dataContext.date
          )}</td>
          </tr>
  
            <tr>
              <td>Health Index Score:</td>
              <td style="float:right">{toFix}</td>
            </tr>
            <tr>
            <td>Verification:</td>
            <td style="float:right">${isVerified}</td>
          </tr>
            <tr>
          </tr>
            
          </tbody>
        </table>`;
        }
      });
      series.tooltip.pointerOrientation = "vertical";
      let bullet = series.bullets.push(new am4charts.CircleBullet());
      bullet.circle.strokeWidth = 0;
      bullet.circle.radius = 4;
      bullet.circle.fill = am4core.color("#00394c");
    }

    // chart line for AI call
    if (AiCallIsSelected === true) {
      var valueAxis2: any = chart.yAxes.push(new am4charts.ValueAxis());

      valueAxis2.title.text = "AI call (level)";
      valueAxis2.title.fontSize = "13px";
      valueAxis2.title.marginRight = "40px";
      valueAxis2.title.fontWeight = "700";
      valueAxis2.renderer.minGridDistance = 15;

      // valueAxis2.renderer.line.strokeOpacity = 0.8;

      // height of axis
      valueAxis2.height = am4core.percent(40);
      valueAxis2.zIndex = 3;
      valueAxis2.min = -0.1;
      valueAxis2.max = 2;
      // this makes gap between panels
      valueAxis2.marginTop = 30;
      valueAxis2.renderer.baseGrid.disabled = true;
      valueAxis2.renderer.inside = false;
      valueAxis2.renderer.labels.template.verticalCenter = "bottom";
      valueAxis2.renderer.labels.template.padding(8, 8, -4, 2);
      valueAxis2.renderer.fontSize = "0.8em";
      // Adapter adds custom text to the label
      valueAxis2.renderer.labels.template.adapter.add(
        "text",
        (label: any, target: any) => {
          if (label == 0) {
            return "Normal";
          }
          if (label == 1) {
            return "Anomaly";
          } 
          // else if (label == 3 || label == 4 || label == 5 || label == -2 || label == -1) {
          //   return "";
          // }
          //  else if (label == -2) {
          //   return "";
          // } else if (label == -1) {
          //   return null;
          // }
          /* else if (label == 5) {
            return "";
          } else if (label == -2) {
            return "";
          } else if (label == 4) {
            return "Shutdown";
          } else if (label == -1) {
            return null;
          } */
          // {
          //   return "Level " + label;
          // }
        }
      );

      // uncomment these lines to fill plot area of this axis with some color
      valueAxis2.renderer.gridContainer.background.fillOpacity = 0.05;

      var series2 = chart.series.push(new am4charts.LineSeries());

      series2.dataFields.dateX = "date";
      series2.dataFields.valueY = "quantity";
      series2.name = "Series 2";
      series2.minBulletDistance = 30;
      series2.strokeWidth = 2;
      series2.yAxis = valueAxis2;
      series2.propertyFields.stroke = "color";
      series2.connect = true;
      series2.tooltip.getFillFromObject = false;

      series2.tooltip.background.pointerLength = 2;
      series2.tooltip.background.fill = am4core.color("#FFF");

      // Test tooltip start

      series2.adapter.add("tooltipHTML", function (text: any, target: any) {
        let tooltip_previous_AnomalyStartDate = "";
        let tooltip_previous_AnomalyEndDate = "";

        previous_anomaly_array.forEach((val: any) => {
          if (
            new Date(Helpers.convertFullDateToReadableFormat(val.startDate)) <=
              new Date(
                Helpers.convertFullDateToReadableFormat(
                  target.tooltipDataItem.dataContext.date
                )
              ) &&
            new Date(Helpers.convertFullDateToReadableFormat(val.endDate)) >=
              new Date(
                Helpers.convertFullDateToReadableFormat(
                  target.tooltipDataItem.dataContext.date
                )
              )
          ) {
            tooltip_previous_AnomalyStartDate =
              Helpers.convertFullDateToDateReadableFormatToolTip(val.startDate);
            tooltip_previous_AnomalyEndDate =
              Helpers.convertFullDateToDateReadableFormatToolTip(val.endDate);
          }
        });
        var data = target.tooltipDataItem.dataContext;
        let anomalyType = data.anomalyType;
        /* if (anomalyType == "2") {
          anomalyType =
            "<span style='color:green;float:right;display: inline-block;padding: 0.35em 0.65em;font-size: 0.95em;font-weight: 700;line-height: 1;color: #fff;text-align: center;white-space: nowrap;vertical-align: baseline;border-radius: 0.4375rem;border-radius: 6px !important;background-color: #ddeefa !important;color: #077ac9 !important;'>Gas slug</span>";
        } else  */
        if (anomalyType == "1") {
          anomalyType =
            "<span style='color:green;float:right;display: inline-block;padding: 0.35em 0.65em;font-size: 0.95em;font-weight: 700;line-height: 1;color: #fff;text-align: center;white-space: nowrap;vertical-align: baseline;border-radius: 0.4375rem;border-radius: 6px !important;background-color: #eaf5f4 !important;color: #84c6bc !important;'>Leak</span>";
        } else if (anomalyType == null || anomalyType == "") {
          if (data.anamoly_set_data_point_name == "Normal") {
            anomalyType = "N/A";
          } else if (data.anamoly_set_data_point_name == "Shutdown") {
            anomalyType = "N/A";
          } else if (data.anamoly_set_data_point_name == "Anomaly") {
            anomalyType = "-";
          }
          /* else if (data.anamoly_set_data_point_name == "Level 2") {
            anomalyType = "-";
          } else if (data.anamoly_set_data_point_name == "Level 3") {
            anomalyType = "-";
          } */
        }
        let anomaly_level_badge = "";

        if (data.anamoly_set_data_point_name == "Normal") {
          anomaly_level_badge =
            "<span style='color:green;float:right;display: inline-block;padding: 0.35em 0.65em;font-size: 0.95em;font-weight: 700;line-height: 1;color: #fff;text-align: center;white-space: nowrap;vertical-align: baseline;border-radius: 0.4375rem;border-radius: 6px !important;background-color: #ecfdf3 !important;color: #0b643f !important;'>Normal</span>";
        } else if (data.anamoly_set_data_point_name == "Shutdown") {
          anomaly_level_badge =
            "<span style='color:green;float:right;display: inline-block;padding: 0.35em 0.65em;font-size: 0.95em;font-weight: 700;line-height: 1;color: #fff;text-align: center;white-space: nowrap;vertical-align: baseline;border-radius: 0.4375rem;border-radius: 6px !important;background-color: #f0f1f2 !important;color: #0000007d !important;'>Shutdown</span>";
        } else if (data.anamoly_set_data_point_name == "Anomaly") {
          anomaly_level_badge =
            "<span style='color:green;float:right;display: inline-block;padding: 0.35em 0.65em;font-size: 0.95em;font-weight: 700;line-height: 1;color: #fff;text-align: center;white-space: nowrap;vertical-align: baseline;border-radius: 0.4375rem;border-radius: 6px !important;background-color: #EEDBD8 !important;color: red !important;'>Anomaly</span>";
        }
        /* else if (data.anamoly_set_data_point_name == "Level 2") {
          anomaly_level_badge =
            "<span style='color:green;float:right;display: inline-block;padding: 0.35em 0.65em;font-size: 0.95em;font-weight: 700;line-height: 1;color: #fff;text-align: center;white-space: nowrap;vertical-align: baseline;border-radius: 0.4375rem;border-radius: 6px !important;background-color: #fff4eb !important;color: #df9612 !important;'>Level 2</span>";
        } else if (data.anamoly_set_data_point_name == "Level 3") {
          anomaly_level_badge =
            "<span style='float:right;display: inline-block;padding: 0.35em 0.65em;font-size: 0.95em;font-weight: 700;line-height: 1;color: #fff;text-align: center;white-space: nowrap;vertical-align: baseline;border-radius: 0.4375rem;border-radius: 6px !important;background-color: #fef3f2 !important;color: #992b0abf !important;'>Level 3</span>";
        } */
        let isVerified = data.verification
          ? `<span style='color:green;float:right;display: inline-block;padding: 0.35em 0.65em;font-size: 0.95em;font-weight: 700;line-height: 1;color: #fff;text-align: center;white-space: nowrap;vertical-align: baseline;border-radius: 0.4375rem;border-radius: 6px !important;background-color: #cff0f0 !important;color: #22b9b9 !important;'><i className="pi pi-check" style={{ fontSize: '10px', fontWeight: 'bolder' }}></i> ${t(
              "Verified"
            )}</span>`
          : "<span style='color:green;float:right;display: inline-block;padding: 0.35em 0.65em;font-size: 0.95em;font-weight: 700;line-height: 1;color: #fff;text-align: center;white-space: nowrap;vertical-align: baseline;border-radius: 0.4375rem;border-radius: 6px !important;background-color: #EEDBD8 !important;color: #ec7a38 !important;'>" +
            t("PopupCall.NeedVerification") +
            "</span>";
        if (data.isCurrentAnomaly == true) {
          series2.tooltip.background.stroke = am4core.color("red");
          series2.tooltip.background.strokeWidth = 1;

          return `<table style="color:black;margin-top:10px;margin-bottom:15px">
        <tbody>
          <tr>
            <td style="padding-top:2px;padding-bottom:2px"><b>Severity level:</b></td>
            <td style="float:right">${anomaly_level_badge}</td>
          </tr>
          <tr>
          
          
          <tr>
          <td style="padding-top:2px;padding-bottom:2px">Start date time:</td>
          <td style="float:right">{anomalySDate}</td>
        </tr>
        <tr>
          <td style="padding-top:2px;padding-bottom:2px">End date time:</td>
          <td style="float:right">{anomalyEDate}</td>
        </tr>
          <tr>
            <td style="padding-top:2px;padding-bottom:2px">Health Index Score:</td>
            <td style="float:right">{toFix}</td>
          </tr>
          <tr>
          <td style="padding-top:2px;padding-bottom:2px">Verification:</td>
          <td style="float:right">${isVerified}</td>
        </tr>
          <tr>
        </tr>
          
        </tbody>
      </table>`;
        } else if (tooltip_previous_AnomalyStartDate != "") {
          // data.previousAnamoly_startDateTooltip != ""
          // data.previousAnamoly_startDateTooltip == "asd"
          //
          series2.tooltip.background.stroke = am4core.color("grey");
          series2.tooltip.background.strokeWidth = 1;
          return `<table style="color:black;margin-top:10px;margin-bottom:15px">
        <tbody>
          <tr>
            <td style="padding-top:2px;padding-bottom:2px"><b>Severity level:</b></td>
            <td style="float:right">${anomaly_level_badge}</td>
          </tr>
          <tr>
         
        <tr>
        <td>Date:</td>
        <td style="float:right">${Helpers.convertFullDateToReadableFormatToolTip(
          target.tooltipDataItem.dataContext.date
        )}</td>
        </tr>

        <tr>
        <td>Time:</td>
        <td style="float:right">${Helpers.convertOnlyTimeToReadableFormat(
          target.tooltipDataItem.dataContext.date
        )}</td>
        </tr>
          <tr>
          <td style="padding-top:2px;padding-bottom:2px">Start date time:</td>
          <td style="float:right">${tooltip_previous_AnomalyStartDate}</td>
        </tr>
        <tr>
          <td style="padding-top:2px;padding-bottom:2px">End date time:</td>
          <td style="float:right">${tooltip_previous_AnomalyEndDate}</td>
        </tr>
          <tr>
            <td style="padding-top:2px;padding-bottom:2px">Health Index Score:</td>
            <td style="float:right">{toFix}</td>
          </tr>
          <tr>
          <td style="padding-top:2px;padding-bottom:2px">Verification:</td>
          <td style="float:right">${isVerified}</td>
        </tr>
          <tr>
        </tr>
          
        </tbody>
      </table>`;
        } else {
          let anomaly_level_badge = "";
          series2.tooltip.background.stroke = am4core.color("grey");
          series2.tooltip.background.strokeWidth = 1;
          if (data.anamoly_set_data_point_name == "Normal") {
            anomaly_level_badge =
              "<span style='color:green;float:right;display: inline-block;padding: 0.35em 0.65em;font-size: 0.95em;font-weight: 700;line-height: 1;color: #fff;text-align: center;white-space: nowrap;vertical-align: baseline;border-radius: 0.4375rem;border-radius: 6px !important;background-color: #ecfdf3 !important;color: #0b643f !important;'>Normal</span>";
          } else if (data.anamoly_set_data_point_name == "Shutdown") {
            anomaly_level_badge =
              "<span style='color:green;float:right;display: inline-block;padding: 0.35em 0.65em;font-size: 0.95em;font-weight: 700;line-height: 1;color: #fff;text-align: center;white-space: nowrap;vertical-align: baseline;border-radius: 0.4375rem;border-radius: 6px !important;background-color: #f0f1f2 !important;color: #0000007d !important;'>Shutdown</span>";
          } else if (data.anamoly_set_data_point_name == "Anomaly") {
            anomaly_level_badge =
              "<span style='color:green;float:right;display: inline-block;padding: 0.35em 0.65em;font-size: 0.95em;font-weight: 700;line-height: 1;color: #fff;text-align: center;white-space: nowrap;vertical-align: baseline;border-radius: 0.4375rem;border-radius: 6px !important;background-color: #EEDBD8 !important;color: red !important;'>Anomaly</span>";
          }
          /* else if (data.anamoly_set_data_point_name == "Level 2") {
            anomaly_level_badge =
              "<span style='color:green;float:right;display: inline-block;padding: 0.35em 0.65em;font-size: 0.95em;font-weight: 700;line-height: 1;color: #fff;text-align: center;white-space: nowrap;vertical-align: baseline;border-radius: 0.4375rem;border-radius: 6px !important;background-color: #fff4eb !important;color: #df9612 !important;'>Level 2</span>";
          } else if (data.anamoly_set_data_point_name == "Level 3") {
            anomaly_level_badge =
              "<span style='float:right;display: inline-block;padding: 0.35em 0.65em;font-size: 0.95em;font-weight: 700;line-height: 1;color: #fff;text-align: center;white-space: nowrap;vertical-align: baseline;border-radius: 0.4375rem;border-radius: 6px !important;background-color: #fef3f2 !important;color: #992b0abf !important;'>Level 3</span>";
          } */
          return `<table style="color:black;margin-top:10px;margin-bottom:15px">
          <tbody>
            <tr>
              <td><b>Severity level:</b></td>
              <td style="float:right">${anomaly_level_badge}</td>
            </tr>
            
          <tr>
          <td>Date:</td>
          <td style="float:right">${Helpers.convertFullDateToReadableFormatToolTip(
            target.tooltipDataItem.dataContext.date
          )}</td>
          </tr>

          <tr>
          <td>Time:</td>
          <td style="float:right">${Helpers.convertOnlyTimeToReadableFormat(
            target.tooltipDataItem.dataContext.date
          )}</td>
          </tr>
  
            <tr>
              <td>Health Index Score:</td>
              <td style="float:right">{toFix}</td>
            </tr>
            <tr>
            <td>Verification:</td>
            <td style="float:right">${isVerified}</td>
          </tr>
            <tr>
          </tr>
            
          </tbody>
        </table>`;
        }
      });

      series2.tooltip.pointerOrientation = "vertical";

      let bullet2 = series2.bullets.push(new am4charts.CircleBullet());
      bullet2.circle.strokeWidth = 0;
      bullet2.circle.radius = 4;
      bullet2.propertyFields.fill = "color";
    }

    chart.cursor = new am4charts.XYCursor();
    chart.cursor.xAxis = dateAxis;
    chart.cursor.maxTooltipDistance = -1;
    // start test range
    let array_event: any = [];

    if (props.viewPastAnamolyState != false) {
      temp_array.map((val: any, i: any) => {
        if (val?.anomaly == "0") {
        } else if (val?.anomaly == "-1") {
        } else if (val.anomaly == "1") {
          array_event[i] = dateAxis.axisRanges.create();
          array_event[i].date = new Date(
            Helpers.convertFullDateToReadableFormat(temp_array[i]?.startDate)
          );
          array_event[i].endDate = new Date(
            Helpers.convertFullDateToReadableFormat(temp_array[i]?.endDate)
          );
          array_event[i].grid.disabled = true;
          array_event[i].grid.disabled = true;
          array_event[i].axisFill.fillOpacity = 0.1;
        }
      });
    }

    // var event1 = dateAxis.axisRanges.create();
    // moment(props.currentAnamolyStartDate).local().format('DD-MM-YYYY hh:mm A')
    // event1.date = new Date(Helpers.convertFullDateToReadableFormat(props.currentAnamolyStartDate));
    let sDate = new Date(
      Helpers.convertFullDateToReadableFormat(props.currentAnamolyStartDate)
    );
    let eDate: any = "";
    let test_event: any = [];
    if (props.currentAnamolyEndDate == "null") {
      eDate = new Date();
    } else {
      eDate = new Date(
        Helpers.convertFullDateToReadableFormat(props.currentAnamolyEndDate)
      );
    }

    // show red anomly current start

    if (props.viewCurrentAnamolyState != false) {
      
      props?.ESPAIvsHeathIndexData?.forEach((val: any, i: any) => {
        let val_date = new Date(
          Helpers.convertFullDateToReadableFormat(val.time)
        );
        if (val_date >= sDate && val_date <= eDate) {
          // test start
          if (val?.anomaly == "0") {
          } else if (val?.anomaly == "-1") {
          } else if (val.anomaly == "1") {
            ;
            test_event[i] = dateAxis.axisRanges.create();
            test_event[i].date = new Date(
              Helpers.convertFullDateToReadableFormat(val_date)
            );
            
            test_event[i].endDate = new Date(
              Helpers.convertFullDateToReadableFormat(
                props.ESPAIvsHeathIndexData[i + 1]?.time
              )
            );
            test_event[i].grid.disabled = true;
            test_event[i].axisFill.fill = am4core.color("red");
            test_event[i].axisFill.fillOpacity = 0.1;
          }
        }
      });
    }
    // show red anomly current end

    var scrollbarX = new am4charts.XYChartScrollbar();
    scrollbarX.bbox.height = 0;
    scrollbarX.marginBottom = 40;
    scrollbarX.minHeight = 15;
    // scrollbarX.series.push()
    // chart.scrollbarX = scrollbarX;

    let rectangle = chart.plotContainer.createChild(am4core.Rectangle);
    rectangle.fillOpacity = 1;
    rectangle.width = am4core.percent(100);
    rectangle.fill = am4core.color("white");
    rectangle.isMeasured = true;
    rectangle.height = 30;
    rectangle.zIndex = 1000;
    dateAxis.baseInterval = { timeUnit: "minute", count: 1 };
    // Set date label formatting
    dateAxis.dateFormats.setKey("hour", "hh:mm a");
    // dateAxis.baseInterval = {"timeUnit": "second", "count": 1}
    // chart.legend = new am4charts.Legend();

    chart.events.on("ready", function (event: any) {
      if (props.zoomDateRange.length > 0) {
        dateAxis.zoomToDates(
          new Date(props.zoomDateRange[0]),
          new Date(props.zoomDateRange[1])
        );
      } else {
      }
      let check1: any = props.zoomDateRange[0];
      let check2: any = props.zoomDateRange[1];
      dateAxis.events.on("selectionextremeschanged", dateAxisChanged);

      function dateAxisChanged(ev: any) {
        var start = ev.target.minZoomed;
        var end = ev.target.maxZoomed;
        let startTime: any = new Date(start);
        let startTime1: any = new Date(check1);

        let endTime: any = new Date(end);
        let endTime1: any = new Date(check2);

        let diff_start = (startTime1 - startTime) / 1000;
        let diff_end = (endTime1 - endTime) / 1000;

        if (isNaN(diff_start)) {
          props.setzoomDateRange([start, end]);
        }

        if (
          diff_start < -120 ||
          diff_start > 120 ||
          diff_end < -120 ||
          diff_end > 120 ||
          isNaN(diff_start)
        ) {
          props.setzoomDateRange([start, end]);
        }
      }

      rectangle.y = valueAxis2.pixelY - rectangle.pixelHeight - 1;
      chart.customLegend.innerHTML = "";

      chart.customLegend.innerHTML += `
          <div class="legend-item" id="legend-item">

            <img height="15px" src='${HealthIndexLegend}'/>
            Health index score

            

       <img style="margin-left:12px" height="15px" src='${NormalLegend}'/>
            ${t("AnamolyDetectionAnalyze.NormalAICall")}


            <img style="margin-left:12px" height="15px" src='${Level3Legend}'/>
            ${t("Anamoly")}
          
          </div>
          `;
    });

    {
      /* <img style="margin-left:12px" height="15px" src='${Level1Legend}'/>
            ${t("AnamolyDetectionAnalyze.L1AnamolyAICall")}
            
            <img style="margin-left:12px" height="15px" src='${Level2Legend}'/>
            ${t("AnamolyDetectionAnalyze.L2AnamolyAICall")} */
    }

    if (props?.chosenDay == "A") {
      setshowSelectedFilterText(`Last 6 hours data`);
    } else if (props?.chosenDay == "M") {
      setshowSelectedFilterText(`Last 12 hours data`);
    } else if (props?.chosenDay == "P") {
      setshowSelectedFilterText(`Last 24 hours data`);
    } else if (props?.chosenDay == "R") {
      setshowSelectedFilterText(`Last 7 days data`);
    } else if (props?.chosenDayDateRange != "") {
      setshowSelectedFilterText(
        `Data from ${Helpers.convertFullDateToReadableFormatForFilterRange(
          props?.chosenDayDateRange[0]
        )} to ${Helpers.convertFullDateToReadableFormatForFilterRange(
          props?.chosenDayDateRange[1]
        )}`
      );
    }
    setChartDisplay("block");
  }, [
    props?.chosenDayDateRange,
    props?.chosenDay,
    // props.zoomDateRange,
    props?.ESPAIvsHeathIndexData,
    props?.currentAnamolyEndDate,
    props?.currentAnamolyStartDate,
    props?.viewPastAnamolyState,
    props?.viewCurrentAnamolyState,
    props?.ESPCallHistoryData,
    ,
    AiCallIsSelected,
    heathIndexScoreIsSelected,
  ]);

  return (
    <>
      <div
        style={{
          border: "1px solid #c4c4c4",
          borderRadius: "10px",
          marginTop: "20px",
        }}
      >
        <div className="row" style={{ marginTop: "12px" }}>
          <div
            className="col-sm-8 col-md-8 col-lg-8"
            style={{ marginLeft: "15px" }}
          >
            <span style={{ color: "black", fontWeight: 700, fontSize: "19px" }}>
              {t("AnamolyDetectionAnalyze.AIvsHealthIndex")}
            </span>
            <p style={{ fontSize: "14px" }}>
              {t("AnamolyDetectionAnalyze.AIvsHealthIndexSubHeading")}
            </p>
          </div>
          <div
            className="col-sm-3 col-md-3 col-lg-3"
            style={{ marginTop: "7px", marginLeft: "6%" }}
          >
            <SelectNormalFilterAIvsHeath
              viewPastAnamolyState={props.viewPastAnamolyState}
              setviewPastAnamolyState={props.setviewPastAnamolyState}
              viewCurrentAnamolyState={props.viewCurrentAnamolyState}
              setviewCurrentAnamolyState={props.setviewCurrentAnamolyState}
              flist={selectFilterList}
              setheathIndexScoreIsSelected={setheathIndexScoreIsSelected}
              setAiCallIsSelected={setAiCallIsSelected}
            />
            <SelectDaysFilter
              setChosenDay={props?.setChosenDay}
              chosenDay={props?.chosenDay}
              setchosenDayDateRange={props.setchosenDayDateRange}
              chosenDayDateRange={props?.chosenDayDateRange}
            />
          </div>
        </div>
        {/* <div className="card" style={{ border: "0.1em solid  rgb(196, 196, 196)", borderTopLeftRadius: "0", borderTopRightRadius: "0" }}> </div> */}
        <span
          style={{
            float: "right",
            marginRight: "1%",
            marginBottom: "20px",
          }}
        >
          {showSelectedFilterText}
        </span>
        {props?.ESPAIvsHeathIndexData?.length == 0 ? (
          <div
            style={{
              padding: "24px",
              textAlign: "center",
              marginTop: "40px",
              marginBottom: "40px",
              color: "rgba(0,0,0,0.87)",
            }}
          >
            {t("No Records To Display")}
          </div>
        ) : (
          <>
            <div
              id="chartdiv"
              style={{
                width: "100%",
                marginBottom: "3%",
                height: "380px",
                borderTop: "1px solid rgb(196, 196, 196)",
              }}
            ></div>
            <div id="legend" style={{ marginBottom: "40px" }}></div>
          </>
        )}
      </div>
    </>
  );
};

export default AiVsHealthIndex;
