// React
import { useEffect, useRef, useState } from 'react'

// i18n
import { useTranslation } from "react-i18next";

// Prime React
import { OverlayPanel } from "primereact/overlaypanel";
import { Button } from 'primereact/button';
import { Checkbox } from "primereact/checkbox";
import { Calendar } from 'primereact/calendar';
import { Toast } from 'primereact/toast';
import Helpers from '../../../common/utils/helpers';
import { AppDispatch, AppState } from '../../store/esp-ai/store';
import { useDispatch, useSelector } from "react-redux";
import { addAnamolyDetectionStoreData } from '../../store/esp-ai/esp-anomaly-detection.store';

const SelectDaysFilter = (props: any) => {
  const toast = useRef<any>(null);
  const dispatch: AppDispatch = useDispatch();
  const { t } = useTranslation();
  const selectDaysDpDown = useRef<any>(null);
  const [selectedDays, setSelectedDays] = useState<any>("");
  const [ToastMessage, setToastMessage] = useState<any>("");
  const selectDaysDpDownDateRange = useRef<any>(null);
  const [date2, setDate2] = useState<any>(props.chosenDayDateRange);
  let session = window.localStorage;
  const selectDaysList = [
    { name: t('AnamolyDetectionAnalyze.Last6Hours'), key: 'A' },
    { name: t('AnamolyDetectionAnalyze.Last12Hours'), key: 'M' },
    { name: t('AnamolyDetectionAnalyze.Last24Hours'), key: 'P' },
    { name: t('AnamolyDetectionAnalyze.Last7Days'), key: 'R' },
    { name: 'Last 30 days', key: 'T' }
  ];
  useEffect(() => {

    setSelectedDays(session.getItem('summary_filter_days'))

    setDate2(props.chosenDayDateRange);
    if (props.chosenDayDateRange == "") {
      // if (props?.chosenDay == "" || props?.chosenDay == " ") {
      // props?.setChosenDay('P');
      // }
    }

    
  }, []);


  useEffect(() => {
    if (ToastMessage != "") {
      toast?.current?.show({ severity: 'error', summary: 'Error message', detail: ToastMessage, life: 10000 });
      setToastMessage("");
    }

  }, [ToastMessage])

  const onSelectDaysChange = (e: any) => {
    if (e?.value?.key) {

      session.setItem("summary_filter_days", e?.value?.key);
      session.setItem("summary_filter_range_flag", "");
      setSelectedDays(e?.value?.key);
      props?.setChosenDay(e?.value?.key);
      props.setchosenDayDateRange('');
      setDate2(new Date())
    }
  };
  return (
    <>
      <Toast ref={toast} />
      <Button className='DashboardFilterButton' severity="secondary" text style={{ fontWeight: "500", height: "37px", marginRight: "9px", borderRadius: "7px", backgroundColor: "white", color: "#607D8B", border: "1px solid #ccc" }} onClick={(e) => selectDaysDpDown.current.toggle(e)}>
        <i className='pi pi-calendar-plus' />&nbsp;{t('AnamolyDetectionAnalyze.SelectDays')}
      </Button>
      <OverlayPanel ref={selectDaysDpDown}>
        {selectDaysList.map((msp) => {
          return (
            <div key={msp.key} className="flex align-items-center mt-2">
              <Checkbox inputId={msp.key} name="msp" value={msp} onChange={(e: any) => {
                onSelectDaysChange(e);
                selectDaysDpDown.current.toggle(e);
              }} checked={(props?.chosenDay == msp.key) || (selectedDays == msp.key)} onClick={(e) => { alert() }} />
              <label htmlFor={msp.key} className="ml-2">
                {msp.name}
              </label>
            </div>
          );
        })}
        <hr style={{ border: "1px solid" }} />
        <div className="flex align-items-center mt-2">
          <i className='pi pi-plus'></i>
          <label htmlFor={"Custom-date-range"} className="ml-2" onClick={(e) => { selectDaysDpDownDateRange.current.toggle(e) }} style={{ cursor: "pointer" }}>
            {t('AnamolyDetectionAnalyze.CustomDateRange')}
          </label>
        </div>

        {
          // (session.getItem("summary_filter_range_flag") != "") || 
          
          ((session.getItem('summary_filter_days') != "") && (session.getItem('summary_filter_days') != null)) && 
          <div className="flex align-items-center mt-2">

          <Button className='ValidateBtnDashboard' label={`View latest calls`} severity="secondary" style={{ color: "#105787", background: '#ddf2fc' }} onClick={(e) => {
            let startD = new Date();
            let EndD = new Date();

            let anomalyDetectionFilterStartDate = startD.toISOString();
            let anomalyDetectionFilterEndDate = EndD.toISOString();


            props.setchosenDayDateRange('');
            setSelectedDays('');
            session.setItem("summary_filter_days", '');
            session.setItem("summary_filter_dates", [0, 0] as any);
            session.setItem("summary_filter_range_flag", "");
            session.setItem("summary_filter_dates_readable", [anomalyDetectionFilterStartDate, anomalyDetectionFilterEndDate] as any);
            props?.setChosenDay('');
            setDate2('' as any);


            dispatch(addAnamolyDetectionStoreData([] as any));
            selectDaysDpDown.current.toggle(e);
          }} />


        </div>
        }
        
      </OverlayPanel>
      <OverlayPanel ref={selectDaysDpDownDateRange}>
        <Calendar inline id="range" value={date2} onChange={(e: any) => {

          setDate2(e.value);
          if (e.value[1] != null) {
            let tempSdate: any = new Date(e.value[0]);
            let tempEdate: any = new Date(e.value[1]);
            const diffTime = Math.abs(tempEdate - tempSdate);
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

            if (diffDays <= 30) {
              let startD = new Date(e.value[0]);
              let EndD = new Date(e.value[1]);

              let anomalyDetectionFilterStartDate = startD.toISOString();
              let anomalyDetectionFilterEndDate = EndD.toISOString();
              //  session.setItem('summary_filter_dates', [anomalyDetectionFilterStartDate, anomalyDetectionFilterEndDate] as any);
              //  session.setItem('summary_filter_dates_readable', [Helpers.convertOnlyDateToReadableFormat(anomalyDetectionFilterStartDate), Helpers.convertOnlyDateToReadableFormat(anomalyDetectionFilterEndDate)] as any);

              props.setchosenDayDateRange(e.value);
              setSelectedDays('');
              session.setItem("summary_filter_days", '');
              session.setItem("summary_filter_range_flag", "ok");
              props?.setChosenDay('');
            } else {
              setDate2([]);
              setToastMessage("Date range should be less than 30 days");
            }


            selectDaysDpDownDateRange.current.toggle(e);
          }

        }} selectionMode="range" readOnlyInput />
      </OverlayPanel>
    </>
  )
}

export default SelectDaysFilter