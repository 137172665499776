import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import "./common/localisation/i18n";

import './assets/styles/nifty/bootstrap.css';
import './assets/styles/nifty/nifty.css';
import './assets/styles/layout/layout.scss';

import store from './esp-ai/store/esp-ai/store'
import { Provider } from "react-redux";

const root = ReactDOM.createRoot(
  document.getElementById('enterprise') as HTMLElement
);
root.render(
  <>
    <Provider store={store}>
      <App />
    </Provider>
  </>
);

reportWebVitals();
