import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useContext, useEffect } from "react";

import { MenuItemModel } from "../../models/menu-item";
import { MenuContext } from "./context/menu-context";

interface MenuItemProps {
  item: MenuItemModel;
  parentKey?: string;
  index: number;
  root?: boolean;
  isexternal?: boolean;
}

const AppMenuItem = (menuItemProps: MenuItemProps) => {
  const { activeMenu, setActiveMenu } = useContext(MenuContext);
  const location = useLocation();
  const currentURLRouteName = location.pathname;
  const item = menuItemProps.item;
  const key = menuItemProps.parentKey
    ? menuItemProps.parentKey + "-" + menuItemProps.index
    : String(menuItemProps.index);
  const isActiveRoute = item.link && currentURLRouteName === item.link;
  const active = activeMenu === key || activeMenu.startsWith(key + "-");
  const { t } = useTranslation();

  var navigate = useNavigate();

  const navigateToPage = (menuItem: MenuItemModel) => {
    //navigate(menuItem.isexternal ? menuItem.link : menuItem.link);
    if (menuItem.isexternal) {
      window.open(process.env.REACT_APP_MVC_URL + menuItem.link, "_blank");
    } else {
      navigate(menuItem.link);
    }
  };

  useEffect(() => {
    if (item.link && currentURLRouteName === item.link) {
      setActiveMenu(key);
    }
  }, [location]);

  const itemClick = (menuItem: any) => {
    if (menuItem.menuitems && menuItemProps.parentKey)
      setActiveMenu(active ? menuItemProps.parentKey : key);
    else setActiveMenu(key);
  };

  const subMenu = item.menuitems && (
    <ul
      className={`mininav-content mininav-popups nav collapse ${
        active ? "show" : ""
      }`}
      id="popupmenu"
    >
      {item.menuitems.map((child, i) => {
        return (
          <AppMenuItem
            item={child}
            index={i}
            parentKey={key}
            key={child.title}
          />
        );
      })}
    </ul>
  );

  return (
    <>
      {!item.link || item.menuitems ? (
        <li className="nav-item has-sub" key={key}>
          <a
            href="javascript:void(0)"
            onClick={() => itemClick(item)}
            className={`mininav-toggle nav-link ${active ? "active" : ""} ${
              activeMenu == key || activeMenu == menuItemProps.parentKey
                ? ""
                : "collapsed"
            } `}
          >
            {typeof item.icon !== "undefined" && item.icon && (
              <i className={item.icon + " fs-5 me-2"}></i>
            )}
            <span className="nav-label dashboard-style">{t(item.title)}</span>
          </a>
          {subMenu}
        </li>
      ) : (
        <li className="nav-item" key={key}>
          <a
            href="javascript:void(0)"
            onClick={() => {
              navigateToPage(item);
            }}
            className={`nav-link ${isActiveRoute ? "active" : ""}`}
          >
            {t(item.title)}
          </a>
        </li>
      )}
    </>
  );
};

export default AppMenuItem;
