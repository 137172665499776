import { useState, useEffect } from 'react';
import profileImage from '../../../assets/img/icon.png';
import UserService from '../../services/user/user.service';
import Helpers from '../../utils/helpers';
import AppMenu from './app-menu';

const AppSidebar = () => {
    const [user, setUser] = useState<any>(null);

    useEffect(() => {
        UserService.getCurrentUser().then((userDetails: any) => {
            Helpers.ConsoleLog(userDetails);
            setUser(userDetails.data)
        });
    }, []);
    return (
        <>
            <nav id="mainnav-container" className="mainnav">
                <div className="mainnav__inner">
                    <div className="mainnav__top-content scrollable-content pb-5">
                        <div className="mainnav__profile mt-3">
                            <div className="mininav-toggle text-center py-2">
                                <img className="mainnav__avatar img-md rounded-circle" src={profileImage} alt="Profile Picture" />
                            </div>
                            <div className="mininav-content collapse d-mn-max">
                                <div className="d-grid">
                                    <button className="d-block btn shadow-none p-2 profile-mail" data-bs-toggle="collapse" data-bs-target="#usernav" aria-expanded="false" aria-controls="usernav">
                                        <span className="dropdown-toggle d-flex justify-content-center align-items-center">
                                            <div className="mb-0 me-2">{user && (user?.firstName + ' ' + user?.lastName)} </div>
                                        </span>
                                        <small className="text-muted " style={{ textTransform: "lowercase" }}>{user && user?.userName}</small>
                                    </button>
                                    <div id="usernav" className="nav flex-column collapse">
                                        <a href="#" className="nav-link">
                                            <i className="pli-male icon-lg icon-fw"></i> View Profile
                                        </a>
                                        <a href="#" className="nav-link">
                                            <i className="pli-unlock icon-lg icon-fw"></i> Logout
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <AppMenu></AppMenu>
                    </div>
                </div>
            </nav>

        </>
    );
}

export default AppSidebar;